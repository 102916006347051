import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import axios from "axios";
import { C } from "../common";
import toast from "react-hot-toast";
import { updateMaintainenceRequestValidationSchema } from "../helpers/validators";
import { useEffect, useState } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { RiDeleteBin2Line } from "react-icons/ri";

import { useDisclosure } from "@mantine/hooks";
import { Modal, useMantineTheme } from "@mantine/core";

import { useNavigate, useParams } from "react-router-dom";
import { Layout } from "./Layout";

import Select from "react-select";

import {
  FaCaretLeft,
  FaTrash,
  FaSave,
  FaTimes,
  FaBan,
  FaCheck,
  FaFile,
  FaPlus,
  FaUpload,
} from "react-icons/fa";

export function UpdateRepairForm() {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [maintenanceDocs, setMaintenanceDocs] = useState([]);

  const [opened, { open, close }] = useDisclosure(false);
  const [opened2, { open: open2, close: close2 }] = useDisclosure(false);
  const theme = useMantineTheme();

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const allCategories = [];
      let currentPage = 1;

      while (true) {
        const response = await axios.get(
          `${C.URLS.BASE_URL}/api/DocumentCategories?PageNumber=${currentPage}`
        );
        const categoriesData = response.data.data.collection;

        if (categoriesData.length === 0) {
          break;
        }

        allCategories.push(...categoriesData);
        currentPage++;
      }

      setCategories(allCategories);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleFileChange = (event, setFieldValue, index) => {
    const fieldName = `MaintenanceDocuments.file`;
    setFieldValue(fieldName, event.currentTarget.files[0]);
  };

  const handleBackClick = () => {
    navigate(`/repairs`);
  };

  const handleUploadDocument = (values) => {
    const selectedCategoryId = values.categoryId;
    const selectedFile = values.MaintenanceDocuments.file;

    const newDocument = {
      DocumentCategoryId: selectedCategoryId,
      file: selectedFile,
    };

    // Check if there's already a document for this category
    const existingDocIndex = maintenanceDocs.findIndex(
      (doc) => doc.DocumentCategoryId === selectedCategoryId
    );

    if (existingDocIndex !== -1) {
      // Replace the existing document for this category with the new one
      maintenanceDocs[existingDocIndex] = newDocument;
      setMaintenanceDocs([...maintenanceDocs]);
    } else {
      // Just add the new document since it's a new category
      values.MaintenanceDocuments.push(newDocument);
      setMaintenanceDocs([...values.MaintenanceDocuments]);
    }

    close();
  };

  return (
    <Layout>
      <Formik
        initialValues={{
          id: id,
          startDate: "",
          endDate: "",
          cost: "",
          comments: "",
          MaintenanceDocuments: [],
        }}
        validationSchema={updateMaintainenceRequestValidationSchema}
        enableReinitialize
        onSubmit={async (values, { resetForm }) => {
          setLoading(true);
          console.log(values);

          const formData = new FormData();

          values.MaintenanceDocuments.forEach((document, index) => {
            formData.append(
              `MaintenanceDocuments[${index}].DocumentCategoryId`,
              document.DocumentCategoryId
            );
            formData.append(
              `MaintenanceDocuments[${index}].file`,
              document.file
            );
          });

          for (const key in values) {
            if (key !== "MaintenanceDocuments") {
              formData.append(key, values[key]);
            }
          }

          try {
            const response = await axios.put(
              `${C.URLS.BASE_URL}/api/Maintenances/${id}/step-two`,
              formData,
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            );

            const responseData = response.data;

            if (responseData.code === "BadRequest")
              toast.error(responseData.message, { position: "bottom-center" });
            else if (response.status === 200 || response.status === 201) {
              toast.success(responseData.message, {
                position: "bottom-center",
              });
              setLoading(false);
              //   resetForm();
            } else {
              toast.error("An error occurred while processing your request.", {
                position: "bottom-center",
              });
            }

            setLoading(false);
          } catch (error) {
            // Handle the error if needed
            toast.error("An unexpected error occurred.", {
              position: "bottom-center",
            });
            setLoading(false);
          }
        }}
      >
        {({
          values,
          setFieldValue,
          handleChange,
          handleBlur,
          errors,
          touched,
        }) => {
          return (
            <>
              <Form className="h-full overflow-y-scroll px-4 md:px-10">
                <main>
                  <div className="flex justify-between items-center gap-3 md:gap-5 mb-2">
                    <button
                      className="px-4 py-2 my-2 font-medium bg-white border rounded-md text-[#23A74C] flex items-center text-base"
                      onClick={handleBackClick}
                    >
                      <span className="mr-2">
                        <FaCaretLeft size={14} />
                      </span>
                      <span className="text-base-extra">View all requests</span>
                    </button>

                    <div className="flex gap-3 md:gap-5">
                      <button
                        type="button"
                        className="px-4 py-2 my-2 font-medium bg-[#EAF2F1] border rounded-md text-[#333] flex items-center text-base"
                        onClick={open}
                      >
                        <span className="mr-2">
                          <FaFile size={14} />
                        </span>
                        <span className="text-base-extra">Attach document</span>
                      </button>

                      <button
                        type="submit"
                        className="px-4 py-2 my-2 font-medium bg-[#23A74C] text-sm text-white border rounded-md flex items-center text-base"
                      >
                        <span className="mr-2">
                          <FaSave size={14} />
                        </span>
                        <span className="text-base-extra">
                          {loading ? "Submiting..." : "Complete request"}
                        </span>
                      </button>
                    </div>
                  </div>

                  {/* Section 1 */}
                  <div className="bg-white shadow-md border border-[#E4E4E7]/50 rounded-md px-10 pt-10 pb-5 flex">
                    {/* Left Column */}
                    <div className="w-1/2 pr-5">
                      <label className="block mb-5 w-full">
                        {/* <span className="block text-sm font-medium text-slate-700"> */}
                        <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base font-semibold text-primary-color">
                          Start date of repair
                        </span>
                        <div className="">
                          <input
                            type="date"
                            name="startDate"
                            placeholder="Start date of repair"
                            onChange={handleChange("startDate")}
                            onBlur={handleBlur("startDate")}
                            value={`${values.startDate}`}
                            className={`mt-1 px-3 py-2 bg-transparent border border-custom-green ${
                              errors &&
                              errors?.startDate &&
                              touched &&
                              touched?.startDate
                                ? "border-red-400"
                                : "border-custom-green"
                            }  placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-base mb-1`}
                            // className={`mt-1 px-3 py-2 bg-gray-50 border shadow-sm capitalize border-gray-400 focus:outline-none focus:border-gray-400 focus:ring-gray-400 block w-full rounded-md sm:text-sm focus:ring-0 mb-1 text-gray-500`}
                          />
                          <ErrorMessage name="startDate">
                            {(msg) => (
                              <div className="text-xs text-red-500 text-left w-full">
                                <p>{msg}</p>
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </label>

                      <label className="block mb-5 w-full">
                        {/* <span className="block text-sm font-medium text-slate-700"> */}
                        <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base font-semibold text-primary-color">
                          End date of repair
                        </span>
                        <div className="">
                          <input
                            type="date"
                            name="endDate"
                            placeholder="End date of repair"
                            onChange={handleChange("endDate")}
                            onBlur={handleBlur("endDate")}
                            value={`${values.endDate}`}
                            className={`mt-1 px-3 py-2 bg-transparent border border-custom-green ${
                              errors &&
                              errors?.endDate &&
                              touched &&
                              touched?.endDate
                                ? "border-red-400"
                                : "border-custom-green"
                            }  placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-base mb-1`}
                            // className={`mt-1 px-3 py-2 bg-gray-50 border shadow-sm capitalize border-gray-400 focus:outline-none focus:border-gray-400 focus:ring-gray-400 block w-full rounded-md sm:text-sm focus:ring-0 mb-1 text-gray-500`}
                          />
                          <ErrorMessage name="endDate">
                            {(msg) => (
                              <div className="text-xs text-red-500 text-left w-full">
                                <p>{msg}</p>
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </label>
                      <label className="block mb-5 w-full">
                        <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base font-semibold text-primary-color">
                          Actual cost (UGX)
                        </span>
                        <div className="">
                          <input
                            type="number"
                            name="cost"
                            placeholder="Actual Cost"
                            onChange={handleChange("cost")}
                            onBlur={handleBlur("cost")}
                            value={`${values.cost}`}
                            className={`mt-1 px-3 py-2 bg-transparent border border-custom-green ${
                              errors && errors?.cost && touched && touched?.cost
                                ? "border-red-400"
                                : "border-custom-green"
                            }  placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-base mb-1`}
                            // className={`mt-1 px-3 py-2 bg-gray-50 border shadow-sm capitalize border-gray-400 focus:outline-none focus:border-gray-400 focus:ring-gray-400 block w-full rounded-md sm:text-sm focus:ring-0 mb-1 text-gray-500`}
                          />
                          <ErrorMessage name="cost">
                            {(msg) => (
                              <div className="text-xs text-red-500 text-left w-full">
                                <p>{msg}</p>
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </label>
                    </div>

                    {/* Right Column */}
                    <div className="w-1/2 pl-5">
                      <div className="">
                        <label className="block mb-5">
                          <span className="block text-base font-semibold text-primary-color">
                            Addtional comments
                            <span className="block text-base font-medium text-primary-color mb-3">
                              <i>Provide any additional needed.</i>
                            </span>
                          </span>
                          <div className="">
                            <ReactQuill
                              value={values.comments}
                              onChange={(value) =>
                                handleChange("comments")(value)
                              }
                              // style={{ height: "50px" }}
                            />
                            <ErrorMessage name="comments">
                              {(msg) => (
                                <div className="text-xs text-red-500 text-left w-full">
                                  <p>{msg}</p>
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                        </label>
                      </div>

                      {/* Display Maintenance Documents */}
                      <span className="block text-base-extra font-semibold text-primary-color">
                        Uploaded documents
                        <span className="before:content-['*'] before:ml-0.5 before:text-red-500 block text-base font-medium text-primary-color mb-3">
                          <i>Certificate of completion must be uploaded.</i>
                        </span>
                      </span>

                      {maintenanceDocs.map((doc, index) => {
                        const categoryName =
                          categories.find(
                            (category) => category.id === doc.DocumentCategoryId
                          )?.name || "Unknown Category";

                        return (
                          <div
                            key={index}
                            className="flex items-center justify-between mt border-b border-[#EEE] pb-2"
                          >
                            <div>
                              <i className="text-base font-bold">
                                {categoryName}
                              </i>
                              <p className="text-base">{doc.file.name}</p>{" "}
                            </div>
                            <button
                              type="button"
                              onClick={() => {
                                const updatedDocs = maintenanceDocs.filter(
                                  (_, idx) => idx !== index
                                );
                                setMaintenanceDocs(updatedDocs);
                                values.MaintenanceDocuments = updatedDocs;
                              }}
                            >
                              <RiDeleteBin2Line
                                size={18}
                                className="cursor-pointer text-red-500"
                              />
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </main>

                {/* <NewDocumentCategoryModal /> */}
                <Modal
                  opened={opened}
                  onClose={close}
                  title="Upload document"
                  centered
                  overlayProps={{
                    color:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[9]
                        : theme.colors.gray[2],
                    opacity: 0.55,
                    blur: 3,
                  }}
                >
                  <div className="border-t border-[#EEE] pt-2">
                    <p className="text-base-extra fpmt-medium text-primary-color leading-snug">
                      Please upload the required document.
                    </p>

                    {/* Upload document */}
                    <label className="block py-2">
                      <span className="block text-base font-semibold text-primary-color">
                        Select document category
                      </span>
                      <div className="">
                        <Select
                          isSearchable
                          menuPortalTarget={document.body}
                          name="categoryId"
                          value={
                            categories
                              .filter(
                                (category) => category.id === values.categoryId
                              )
                              .map((category) => ({
                                value: category.id,
                                label: category.name,
                              }))[0]
                          }
                          onChange={(option) =>
                            setFieldValue(
                              "categoryId",
                              option ? option.value : ""
                            )
                          }
                          onBlur={handleBlur("categoryId")}
                          options={categories.map((category) => ({
                            value: category.id,
                            label: category.name,
                          }))}
                          className={`mt-1 bg-transparent border border-custom-green ${
                            errors &&
                            errors.categoryId &&
                            touched &&
                            touched.categoryId
                              ? "border-red-400"
                              : "border-custom-green"
                          } focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-sm mb-1`}
                          classNamePrefix="select"
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              borderColor: state.isFocused
                                ? "rgba(76, 206, 172, 0.4)"
                                : "rgba(76, 206, 172, 0.7)",
                              boxShadow: state.isFocused
                                ? "0 0 0 1px rgba(76, 206, 172, 0)"
                                : "",
                              "&:hover": {
                                borderColor: "rgba(76, 206, 172, 0.4)",
                              },
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isSelected
                                ? "#23A74C"
                                : state.isFocused
                                ? "#EAF2F1"
                                : provided.backgroundColor,
                              color: state.isSelected
                                ? "#FFFFFF"
                                : provided.color,
                              fontSize: "14px",
                            }),
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        />
                        <ErrorMessage name="categoryId">
                          {(msg) => (
                            <div className="text-xs text-red-500 text-left w-full">
                              <p>{msg}</p>
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </label>
                    <div className="">
                      <input
                        type="file"
                        name={`MaintenanceDocuments.file`}
                        onChange={(event) =>
                          handleFileChange(event, setFieldValue)
                        }
                        onBlur={handleBlur(`file`)}
                        className="mt-1 px-2 py-1 bg-transparent shadow-sm border-slate-400 placeholder-slate-400 block w-full rounded-md text-xs focus:ring-1 mb-1"
                      />

                      <ErrorMessage name="file">
                        {(msg) => (
                          <div className="text-xs text-red-500 text-left w-full">
                            <p>{msg}</p>
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>
                  <div className="flex gap mt-7 md:gap-2">
                    <button
                      type="button"
                      className="px-4 py-1 my-2 font-medium bg-[#FDE8EB] border rounded-md text-[#B21028] flex items-center text-base"
                      onClick={close}
                    >
                      <span className="mr-2">
                        <FaTimes size={12} />
                      </span>
                      <span className="text-base-extra">Cancel</span>
                    </button>

                    <button
                      className="px-4 py-1 my-2 font-medium bg-[#23A74C] text-sm text-white border rounded-md flex items-center text-base"
                      onClick={() => {
                        handleUploadDocument(values);
                      }}
                    >
                      <span className="mr-2">
                        <FaUpload size={12} />
                      </span>
                      <span className="text-base-extra">Upload</span>
                    </button>
                  </div>
                </Modal>
              </Form>
            </>
          );
        }}
      </Formik>
    </Layout>
  );
}
