import { Formik, Form, ErrorMessage } from "formik";
import Logo from "../../assets/img/mak-logo.svg";
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineKey } from "react-icons/ai";
import { BiSolidChevronLeft, BiSolidChevronRight, BiLock } from "react-icons/bi";
// import { BiSolidChevronRight } from "react-icons/bi";
import { useState } from "react";
import axios from "axios";
import { C } from "../../common"
import { newPasswordValidationSchema } from "../../helpers/validators";
import { toast } from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import classes from './AuthenticationImage.module.css';

import {
  Paper,
  Title,
} from '@mantine/core';

function NewPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  return (
    <div className={classes.wrapper}>

      <Helmet>
        <title>Verify Email - Mak Fleet</title>
      </Helmet>

      <Paper className={`${classes.form}`} radius={0} p={30}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={Logo} alt="MakFleet logo" width={80} />
        </div>

        <Title order={2} className={`${classes.title} text-md justify-center font-semibold text-[#222] flex items-center`} ta="center" mt="md" mb={50}>
          <BiLock size={20} className="mr-2" />
          <span>Verify Email</span>
        </Title>

        <Formik
          initialValues={{
            code: "",
            email: searchParams.get("email"),
            password: "",
            // confirmPassword: "",
          }}
          enableReinitialize
          validationSchema={newPasswordValidationSchema}
          onSubmit={async (values, { resetForm }) => {
            setLoading(true);

            try {
              const response = await axios
                .post(
                  // `${C.URLS.BASE_URL}/api/Authentication/confirm-password-reset`,
                  `${C.URLS.BASE_URL}/api/Authentication/verifyemail`,
                  values
                );
              const responseData = response.data;

              if (responseData.code === 'BadRequest') toast.error(responseData.message, { position: "bottom-center" });
              else if (response.status === 200 || response.status === 201) {
                toast.success(responseData.message, { position: "bottom-center" });
                navigate("/login");
                setLoading(false);
              } else {
                toast.error("An error occurred while processing your request.", { position: "bottom-center" });
              }
              setLoading(false);

            } catch (error) { }

            setLoading(false);
          }}
        >
          {({ values, handleChange, handleBlur }) => (
            <Form className="w-80 md:w-96 flex flex-col gap-4">

              <div className="flex flex-col gap-1 group text-center">
                <p className="text-sm">
                  Use the code that was shared on your email
                </p>
              </div>

              <div className="flex flex-col gap-1 group relative">
                {/* <div className="placeholder-neutral-500 focus:ring-0 focus:outline-none w-full rounded-sm bg-[#EFF5F180] flex items-center"> */}
                <div className="flex flex-col gap-1 group">
                  <label htmlFor="username_email" className="font-semibold text-base text-[#515861]" style={{ pointerEvents: 'none' }}>
                    EMAIL
                  </label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    onChange={handleChange("email")}
                    onBlur={handleBlur("email")}
                    className="mt-1 px-3 py-3 bg-gray-50 border border-[#d4e7e2] focus:outline-none focus:border-[#d4e7e2] focus:ring-gray-400 block w-full rounded-sm sm:text-base-extra focus:ring-0 mb-1 text-[#555]"
                    value={values.email}
                    readOnly
                  />
                </div>

                <ErrorMessage name="email">
                  {(msg) => (
                    <div className="text-xs text-red-500 text-left w-full">
                      <p>{msg}</p>
                    </div>
                  )}
                </ErrorMessage>
              </div>

              <div className="flex flex-col gap-1 group relative">
                {/* <div className="placeholder-neutral-500 focus:ring-0 focus:outline-none w-full rounded-sm bg-[#EFF5F180] flex items-center"> */}
                <div className="flex flex-col gap-1 group">
                  <label htmlFor="username_email" className="font-semibold text-base text-[#515861]" style={{ pointerEvents: 'none' }}>
                    CODE
                  </label>
                  <input
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    maxLength="6"
                    name="code"
                    placeholder="Code"
                    onChange={handleChange("code")}
                    onBlur={handleBlur("code")}
                    className="mt-1 px-3 py-3 bg-gray-50 border border-[#d4e7e2] focus:outline-none focus:border-custom-greener focus:ring-gray-400 block w-full rounded-sm sm:text-base-extra focus:ring-0 mb-1 text-[#000]"
                    value={values.code}
                  />
                </div>

                <ErrorMessage name="code">
                  {(msg) => (
                    <div className="text-xs text-red-500 text-left w-full">
                      <p>{msg}</p>
                    </div>
                  )}
                </ErrorMessage>
              </div>

              <div className="flex flex-col gap-1 group relative">
                {/* <div className="placeholder-neutral-500 focus:ring-0 focus:outline-none w-full rounded-sm bg-[#EFF5F180] flex items-center"> */}
                <label htmlFor="username_email" className="font-semibold text-base text-[#515861]" style={{ pointerEvents: 'none' }}>
                  PASSWORD
                </label>
                <div className=" placeholder-neutral-500 focus:ring-0 focus:outline-none w-full rounded-sm flex items-center">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    onChange={handleChange("password")}
                    onBlur={handleBlur("password")}
                    className="mt-1 px-3 py-3 bg-gray-50 border border-[#d4e7e2] focus:outline-none focus:border-custom-greener focus:ring-gray-400 block w-full rounded-sm sm:text-base-extra focus:ring-0 mb-1 text-[#000]"
                    value={values.password}
                  />
                  <span
                    className="absolute right-2 cursor-pointer"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    {showPassword ? (
                      <AiOutlineEyeInvisible size={20} />
                    ) : (
                      <AiOutlineEye size={20} />
                    )}
                  </span>
                </div>

                <ErrorMessage name="password">
                  {(msg) => (
                    <div className="text-xs text-red-500 text-left w-full">
                      <p>{msg}</p>
                    </div>
                  )}
                </ErrorMessage>
              </div>

              {/* <div className="flex flex-col gap-1 group relative">
                <label htmlFor="username_email" className="font-semibold text-base text-[#515861]" style={{ pointerEvents: 'none' }}>
                  REPEAT PASSWORD
                </label>

                <div className=" placeholder-neutral-500 focus:ring-0 focus:outline-none w-full rounded-sm flex items-center">
                  <input
                    type={showConfirm ? "text" : "password"}
                    name="confirmPassword"
                    placeholder="Repeat new password"
                    onChange={handleChange("confirmPassword")}
                    onBlur={handleBlur("confirmPassword")}
                    className="mt-1 px-3 py-3 bg-gray-50 border border-[#d4e7e2] focus:outline-none focus:border-custom-greener focus:ring-gray-400 block w-full rounded-sm sm:text-base-extra focus:ring-0 mb-1 text-[#000]"
                    value={values.confirmPassword}
                  />
                  <span
                    className="absolute right-2 cursor-pointer"
                    onClick={() => setShowConfirm((prev) => !prev)}
                  >
                    {showConfirm ? (
                      <AiOutlineEyeInvisible size={20} />
                    ) : (
                      <AiOutlineEye size={20} />
                    )}
                  </span>
                </div>

                <ErrorMessage name="confirmPassword">
                  {(msg) => (
                    <div className="text-xs text-red-500 text-left w-full">
                      <p>{msg}</p>
                    </div>
                  )}
                </ErrorMessage>
              </div> */}

              <button
                type="submit"
                className="bg-[#449D44] text-white py-3 px-4 mt text-base-xxl rounded-sm font-medium w-full cursor-pointer flex items-center justify-between hover:bg-[#5BBF61]"
              >
                <span>{loading ? "Activating..." : "Activate account"}</span>
                <BiSolidChevronRight />
              </button>

              <Link
                className={`w-full flex justify-end text-base-xxl font-medium items-center hover:underline`}
                type="button"
                to="/login"
              >
                <BiSolidChevronLeft />
                Login
              </Link>

            </Form>
          )}
        </Formik>

      </Paper>

      <div className={classes.image}></div>

    </div>
  );
}

export default NewPassword;
