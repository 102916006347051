import { Formik, Form, ErrorMessage } from "formik";
// import { MdClose } from "react-icons/md";
import axios from "axios";
import { C } from '../common';
import { useEffect, useState } from "react";

import { Pagination } from '@mantine/core';
import { TableSort } from "./TableSort";
import { MoonLoader } from 'react-spinners';

import {
    FaWrench,
    FaCarCrash,
} from 'react-icons/fa';

export function ViewVehicle({ vehicle }) {
    const [loading, setLoading] = useState(false);
    const [activePage, setPage] = useState(1);
    const [activeTab, setActiveTab] = useState('insurance');

    const [repairList, setRepairList] = useState({});
    const [insurancesList, setInsurancesList] = useState({});

    // console.log(vehicle);

    useEffect(() => {
        getRepairList();
        getInsuranceList();
    }, [activePage]);

    const getRepairList = async () => {
        setLoading(true);
        try {
            await axios
                .get(`${C.URLS.BASE_URL}/api/Maintenances?VehicleId=${vehicle.id}`)
                .then((res) => setRepairList(res.data.data));
        } catch (error) {
            console.error("Network Error:", error.message);
        }
        setLoading(false);
    };

    // console.log(insurancesList);

    const getInsuranceList = async () => {
        setLoading(true);
        try {
            await axios
                .get(`${C.URLS.BASE_URL}/api/Insurances`)
                .then((res) => {
                    if (res.data && res.data.data) {
                        setInsurancesList(res.data.data);
                    } else {
                        console.error("Unexpected API response structure:", res.data);
                        setInsurancesList({});
                    }
                });

        } catch (error) {
            console.error("Network Error:", error.message);
        }
        setLoading(false);
    };

    const {
        currentPage: repairsCurrentPage,
        pageSize: repairsPageSize,
        totalCount: repairsTotalCount,
        totalPages: repairsPages,
        collection: repairs,
        previousPage: repairsPreviousPage,
        nextPage: repairsNextPage,
    } = repairList;

    const isValidDate = (dateString) => {
        if (dateString === null) return false;

        const date = new Date(dateString);
        return !isNaN(date.getTime()) && date instanceof Date;
    };

    const tableRepairsFields = ['Reg no.', 'Type', 'Cost', 'Start date', 'End date', 'Duration', 'Status'];
    const tableInsuranceFields = ['Cost', 'Insurance Type', 'Insurance Date', 'Expiry Date'];

    const repairsRowData = repairList.collection?.map((repair) => {
        const startDate = isValidDate(repair.startDate) ? new Date(repair.startDate) : null;
        const endDate = isValidDate(repair.endDate) ? new Date(repair.endDate) : null;

        let duration = '_';
        if (startDate && endDate) {
            const timeDifference = endDate.getTime() - startDate.getTime();
            const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
            duration = daysDifference + ' days';
        }

        return {
            id: repair.id,
            'Reg no.': `${repair.vehicle.regNo || 'Unknown'}`,
            'Make/Model': `${repair.vehicle.vehicleMake ? repair.vehicle.vehicleMake.name : ''}`,
            'Type': `${repair.vehicle.vehicleType ? repair.vehicle.vehicleType.name : ''}`,
            'Cost': repair.cost,
            'Start date': startDate ? startDate.toLocaleDateString() : '_',
            'End date': endDate ? endDate.toLocaleDateString() : '_',
            'Duration': duration,
            'Status': repair.status,
        };
    }) || [];

    const {
        currentPage: insurancesCurrentPage,
        pageSize: insurancesPageSize,
        totalCount: insurancesTotalCount,
        totalPages: insurancesPages,
        collection: insurances,
        previousPage: insurancesPreviousPage,
        nextPage: insurancesNextPage,
    } = insurancesList;

    // const tableDriversFields = ['Name', 'Contact', 'Permit Classes', 'Verified'];
    // const tableInsuranceFields = ['Contact', 'Insurance Type', 'Insurance Date', 'Expiry Date'];
    const insurancesRowData = insurances?.map((insurance) => ({
        id: insurance.id,
        'Cost': `${insurance.cost}`,
        'Insurance Type': `${insurance.insuranceType}`,
        'Insurance Date': `${insurance.issuanceDate}`,
        'Expiry Date': `${insurance.expiryDate}`,
    })) || [];


    // console.log(insurancesRowData);
    // console.log(insurances);

    const renderTable = (data, fields, category) => {
        if (loading) {
            return (
                <div style={{ minHeight: '50vh' }} className="flex items-center justify-center">
                    <MoonLoader color="#4CCEAC" size={50} />
                </div>
            );
        }

        if (data) {
            return data.length > 0 ? (
                <TableSort
                    data={data}
                    fields={fields}
                    category={category}
                />
            ) : (
                <TableSort
                    data={[]}
                    fields={fields}
                    category={category}
                />
            );
        }

        return null;
    };

    const calculatePaginationRanges = (currentPage, pageSize, totalCount) => {
        if (totalCount === 0) {
            return { startRange: 0, endRange: 0 };
        }
        const startRange = (currentPage - 1) * pageSize + 1;
        const endRange = Math.min(startRange + pageSize - 1, totalCount);
        return { startRange, endRange };
    };

    const { startRange: repairsStartRange, endRange: repairsEndRange } = calculatePaginationRanges(activePage, repairsPageSize, repairsTotalCount);
    const { startRange: driversStartRange, endRange: driversEndRange } = calculatePaginationRanges(activePage, repairsPageSize, repairsTotalCount);
    // const { startRange: driversStartRange, endRange: driversEndRange } = calculatePaginationRanges(activePage, vehiclesPageSize, driversTotalCount);

    return (
        <div className="m-5">
            <div className="flex justify-between">

                <div className="flex">
                    <button
                        onClick={() => setActiveTab('maintainences')}
                        className={`rounded-l-md rounded-r-none flex gap-2 items-center font-medium text-base-extra py-2 px-4 ${activeTab === 'maintainences' ? 'bg-[#C1BCCD] text-white' : 'bg-gray-100 border'}`}>
                        <FaWrench size={12} />
                        Maintainence
                    </button>
                    <button
                        onClick={() => setActiveTab('insurance')}
                        className={`flex gap-2 items-center font-medium text-base-extra py-2 px-4 ${activeTab === 'insurance' ? 'bg-[#C1BCCD] text-white' : 'bg-gray-100 border'}`}>
                        <FaCarCrash size={16} />
                        Insurance
                    </button>
                </div>

            </div>

            {activeTab === 'maintainences' && (
                <>
                    {renderTable(repairsRowData, tableRepairsFields, 'fleeVehicleRepairs')}

                    <div className="px-3 py-2 mt-3 flex justify-end items-center font-medium">
                        <div className="text-sm mr-3" style={{ fontSize: '12px' }}>
                            Showing {repairsStartRange ? repairsStartRange : 0} to {repairsEndRange ? repairsEndRange : 0} of {repairsTotalCount} entries
                        </div>
                        <div className="">
                            <Pagination
                                value={activePage}
                                onChange={setPage}
                                total={repairsPages}
                                size="sm"
                                position="right"
                                withEdges
                                styles={(theme) => ({
                                    fontFamily: '',
                                    fontSize: '12px',
                                    control: {
                                        '&[data-active]': {
                                            backgroundImage: theme.fn.gradient({ from: '#C1BCCD', to: '#C1BCCD' }),
                                            border: 0,
                                        },
                                    },
                                })}
                            />
                        </div>
                    </div>
                </>
            )}

            {activeTab === 'insurance' && (
                <>
                    {renderTable(insurancesRowData, tableInsuranceFields, 'fleeVehicleInsurances')}

                    <div className="px-3 py-2 mt-3 flex justify-end items-center font-medium">
                        <div className="text-sm mr-3" style={{ fontSize: '12px' }}>
                            Showing {repairsStartRange ? repairsStartRange : 0} to {repairsEndRange ? repairsEndRange : 0} of {repairsTotalCount} entries
                        </div>
                        <div className="">
                            <Pagination
                                value={activePage}
                                onChange={setPage}
                                total={repairsPages}
                                size="sm"
                                position="right"
                                withEdges
                                styles={(theme) => ({
                                    fontFamily: '',
                                    fontSize: '12px',
                                    control: {
                                        '&[data-active]': {
                                            backgroundImage: theme.fn.gradient({ from: '#C1BCCD', to: '#C1BCCD' }),
                                            border: 0,
                                        },
                                    },
                                })}
                            />
                        </div>
                    </div>
                </>
            )}

        </div>
    );
}
