// @TODO: Add all appropriate constants over here..

export default {
    // SERVER_BASENAME: 'admin',
  
    URLS: {
      // BASE_URL: 'https://fleet-api-ug.azurewebsites.net',
      BASE_URL: 'https://fleet.mak.ac.ug/fleet',
    },

    PORTS: {
    //   LOCAL_DEV: 8000, // Change to 80 if we run 'make up'
    },

    TOOLTIPS: {

    },

    COOKIES: {
      // @TODO: This is probably only needed in development right now, remove it.
      AUTH_TOKEN: 'access_token',
      ROLES: 'roles',
    },

    STORAGE: {

    },
    
    ROLES: {
    },

    STATUS: {
    //   STATUSES
    },
    
    ENDPOINTS: {
      // BASE_URL: 'https://fleet.mak.ac.ug',
      ACTIVATION_URL: 'https://fleet.mak.ac.ug/#/new-password',
      USER_RESET_PASSWORD_URL: 'https://fleet.mak.ac.ug/#/new-password?email=${values.username_email}',
    },

    RESPONSE_TYPE: {
      TEXT: 'text',
      ARRAY_BUFFER: 'arraybuffer',
    },

    FORMS: {
      EMPTY_LABEL: '<none>',
    },

    COLORS: {
      // @TODO: Use a common constants file with the dashboard
      GREY: 'grey',
      WHITE: 'white',
      SECONDARY: '#898989',
      HOVERED_COLOR: '#9494942e',
      PRIMARY_COLOR: '#2a79a1',
      BRAND_COLOR: '#21406c',
      TAGS: ['#FFAC4A', '#30A8FF', '#F85461', '#6BB685', '#277095'],
    },

    CSS: {
      // @TODO: To common
      MODAL_STYLE: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(42, 121, 161, 0.7)',
      },
    },

  };
  