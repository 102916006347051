import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import { C } from "../../common";
import toast from "react-hot-toast";
// import { getFinancialReports } from "../../helpers/validators";
import { useEffect, useState } from "react";
import { Pagination } from "@mantine/core";
import Select from "react-select";
import { Layout } from "../../components/Layout";

import { TableSort } from "../../components/TableSort";
import { MoonLoader } from "react-spinners";
import { FaDatabase, FaMinusCircle } from "react-icons/fa";

const UnitReports = () => {
  const [loading, setLoading] = useState(false);
  const [selectedReportArea, setSelectedReportArea] = useState(1);
  const [unitsData, setUnitsData] = useState([]);
  const [activePage, setPage] = useState(1);

  const [reportTypes, setReportTypes] = useState([
    { id: 1, name: "Units" },
    { id: 2, name: "Vehicles" },
    { id: 3, name: "Drivers" },
    { id: 4, name: "Garages" },
    { id: 5, name: "Financial" },
    { id: 6, name: "Maintenances" },
  ]);

  useEffect(() => {

    const fetchData = async () => {
      setLoading(true);

      try {
        const response = await axios.get(`${C.URLS.BASE_URL}/api/Units`, {
          params: {
            // Location: values.unitLocation,
            PageNumber: activePage,
          },
        });

        const responseData = response.data.data;
        setUnitsData(responseData);

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, [activePage]);

  const unitLocations = ["MUK", "Makerere", "Kabanyolo", "Makerere University"];

  const today = new Date();
  const currentYear = today.getFullYear();
  const firstOfJanuary = new Date(currentYear, 0, 1);
  const toDateString = today.toISOString().split("T")[0];
  const fromDateString = firstOfJanuary.toISOString().split("T")[0];

  const {
    currentPage,
    pageSize,
    totalCount,
    totalPages,
    collection: fleetUnits,
    previousPage,
    nextPage,
  } = unitsData;

  // console.log(unitsData);

  const tableFields = ["Name", "Location", "Administrator"];
  const rowData = fleetUnits?.map((unit) => ({
    id: unit.id,
    'Name': `${unit.name}`,
    'Location': unit.location,
    'Administrator': `${unit.userUnits[0]?.user?.firstName || ''} ${unit.userUnits[0]?.user?.lastName || ''}`,
  })) || [];

  const calculatePaginationRanges = (currentPage, pageSize, totalCount) => {
    if (totalCount === 0) {
      return { startRange: 0, endRange: 0 };
    }
    const startRange = (currentPage - 1) * pageSize + 1;
    const endRange = Math.min(startRange + pageSize - 1, totalCount);
    return { startRange, endRange };
  };

  const { startRange, endRange } = calculatePaginationRanges(
    activePage,
    pageSize,
    totalCount
  );

  return (
    <Layout>
      <Formik
        initialValues={{
          reportTypeId: 1,
          unitLocation: "",
          To: toDateString,
          From: fromDateString,
        }}
        // validationSchema={getFinancialReports}

        enableReinitialize
        onSubmit={async (values, { resetForm }) => {
          setLoading(true);
          // console.log(values);
          try {
            const response = await axios.get(`${C.URLS.BASE_URL}/api/Units`, {
              params: {
                Location: values.unitLocation,
                // From: values.From,
                // To: values.To,
              },
            });
            const responseData = response.data.data;
            setUnitsData(responseData);

            if (responseData.code === "BadRequest") {
              const errorMessage =
                responseData.message ||
                "Oops! It looks like some information is missing or incorrect";
              toast.error(errorMessage, { position: "bottom-center" });
            } else if (response.status === 200 || response.status === 201) {
              const successMessage =
                responseData.message || "Successfully loaded";
              toast.success(successMessage, {
                position: "bottom-center",
              });
              setLoading(false);
            } else {
              toast.error("An error occurred while processing your request.", {
                position: "bottom-center",
              });
            }

            setLoading(false);
          } catch (error) { }
        }}
      >
        {({
          values,
          setFieldValue,
          handleChange,
          handleBlur,
          errors,
          touched,
          resetForm,
        }) => {
          return (
            <>
              <Form className="h-full overflow-y-scroll">
                <main>
                  <div className="flex justify-between items-center gap-3 md:gap-5 px-2">

                    {/* Report Area */}
                    <div className="w-1/2 flex flex-wrap -mx-2">
                      <div className="pr-1 flex flex-col">
                        <label className="block mb-5">
                          <span className="block text-base font-semibold text-primary-color">
                            Report area
                          </span>
                          <div className="">
                            <Select
                              isSearchable
                              name="reportTypeId"
                              value={
                                reportTypes
                                  .filter(
                                    (type) => type.id === values.reportTypeId
                                  )
                                  .map((type) => ({
                                    value: type.id,
                                    label: type.name,
                                  }))[0]
                              }
                              isDisabled={true}
                              onChange={(option) => {
                                const newValue = option ? option.value : "";
                                setFieldValue("reportTypeId", newValue);
                                setSelectedReportArea(newValue);
                              }}
                              options={reportTypes.map((type) => ({
                                value: type.id,
                                label: type.name,
                              }))}
                              className={`mt-1 bg-gray-50 block w-full rounded-md sm:text-base focus:ring-0 mb-1`}
                              classNamePrefix="select"
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  borderColor: state.isFocused
                                    ? "rgba(76, 206, 172, 0.4)"
                                    : "rgba(76, 206, 172, 0.7)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px rgba(76, 206, 172, 0)"
                                    : "",
                                  "&:hover": {
                                    borderColor: "rgba(76, 206, 172, 0.4)",
                                  },
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: state.isSelected
                                    ? "#23A74C"
                                    : state.isFocused
                                      ? "#EAF2F1"
                                      : provided.backgroundColor,
                                  color: state.isSelected
                                    ? "#FFFFFF"
                                    : provided.color,
                                }),
                              }}
                            />
                            <ErrorMessage name="reportTypeId">
                              {(msg) => (
                                <div className="text-xs text-red-500 text-left w-full">
                                  <p>{msg}</p>
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                        </label>
                      </div>

                      <div className="w-1/4 pr-1 flex flex-col">
                        <label className="block mb-5">
                          <span className="block text-base font-semibold text-primary-color">
                            Unit Location
                          </span>
                          <div className="">
                            <Select
                              isSearchable
                              name="unitLocation"
                              value={
                                values.unitLocation
                                  ? {
                                    value: values.unitLocation,
                                    label: values.unitLocation,
                                  }
                                  : null
                              }
                              onChange={(option) =>
                                setFieldValue(
                                  "unitLocation",
                                  option ? option.value : ""
                                )
                              }
                              onBlur={handleBlur("unitLocation")}
                              options={unitLocations.map((unitLocation) => ({
                                value: unitLocation,
                                label: unitLocation,
                              }))}
                              className={`mt-1 bg-gray-50 block w-full rounded-md sm:text-base focus:ring-0 mb-1`}
                              classNamePrefix="select"
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  borderColor: state.isFocused
                                    ? "rgba(76, 206, 172, 0.4)"
                                    : "rgba(76, 206, 172, 0.7)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px rgba(76, 206, 172, 0)"
                                    : "",
                                  "&:hover": {
                                    borderColor: "rgba(76, 206, 172, 0.4)",
                                  },
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: state.isSelected
                                    ? "#23A74C"
                                    : state.isFocused
                                      ? "#EAF2F1"
                                      : provided.backgroundColor,
                                  color: state.isSelected
                                    ? "#FFFFFF"
                                    : provided.color,
                                }),
                              }}
                            />
                          </div>
                        </label>
                      </div>

                    </div>

                    <div className="w-1/2 flex flex-wrap justify-end -mx-2">
                      <div className="flex justify-end">
                        <button
                          type="button"
                          className="px-4 py-2 my-2 font-medium bg-[#E1F5E2] mr-2 text-base text-[#006400] border rounded-md flex items-center justify-center"
                          onClick={() => resetForm()}
                        >
                          <span className="mr-2">
                            <FaMinusCircle size={14} />
                          </span>
                          <span>Clear fields</span>
                        </button>
                      </div>
                      <div className="flex justify-end">
                        <button
                          type="submit"
                          className="px-4 py-2 my-2 font-medium bg-[#23A74C] text-base text-white border rounded-md flex items-center justify-center"
                        >
                          <span className="mr-2">
                            <FaDatabase size={14} />
                          </span>
                          <span>{loading ? "Loading..." : "Load data"}</span>
                        </button>
                      </div>
                    </div>

                  </div>

                  <div className="mb-2">
                    <ErrorMessage name="unitLocation">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>

                    {/* <ErrorMessage name="From">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                    <ErrorMessage name="To">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage> */}
                  </div>

                  {/* Section 1 */}
                  <div className="bg-white border border-gray-200 rounded-md px-3 pt-2 pb-5">
                    {!loading ? (
                      <>
                        {unitsData && (
                          <TableSort
                            data={rowData}
                            fields={tableFields}
                            category={"fleetUnits"}
                          />
                        )}

                      </>
                    ) : (
                      <div className="w-full flex justify-center">
                        <div
                          style={{ minHeight: "20vh" }}
                          className="flex items-center justify-center"
                        >
                          <div className="flex justify-center px-5 py-5">
                            <MoonLoader color="#4CCEAC" size={50} />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <>
                    <div className="px-3 py-2 mt-3 flex justify-end items-center font-medium">
                      <div
                        className="text-sm mr-3"
                        style={{ fontSize: "12px" }}
                      >
                        Showing {startRange ? startRange : 0} to{" "}
                        {endRange ? endRange : 0} of {totalCount} entries
                      </div>
                      <div className="">
                        <Pagination
                          value={activePage}
                          onChange={setPage}
                          total={totalPages}
                          size="sm"
                          position="right"
                          withEdges
                          styles={(theme) => ({
                            fontFamily: "Outfit",
                            fontSize: "12px",
                            control: {
                              "&[data-active]": {
                                backgroundImage: theme.fn.gradient({
                                  from: "#C1BCCD",
                                  to: "#C1BCCD",
                                }),
                                border: 0,
                              },
                            },
                          })}
                        />
                      </div>
                    </div>
                  </>
                </main>
              </Form>
            </>
          );
        }}
      </Formik>
    </Layout>
  );
};

export default UnitReports;
