import { Formik, Form, ErrorMessage } from "formik";
// import { MdClose } from "react-icons/md";
import axios from "axios";
import { C } from "../common";
import PlaceholderImage from "../assets/img/image-placeholder.png";
import toast from "react-hot-toast";
import { assignUnitAdminValidationSchema } from "../helpers/validators";
import { useEffect, useState } from "react";

import { useDisclosure } from "@mantine/hooks";
import { Modal, useMantineTheme } from "@mantine/core";

import { useNavigate, useParams } from "react-router-dom";
import { Layout } from "./Layout";

import Select from "react-select";

import {
  FaCaretLeft,
  FaTrash,
  FaSave,
  FaTimes,
  FaShieldAlt,
} from "react-icons/fa";

export function EditVehicle() {
  const [loading, setLoading] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [openedInsurance, { open: openInsurance, close: closeInsurance }] =
    useDisclosure(false);
  const theme = useMantineTheme();

  const [currentVehicle, setCurrentVehicle] = useState({});
  const [insuranceProviders, setInsuranceProviders] = useState([]);
  const [typeMakes, setTypeMakes] = useState([]);
  const [units, setUnits] = useState([]);
  const [uploadedImage, setUploadedImage] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();

  // console.log(currentVehicle);

  const insuranceTypes = [
    { name: "None", value: "NOT_SET" },
    { name: "Motor third party", value: "MotorThirdParty" },
    { name: "Comprehensive", value: "Comprehensive" },
  ];

  useEffect(() => {
    getVehicle();
    fetchVehicleTypeMakes();
    fetchUnits();
    fetchInsuranceProviders();
  }, []);

  // console.log(insuranceProviders);

  const getVehicle = async () => {
    setLoading(true);
    try {
      await axios
        .get(`${C.URLS.BASE_URL}/api/Vehicles/${id}`)
        .then((res) => setCurrentVehicle(res.data.data));
    } catch (error) {
      console.error("Network Error:", error.message);
    }
    setLoading(false);
  };

  const fetchVehicleTypeMakes = async () => {
    try {
      const allVehicleTypeMakes = [];
      let currentPage = 1;

      while (true) {
        const response = await axios.get(
          `${C.URLS.BASE_URL}/api/VehicleTypeMakes?PageNumber=${currentPage}`
        );
        const typeMakeData = response.data.data.collection;

        if (typeMakeData.length === 0) {
          break;
        }

        allVehicleTypeMakes.push(...typeMakeData);
        currentPage++;
      }

      setTypeMakes(allVehicleTypeMakes);
    } catch (error) {
      console.error(error.message);
    }
  };

  const fetchUnits = async () => {
    try {
      const allUnits = [];
      let currentPage = 1;

      while (true) {
        // const response = await axios.get(`${C.URLS.BASE_URL}/api/VehicleTypeMakes?PageNumber=${currentPage}`);
        const response = await axios.get(
          `${C.URLS.BASE_URL}/api/Units?PageNumber=${currentPage}`
        );
        const unitsData = response.data.data.collection;

        if (unitsData.length === 0) {
          break;
        }

        allUnits.push(...unitsData);
        currentPage++;
      }

      setUnits(allUnits);
    } catch (error) {
      console.error(error.message);
    }
  };

  const fetchInsuranceProviders = async () => {
    try {
      await axios
        .get(`${C.URLS.BASE_URL}/api/Insurances/providers`)
        .then((res) => setInsuranceProviders(res.data.data.collection));
    } catch (error) {
      console.error("Network Error:", error.message);
    }
  };

  const handleBackClick = () => {
    navigate(`/vehicles`);
  };

  const addInsuranceDetails = async (values) => {
    setLoading(true);
    console.log(values);

    try {
      await axios.post(`${C.URLS.BASE_URL}/api/Insurances`, {
        vehicleId: values.vehicleId,
        providerId: values.providerId,
        cost: values.cost,
        insuranceType: values.InsuranceType,
        issuanceDate: values.issuanceDate,
        expiryDate: values.expiryDate,
      });

      toast.success("Insurance details added successfully.", {
        position: "bottom-center",
      });
    } catch (error) {
      toast.error(`Failed to add insurance details. Error: ${error.message}`, {
        position: "bottom-center",
      });
    } finally {
      close();
      handleBackClick();
    }
  };

  const handleDeleteConfirm = async () => {
    const updatedVehicle = {
      ...currentVehicle,
      isDeleted: true,
    };

    try {
      await axios.put(
        `${C.URLS.BASE_URL}/api/Vehicles/${updatedVehicle.id}`,
        updatedVehicle
      );
      toast.success("Vehicle deleted.", {
        position: "bottom-center",
      });
    } catch (error) {
      toast.error(`Failed to delete vehicle. Error: ${error.message}`, {
        position: "bottom-center",
      });
    } finally {
      close();
      handleBackClick();
    }
  };

  return (
    <Layout>
      <Formik
        initialValues={{
          id: currentVehicle.id,
          regNo: currentVehicle.regNo,
          vehicleMakeId: currentVehicle.vehicleMakeId,
          vehicleTypeId: currentVehicle.vehicleTypeId,
          yearOfManufacture: currentVehicle.yearOfManufacture,
          engineNo: currentVehicle.engineNo,
          unitId: currentVehicle.unitId,
          regBook: currentVehicle.regBook,
          chasisNumber: currentVehicle.chasisNumber,
          remarks: currentVehicle.remarks,
          isDeleted: currentVehicle.isDeleted,
          deleteReason: currentVehicle.deleteReason,
          metaData: currentVehicle.metaData,
          // imageUrl: currentVehicle.imageUrl,
          dateOfAcquisition: currentVehicle.dateOfAcquisition,
          status: currentVehicle.status,
          costOfAquisition: currentVehicle.costOfAquisition,
          mileage: currentVehicle.mileage,
          subUnit: currentVehicle.subUnit,
          providerId: "",
          cost: 0,
          issuanceDate: "",
          expiryDate: "",
          insuranceType: "NOT_SET",
          vehicleId: currentVehicle.id,
          ImageFile: currentVehicle.ImageFile,
        }}
        enableReinitialize
        onSubmit={async (values) => {
          setLoading(true);
          console.log(values);

          const formData = new FormData();
          for (const key in values) {
            formData.append(key, values[key]);
          }
          // Append the file as 'ImageFile'
          if (values.file) {
            formData.append("ImageFile", values.file);
          }

          try {
            await axios
              .put(`${C.URLS.BASE_URL}/api/Vehicles/${id}`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then(() =>
                toast.success("Vehicle updated successfully.", {
                  position: "bottom-center",
                })
              )
              .then(async () => {
                setLoading(false);
              })
              .catch((error) => {
                toast.error(`${error.message}`, {
                  position: "bottom-center",
                });
                setLoading(false);
              });
          } catch (error) {}
        }}
      >
        {({
          values,
          setFieldValue,
          handleChange,
          handleBlur,
          errors,
          touched,
        }) => {
          return (
            <>
              <Form className="h-full overflow-y-scroll">
                <main>
                  <div className="flex justify-between items-center gap-3 md:gap-5 mb-2">
                    <button
                      className="px-4 py-2 my-2 font-medium bg-white border rounded-md text-[#23A74C] flex items-center text-base"
                      onClick={handleBackClick}
                    >
                      <span className="mr-2">
                        <FaCaretLeft size={14} />
                      </span>
                      <span className="text-base-extra">View all vehicles</span>
                    </button>

                    <div className="flex gap-3 md:gap-5">
                      <button
                        type="button"
                        className="px-4 py-2 my-2 font-medium bg-[#FDE8EB] border rounded-md text-[#B21028] flex items-center text-base"
                        onClick={open}
                      >
                        <span className="mr-2">
                          <FaTrash size={14} />
                        </span>
                        <span className="text-base-extra">Delete vehicle</span>
                      </button>

                      <button
                        type="button"
                        className="px-4 py-2 my-2 font-medium bg-[#98FB98]/20 border border-[#006400]/10 rounded-md text-[#006400] flex items-center text-base"
                        onClick={openInsurance}
                      >
                        <span className="mr-2">
                          <FaShieldAlt size={14} />
                        </span>
                        <span className="text-base-extra">Add insurance</span>
                      </button>

                      <button
                        type="submit"
                        className="px-4 py-2 my-2 font-medium bg-[#23A74C] text-sm text-white border rounded-md flex items-center text-base"
                      >
                        <span className="mr-2">
                          <FaSave size={14} />
                        </span>
                        <span className="text-base-extra">
                          {loading ? "Updating vehicle..." : "Update vehicle"}
                        </span>
                      </button>
                    </div>
                  </div>

                  {/* Section 1 */}
                  <div class="flex bg-white border border-gray-200 rounded-md px-10 pt-10 pb-5">
                    {/* Left Column */}
                    <div class="w-1/2 pr-5">
                      {/* Registration number */}
                      <label className="block mb-5 w-full ">
                        <span className="block text-base font-semibold text-primary-color">
                          Registration number
                        </span>
                        <div className="">
                          <input
                            type="text"
                            name="regNo"
                            placeholder="Enter Reg No."
                            onChange={handleChange("regNo")}
                            onBlur={handleBlur("regNo")}
                            value={values.regNo}
                            className={`mt-1 px-3 py-2 bg-gray-50 border border-custom-green capitalize focus:outline-none focus:border-custom-greener focus:ring-gray-400 block w-full rounded-md sm:text-base focus:ring-0 mb-1`}
                          />
                        </div>
                      </label>

                      {/* Current Status */}
                      <label className="block mb-5">
                        <span className="block text-base font-semibold text-primary-color">
                          Current status
                        </span>
                        <div className="">
                          <select
                            name="status"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.status}
                            className={`mt-1 px-3 py-2 bg-transparent border border-custom-green ${
                              errors &&
                              errors.status &&
                              touched &&
                              touched.status
                                ? "border-red-400"
                                : "border-custom-green"
                            }  placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-base mb-1`}
                          >
                            <option value="">
                              -- Select Current Status --
                            </option>
                            <option value="PARKED">Parked</option>
                            <option value="RUNNING">Running</option>
                            <option value="DISPOSED">Disposed</option>
                            <option value="OBSOLETE">Obsolete</option>
                            <option value="GROUNDED">Grounded</option>
                            <option value="SOLD">Sold</option>
                          </select>
                        </div>
                      </label>

                      {/* Units */}
                      <label className="block mb-5">
                        <span className="block text-base font-semibold text-primary-color">
                          Unit
                        </span>
                        <div className="">
                          <select
                            name="unitId"
                            onChange={handleChange("unitId")}
                            onBlur={handleBlur("unitId")}
                            value={values.unitId}
                            className={`mt-1 px-3 py-2 bg-transparent border border-custom-green ${
                              errors &&
                              errors?.unitId &&
                              touched &&
                              touched?.unitId
                                ? "border-red-400"
                                : "border-custom-green"
                            }  placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-green block w-full rounded-md sm:text-base mb-1`}
                          >
                            <option value="">-- Select Unit --</option>
                            {units &&
                              units.length > 0 &&
                              units.map((unit) => (
                                <option key={unit.id} value={unit.id}>
                                  {unit.name}
                                </option>
                              ))}
                          </select>
                          <ErrorMessage name="vehicleMakeId">
                            {(msg) => (
                              <div className="text-xs text-red-500 text-left w-full">
                                <p>{msg}</p>
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </label>

                      {/* Subunit */}
                      <label className="block mb-5">
                        <span className="block text-base text-primary-color font-semibold">
                          User Unit
                        </span>
                        <div className="">
                          <input
                            type="text"
                            name="subUnit"
                            placeholder="Enter user unit"
                            onChange={handleChange("subUnit")}
                            onBlur={handleBlur("subUnit")}
                            value={values.subUnit}
                            className={`mt-1 px-3 py-2 bg-transparent border border-custom-green ${
                              errors &&
                              errors?.subUnit &&
                              touched &&
                              touched?.subUnit
                                ? "border-red-400"
                                : "border-custom-green"
                            }  placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-base mb-1`}
                          />
                          <ErrorMessage name="subUnit">
                            {(msg) => (
                              <div className="text-xs text-red-500 text-left w-full">
                                <p>{msg}</p>
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </label>
                    </div>

                    {/* Right Column */}
                    <div class="w-1/2 h-full">
                      <label className="block mb-5 rounded-sm">
                        <span className="block text-base text-primary-color font-semibold">
                          Vehicle form{" "}
                          <i className="text-red">(max size: 3MB)</i>
                        </span>
                        <input
                          className="mt-1 px-3 py-2 bg-transparent border block text-base text-primary-color block w-full focus:border-custom-greener focus:ring-custom-greener rounded-md"
                          id="file"
                          name="file"
                          type="file"
                          onChange={(event) => {
                            // event.preventDefault();
                            setFieldValue("file", event.currentTarget.files[0]);
                            // Set the uploaded image state to display the preview
                            setUploadedImage(
                              URL.createObjectURL(event.currentTarget.files[0])
                            );
                          }}
                        />
                        <div className="flex max-h-52 pt-2">
                          <img
                            className="object-cover"
                            src={
                              uploadedImage
                                ? uploadedImage
                                : currentVehicle.imageUrl
                                ? currentVehicle.imageUrl
                                : PlaceholderImage
                            }
                            alt="Vehicle Preview"
                            style={{}}
                          />
                        </div>
                      </label>
                    </div>
                  </div>

                  {/* Section 2 */}
                  {/* <div className="bg-white shadow-md border border-[#E4E4E7]/50 rounded-md mt-2 px-10 pt-10 pb-5 grid grid-cols-3 gap-5"> */}
                  <div className="bg-white shadow-md border border-gray-200 rounded-md px-10 mt-1 pt-10 pb-5 flex">
                    {/* First Column */}
                    {/* <div className="col-span-1"> */}
                    <div className="w-1/2 pr-5">
                      {/* Make/Model */}
                      <label className="block mb-5">
                        <span className="block text-base font-semibold text-primary-color">
                          Make/Model
                        </span>
                        <div className="">
                          <select
                            name="vehicleMakeId"
                            onChange={handleChange("vehicleMakeId")}
                            onBlur={handleBlur("vehicleMakeId")}
                            value={values.vehicleMakeId}
                            className={`mt-1 px-3 py-2 bg-transparent border border-custom-green ${
                              errors &&
                              errors?.vehicleMakeId &&
                              touched &&
                              touched?.vehicleMakeId
                                ? "border-red-400"
                                : "border-custom-green"
                            }  placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-green block w-full rounded-md sm:text-base mb-1`}
                          >
                            <option value="">-- Select Make/Model --</option>
                            {typeMakes &&
                              typeMakes.length > 0 &&
                              typeMakes
                                .filter(
                                  (makesModel) =>
                                    makesModel.typeMakeModelStatus ===
                                    "MAKEMODEL"
                                )
                                .map((makesModel) => (
                                  <option
                                    key={makesModel.id}
                                    value={makesModel.id}
                                  >
                                    {makesModel.name}
                                  </option>
                                ))}
                          </select>
                          <ErrorMessage name="vehicleMakeId">
                            {(msg) => (
                              <div className="text-xs text-red-500 text-left w-full">
                                <p>{msg}</p>
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </label>

                      {/* Year Of Manufacture */}
                      <label className="block mb-5">
                        <span className="block text-base text-primary-color font-semibold">
                          Year Of manufacture
                        </span>
                        <div className="">
                          <input
                            type="text"
                            name="yearOfManufacture"
                            placeholder="Enter year of manufacture"
                            onChange={handleChange("yearOfManufacture")}
                            onBlur={handleBlur("yearOfManufacture")}
                            value={values.yearOfManufacture}
                            className={`mt-1 px-3 py-2 bg-transparent border border-custom-green ${
                              errors &&
                              errors?.yearOfManufacture &&
                              touched &&
                              touched?.yearOfManufacture
                                ? "border-red-400"
                                : "border-custom-green"
                            }  placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-base mb-1`}
                          />
                          <ErrorMessage name="yearOfManufacture">
                            {(msg) => (
                              <div className="text-xs text-red-500 text-left w-full">
                                <p>{msg}</p>
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </label>

                      {/* Types */}
                      <label className="block mb-5">
                        <span className="block text-base font-semibold text-primary-color">
                          Type
                        </span>
                        <div className="">
                          <select
                            name="vehicleTypeId"
                            onChange={handleChange("vehicleTypeId")}
                            onBlur={handleBlur("vehicleTypeId")}
                            value={values.vehicleTypeId}
                            className={`mt-1 px-3 py-2 bg-transparent border border-custom-green ${
                              errors &&
                              errors?.vehicleTypeId &&
                              touched &&
                              touched?.vehicleTypeId
                                ? "border-red-400"
                                : "border-custom-green"
                            }  placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-base mb-1`}
                          >
                            <option value="">-- Select Type --</option>
                            {typeMakes &&
                              typeMakes.length > 0 &&
                              typeMakes
                                .filter(
                                  (makesModel) =>
                                    makesModel.typeMakeModelStatus === "TYPE"
                                )
                                .map((makesModel) => (
                                  <option
                                    key={makesModel.id}
                                    value={makesModel.id}
                                  >
                                    {makesModel.name}
                                  </option>
                                ))}
                          </select>
                          <ErrorMessage name="vehicleTypeId">
                            {(msg) => (
                              <div className="text-xs text-red-500 text-left w-full">
                                <p>{msg}</p>
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </label>

                      {/* Mileage */}
                      <label className="block mb-5">
                        <span className="block text-base text-primary-color font-semibold">
                          Mileage (KM)
                        </span>
                        <div className="">
                          <input
                            type="number"
                            name="mileage"
                            placeholder="Enter Vehicle Mileage"
                            onChange={handleChange("mileage")}
                            onBlur={handleBlur("mileage")}
                            value={values.mileage}
                            className={`mt-1 px-3 py-2 bg-transparent border border-custom-green ${
                              errors &&
                              errors?.mileage &&
                              touched &&
                              touched?.mileage
                                ? "border-red-400"
                                : "border-custom-green"
                            }  placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-base mb-1`}
                          />
                          <ErrorMessage name="mileage">
                            {(msg) => (
                              <div className="text-xs text-red-500 text-left w-full">
                                <p>{msg}</p>
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </label>
                    </div>

                    {/* Second Column */}
                    {/* <div className="col-span-1"> */}
                    <div className="w-1/2 pr-5">
                      {/* Registration book holder */}
                      <label className="block mb-5">
                        <span className="block text-base text-primary-color font-semibold">
                          Registration book holder
                        </span>
                        <div className="">
                          <input
                            type="text"
                            name="regBook"
                            placeholder="Enter registration book holder"
                            onChange={handleChange("regBook")}
                            onBlur={handleBlur("regBook")}
                            value={values.regBook}
                            className={`mt-1 px-3 py-2 bg-transparent border border-custom-green ${
                              errors &&
                              errors?.regBook &&
                              touched &&
                              touched?.regBook
                                ? "border-red-400"
                                : "border-custom-green"
                            }  placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-base mb-1`}
                          />
                          <ErrorMessage name="regBook">
                            {(msg) => (
                              <div className="text-xs text-red-500 text-left w-full">
                                <p>{msg}</p>
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </label>

                      {/* Engine number */}
                      <label className="block mb-5">
                        <span className="block text-base text-primary-color font-semibold">
                          Engine number
                        </span>
                        <div className="">
                          <input
                            type="text"
                            name="engineNo"
                            placeholder="Enter engine number"
                            onChange={handleChange("engineNo")}
                            onBlur={handleBlur("engineNo")}
                            value={values.engineNo}
                            className={`mt-1 px-3 py-2 bg-transparent border border-custom-green ${
                              errors &&
                              errors?.engineNo &&
                              touched &&
                              touched?.regBook
                                ? "border-red-400"
                                : "border-custom-green"
                            }  placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-base mb-1`}
                          />
                          <ErrorMessage name="regBook">
                            {(msg) => (
                              <div className="text-xs text-red-500 text-left w-full">
                                <p>{msg}</p>
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </label>

                      {/* Chasis number */}
                      <label className="block mb-5">
                        <span className="block text-base text-primary-color font-semibold">
                          Chasis number
                        </span>
                        <div className="">
                          <input
                            type="text"
                            name="chasisNumber"
                            placeholder="Enter chasis number"
                            onChange={handleChange("chasisNumber")}
                            onBlur={handleBlur("chasisNumber")}
                            value={values.chasisNumber}
                            className={`mt-1 px-3 py-2 bg-transparent border border-custom-green ${
                              errors &&
                              errors?.chasisNumber &&
                              touched &&
                              touched?.chasisNumber
                                ? "border-red-400"
                                : "border-custom-green"
                            }  placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-base mb-1`}
                          />
                          <ErrorMessage name="chasisNumber">
                            {(msg) => (
                              <div className="text-xs text-red-500 text-left w-full">
                                <p>{msg}</p>
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </label>
                    </div>
                  </div>
                </main>

                <Modal
                  opened={openedInsurance}
                  onClose={closeInsurance}
                  title="Insurance"
                  overlayProps={{
                    color:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[9]
                        : theme.colors.gray[2],
                    opacity: 0.55,
                    blur: 3,
                  }}
                >
                  <div className="border-t border-[#EEE] pt-3 mb-3">
                    {/* <p className="text-base-extra fpmt-medium text-primary-color leading-snug">
                      Adding insurance details.
                    </p> */}
                  </div>

                  {/* Insurance providers */}
                  <label className="block py-2">
                    <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base font-semibold text-primary-color">
                      Select provider
                    </span>
                    <div className="">
                      <Select
                        isSearchable
                        // menuPortalTarget={document.body}
                        name="providerId"
                        value={
                          insuranceProviders
                            .filter(
                              (provider) => provider.id === values.providerId
                            )
                            .map((provider) => ({
                              value: provider.id,
                              label: provider.name,
                            }))[0]
                        }
                        onChange={(option) =>
                          setFieldValue(
                            "providerId",
                            option ? option.value : ""
                          )
                        }
                        onBlur={handleBlur("providerId")}
                        options={insuranceProviders.map((provider) => ({
                          value: provider.id,
                          label: provider.name,
                        }))}
                        className={`mt-1 bg-gray-50 block w-full rounded-md sm:text-base focus:ring-0 mb-1 ${
                          errors &&
                          errors.providerId &&
                          touched &&
                          touched.providerId
                            ? "border-red-400"
                            : "border-custom-green"
                        } focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-base mb-1`}
                        classNamePrefix="select"
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            borderColor: state.isFocused
                              ? "rgba(76, 206, 172, 0.4)"
                              : "rgba(76, 206, 172, 0.7)",
                            boxShadow: state.isFocused
                              ? "0 0 0 1px rgba(76, 206, 172, 0)"
                              : "",
                            "&:hover": {
                              borderColor: "rgba(76, 206, 172, 0.4)",
                            },
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#23A74C"
                              : state.isFocused
                              ? "#EAF2F1"
                              : provided.backgroundColor,
                            color: state.isSelected
                              ? "#FFFFFF"
                              : provided.color,
                          }),
                        }}
                      />
                      <ErrorMessage name="providerId">
                        {(msg) => (
                          <div className="text-xs text-red-500 text-left w-full">
                            <p>{msg}</p>
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                  </label>

                  {/* Insurance Types */}
                  <label className="block mb-5">
                    <span className="block text-base font-semibold text-primary-color">
                      Insurance type
                    </span>
                    <div className="">
                      <Select
                        isSearchable
                        name="InsuranceType"
                        value={
                          values.InsuranceType
                            ? {
                                value: values.InsuranceType,
                                label: insuranceTypes.find(
                                  (type) => type.value === values.InsuranceType
                                )?.name,
                              }
                            : null
                        }
                        onChange={(option) =>
                          setFieldValue(
                            "InsuranceType",
                            option ? option.value : ""
                          )
                        }
                        onBlur={handleBlur("InsuranceType")}
                        options={insuranceTypes.map((type) => ({
                          value: type.value,
                          label: type.name,
                        }))}
                        className={`mt-1 bg-gray-50 block w-full rounded-md sm:text-base focus:ring-0 mb-1`}
                        classNamePrefix="select"
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            borderColor: state.isFocused
                              ? "rgba(76, 206, 172, 0.4)"
                              : "rgba(76, 206, 172, 0.7)",
                            boxShadow: state.isFocused
                              ? "0 0 0 1px rgba(76, 206, 172, 0)"
                              : "",
                            "&:hover": {
                              borderColor: "rgba(76, 206, 172, 0.4)",
                            },
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#23A74C"
                              : state.isFocused
                              ? "#EAF2F1"
                              : provided.backgroundColor,
                            color: state.isSelected
                              ? "#FFFFFF"
                              : provided.color,
                          }),
                        }}
                      />
                    </div>
                  </label>

                  {/* Insurance cost */}
                  <label className="block mb-5">
                    <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base text-primary-color font-semibold">
                      Cost (UGX)
                    </span>
                    <div className="">
                      <input
                        type="number"
                        name="cost"
                        placeholder="Enter insurance cost"
                        onChange={handleChange("cost")}
                        onBlur={handleBlur("cost")}
                        value={values.cost}
                        className={`mt-1 px-3 py-2 bg-transparent border border-custom-greener ${
                          errors && errors?.cost && touched && touched?.cost
                            ? "border-red-400"
                            : "border-custom-green"
                        }  placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-base mb-1`}
                      />
                      <ErrorMessage name="cost">
                        {(msg) => (
                          <div className="text-xs text-red-500 text-left w-full">
                            <p>{msg}</p>
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                  </label>

                  {/* Issuance date */}
                  <label className="block mb-3">
                    <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base font-medium text-slate-700">
                      Issuance date
                    </span>
                    <div className="">
                      <input
                        type="date"
                        name="issuanceDate"
                        onChange={handleChange("issuanceDate")}
                        onBlur={handleBlur("issuanceDate")}
                        value={values.issuanceDate}
                        className="mt-1 px-3 py-2 bg-transparent border border-custom-greener placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-base mb-1"
                      />
                      <ErrorMessage name="issuanceDate">
                        {(msg) => (
                          <div className="text-xs text-red-500 text-left w-full">
                            <p>{msg}</p>
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                  </label>

                  {/* Expiry date */}
                  <label className="block mb-3">
                    <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base font-medium text-slate-700">
                      Expiry date
                    </span>
                    <div className="">
                      <input
                        type="date"
                        name="expiryDate"
                        onChange={handleChange("expiryDate")}
                        onBlur={handleBlur("expiryDate")}
                        value={values.expiryDate}
                        className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-custom-greener placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:custom-greener block w-full rounded-md sm:text-base focus:ring-1 mb-1"
                      />
                      <ErrorMessage name="expiryDate">
                        {(msg) => (
                          <div className="text-xs text-red-500 text-left w-full">
                            <p>{msg}</p>
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                  </label>

                  <div className="flex gap-3 md:gap-2 justify-end">
                    {" "}
                    {/* Added justify-end here */}
                    <button
                      type="button"
                      className="px-4 py-1 my-2 font-medium bg-[#FDE8EB] border rounded-md text-[#B21028] flex items-center text-base"
                      onClick={closeInsurance}
                    >
                      <span className="mr-2">
                        <FaTimes size={12} />
                      </span>
                      <span className="text-base-extra">Cancel</span>
                    </button>
                    <button
                      type="button"
                      onClick={() => addInsuranceDetails(values)}
                      className="px-4 py-1 my-2 font-medium bg-[#23A74C] text-sm text-white border rounded-md flex items-center text-base"
                    >
                      <span className="mr-2">
                        <FaSave size={12} />
                      </span>
                      <span className="text-base-extra">Submit</span>
                    </button>
                  </div>
                </Modal>
              </Form>
            </>
          );
        }}
      </Formik>
      {/* <DeleteConfirmModal /> */}
      <Modal
        opened={opened}
        onClose={close}
        title="Engine-eering a Goodbye?"
        centered
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <div className="border-t border-[#EEE] pt-2">
          <p className="text-base-extra fpmt-medium text-primary-color leading-snug">
            Are you certain about letting this vehicle go? If you proceed, this
            vehicle will be removed from the system.
          </p>
        </div>
        <div className="flex gap-3 mt-7 md:gap-5">
          <button
            type="button"
            className="px-4 py-1 my-2 font-medium bg-[#FDE8EB] border rounded-md text-[#B21028] flex items-center text-base"
            onClick={close}
          >
            <span className="mr-2">
              <FaTimes size={12} />
            </span>
            <span className="text-base-extra">Cancel</span>
          </button>

          <button
            type="button"
            onClick={handleDeleteConfirm}
            className="px-4 py-1 my-2 font-medium bg-[#23A74C] text-sm text-white border rounded-md flex items-center text-base"
          >
            <span className="mr-2">
              <FaTrash size={12} />
            </span>
            <span className="text-base-extra">Delete vehicle</span>
            {/* <span className="text-base-extra">{loading ? "Updating vehicle..." : "Delete vehicle"}</span> */}
          </button>
        </div>
      </Modal>
    </Layout>
  );
}
