import { Formik, Form, ErrorMessage } from "formik";
// import { MdClose } from "react-icons/md";
import axios from "axios";
import { C } from '../common';
import { useEffect, useState } from "react";

import { Pagination } from '@mantine/core';
import { TableSort } from "./TableSort";
import { MoonLoader } from 'react-spinners';

import {
    FaUniversity,
    FaLongArrowAltLeft,
    FaSave,
} from 'react-icons/fa';

import {
    FaBus,
    FaUserTie,
} from 'react-icons/fa';

export function ViewUnit({ unit }) {
    const [loading, setLoading] = useState(false);
    const [activePage, setPage] = useState(1);
    const [activeTab, setActiveTab] = useState('vehicles');

    const [vehicleList, setVehicleList] = useState({});
    const [driverList, setDriverList] = useState({});

    useEffect(() => {
        getVehicleList();
        getDriverList();
    }, [activePage]);

    const getVehicleList = async () => {
        setLoading(true);
        try {
            await axios
                .get(`${C.URLS.BASE_URL}/api/Vehicles?UnitId=${unit.id}`)
                .then((res) => setVehicleList(res.data.data));
        } catch (error) {
            console.error("Network Error:", error.message);
        }
        setLoading(false);
    };

    const getDriverList = async () => {
        setLoading(true);
        try {
            await axios
                .get(`${C.URLS.BASE_URL}/api/Drivers?UnitId=${unit.id}`)
                .then((res) => setDriverList(res.data));
        } catch (error) {
            console.error("Network Error:", error.message);
        }
        setLoading(false);
    };

    // console.log(unit);

    const {
        currentPage: vehiclesCurrentPage,
        pageSize: vehiclesPageSize,
        totalCount: vehiclesTotalCount,
        totalPages: vehiclesPages,
        collection: vehicles,
        previousPage: vehiclesPreviousPage,
        nextPage: vehiclesNextPage,
    } = vehicleList || {};

    const tableVehiclesFields = ['Reg No.', 'Type', 'Make/Model', 'Engine no.'];
    const vehiclesRowData = vehicleList.collection?.map((vehicle) => ({
        id: vehicle.id,
        'Reg No.': `${vehicle.regNo}`,
        'Type': `${vehicle.vehicleType.name}`,
        'Make/Model': `${vehicle.vehicleMake.name}`,
        'Engine no.': `${vehicle.engineNo}`,
    })) || [];

    const {
        currentPage: driversCurrentPage,
        pageSize: driversPageSize,
        totalCount: driversTotalCount,
        totalPages: driversPages,
        collection: drivers,
        previousPage: driversPreviousPage,
        nextPage: driversNextPage,
    } = driverList || {};

    const tableDriversFields = ['Name', 'Contact', 'Permit Classes', 'Verified'];
    const driversRowData = driverList?.collection?.map((driver) => ({
        id: driver.id,
        'Name': `${driver.name}`,
        'Contact': `${driver.contact}`,
        'Permit Classes': `${driver.driverPermits && driver.driverPermits[0] ? driver.driverPermits[0].permitClass.name : ""}`,
        'Verified': `${driver.isVerified}`,
    })) || [];

    // const driversRowData = driverList?.collection
    //     ?.filter((driver) => driver.unitId === unit.id) // filter drivers based on unitId
    //     .map((driver) => ({
    //         id: driver.id,
    //         'Name': `${driver.name}`,
    //         'Contact': `${driver.contact}`,
    //         'Permit Classes': `${driver.driverPermits && driver.driverPermits[0]
    //                 ? driver.driverPermits[0].permitClass.name
    //                 : ""
    //             }`,
    //         'Verified': `${driver.isVerified}`,
    //     })) || [];


    const renderTable = (data, fields, category) => {
        if (loading) {
            return (
                <div style={{ minHeight: '50vh' }} className="flex items-center justify-center">
                    <MoonLoader color="#4CCEAC" size={50} />
                </div>
            );
        }

        if (data) {
            return data.length > 0 ? (
                <TableSort
                    data={data}
                    fields={fields}
                    category={category}
                />
            ) : (
                <TableSort
                    data={[]}
                    fields={fields}
                    category={category}
                />
            );
        }

        return null;
    };

    const calculatePaginationRanges = (currentPage, pageSize, totalCount) => {
        if (totalCount === 0) {
            return { startRange: 0, endRange: 0 };
        }
        const startRange = (currentPage - 1) * pageSize + 1;
        const endRange = Math.min(startRange + pageSize - 1, totalCount);
        return { startRange, endRange };
    };

    const { startRange: vehiclesStartRange, endRange: vehiclesEndRange } = calculatePaginationRanges(activePage, vehiclesPageSize, vehiclesTotalCount);
    const { startRange: driversStartRange, endRange: driversEndRange } = calculatePaginationRanges(activePage, vehiclesPageSize, driversTotalCount);

    return (
        <div className="m-5">
            <div className="flex justify-between">

                <div className="flex">
                    <button
                        onClick={() => setActiveTab('vehicles')}
                        className={`rounded-l-md rounded-r-none flex gap-2 items-center font-medium text-sm py-2 px-4 ${activeTab === 'vehicles' ? 'bg-[#C1BCCD] text-white' : 'bg-gray-100 border'}`}>
                        <FaBus size={14} />
                        Vehicles
                    </button>
                    <button
                        onClick={() => setActiveTab('drivers')}
                        className={`flex gap-2 items-center font-medium text-sm py-2 px-4 ${activeTab === 'drivers' ? 'bg-[#C1BCCD] text-white' : 'bg-gray-100 border'}`}>
                        <FaUserTie size={14} />
                        Drivers
                    </button>
                </div>

            </div>

            {activeTab === 'vehicles' && (
                <>
                    {renderTable(vehiclesRowData, tableVehiclesFields, 'fleetUnitVehicles')}

                    <div className="px-3 py-2 mt-3 flex justify-end items-center font-medium">
                        <div className="text-sm mr-3" style={{ fontSize: '12px' }}>
                            Showing {vehiclesStartRange ? vehiclesStartRange : 0} to {vehiclesEndRange ? vehiclesEndRange : 0} of {vehiclesTotalCount} entries
                        </div>
                        <div className="">
                            <Pagination
                                value={activePage}
                                onChange={setPage}
                                total={vehiclesPages}
                                size="sm"
                                position="right"
                                withEdges
                                styles={(theme) => ({
                                    fontFamily: 'Outfit',
                                    fontSize: '12px',
                                    control: {
                                        '&[data-active]': {
                                            backgroundImage: theme.fn.gradient({ from: '#C1BCCD', to: '#C1BCCD' }),
                                            border: 0,
                                        },
                                    },
                                })}
                            />
                        </div>
                    </div>
                </>
            )}

            {activeTab === 'drivers' && (
                <>
                    {renderTable(driversRowData, tableDriversFields, 'fleetUnitDrivers')}

                    <div className="px-3 py-2 mt-3 flex justify-end items-center font-medium">
                        <div className="text-sm mr-3" style={{ fontSize: '12px' }}>
                            Showing {driversStartRange ? driversStartRange : 0} to {driversEndRange ? driversEndRange : 0} of {driversTotalCount} entries
                        </div>
                        <div className="">
                            <Pagination
                                value={activePage}
                                onChange={setPage}
                                total={driversPages}
                                size="sm"
                                position="right"
                                withEdges
                                styles={(theme) => ({
                                    fontFamily: 'Outfit',
                                    fontSize: '12px',
                                    control: {
                                        '&[data-active]': {
                                            backgroundImage: theme.fn.gradient({ from: '#C1BCCD', to: '#C1BCCD' }),
                                            border: 0,
                                        },
                                    },
                                })}
                            />
                        </div>
                    </div>
                </>
            )}

        </div>
    );
}
