import {
  FaUserTie,
  FaBus,
  FaTachometerAlt,
  FaUsers,
  FaUniversity,
  FaWarehouse,
  FaWrench,
  FaTools,
  FaFileAlt,
  FaDatabase,
} from "react-icons/fa";
// import { TbReportAnalytics } from "react-icons/tb";

export const menuData = {
  admin: [
    {
      label: "Dashboard",
      link: "/dashboard",
      icon: FaTachometerAlt,
      roles: ["super_admin", "unit_admin", "unit_head"],
    },
    {
      label: "Reports",
      link: "/reports",
      icon: FaDatabase,
      initiallyOpened: true,
      links: [
        { label: "Vehicles", link: "/reports/vehicles" },
        { label: "Drivers", link: "/reports/drivers" },
        { label: "Units", link: "/reports/units" },
        { label: "Financial Report", link: "/reports" },
      ],
      roles: ["super_admin"],
    },
    {
      label: "Reports",
      link: "/reports",
      icon: FaDatabase,
      initiallyOpened: true,
      links: [
        { label: "Vehicles", link: "/reports/vehicles" },
        { label: "Drivers", link: "/reports/drivers" },
        { label: "Financial Report", link: "/reports" },
      ],
      roles: ["unit_admin", "unit_head"],
    },
    {
      label: "Units",
      link: "/units",
      icon: FaUniversity,
      roles: ["super_admin"],
    },
    {
      label: "Maintainences",
      link: "/Maintainences",
      icon: FaWrench,
      initiallyOpened: true,
      links: [
        { label: "Repairs", link: "/repairs" },
        { label: "Servicing", link: "/servicing" },
      ],
      roles: ["unit_admin", "unit_head", "driver"],
    },
    {
      label: "Maintainences",
      link: "/Maintainences",
      icon: FaWrench,
      initiallyOpened: true,
      links: [
        { label: "Repairs", link: "/repairs" },
        { label: "Servicing", link: "/servicing" },
        { label: "Garages", link: "/garages" },
      ],
      roles: ["super_admin"],
    },
    {
      label: "Fleet",
      link: "/Maintainences",
      icon: FaBus,
      initiallyOpened: true,
      links: [
        { label: "Vehicles", link: "/vehicles" },
        { label: "Drivers", link: "/drivers" },
      ],
      roles: ["super_admin", "unit_admin", "unit_head"],
    },
    {
      label: "Users",
      link: "/users",
      icon: FaUserTie,
      roles: ["super_admin"],
    },
  ],
};
