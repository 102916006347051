import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ data, options }) => {
  const updatedOptions = {
    ...options,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Month'
        },
        grid: {
          display: false
        },
      },
      y: {
        title: {
          display: true,
          text: 'Vehicles'
        },
        border: { dash: [6, 6], display: true },
          grid: {
            display: true
          },
          ticks: {
            padding: 5
          },
        beginAtZero: true,
      }
    }
  };

  return (
    <div className="h-[40vh] flex justify-center">
      <Line data={data} options={updatedOptions} />
    </div>
  );
};

export default LineChart;
