import { Layout, Paginate } from "../../components";
import { HiOutlineUserGroup } from "react-icons/hi";
import { AiFillPrinter, AiFillFileExcel } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";
import { AddUser } from "../../components";
import FleetDrawer from "../../components/FleetDrawer";
import { useEffect, useState } from "react";
import axios from "axios";
import { C } from "../../common";
import { Helmet } from "react-helmet";
// import { Formik, Form, ErrorMessage } from "formik";
import { useOnClickOutside } from "../../hooks";
import { useRef } from "react";
import { generateCSV, generateCSV2 } from "../../helpers/generateCSV";
import { generatePDF } from "../../helpers/generatePDF";

import { useDisclosure } from "@mantine/hooks";
import { Pagination } from "@mantine/core";

import { TableSort } from "../../components/TableSort";

import { IconCirclePlus } from "@tabler/icons-react";

import { MoonLoader } from "react-spinners";

const Users = () => {
  const [loading, setLoading] = useState(false);

  const PAGE_SIZE = 20;

  const [openedAddDrawer, { open: openAddDrawer, close: closeAddDrawer }] =
    useDisclosure(false);

  const [roles, setRoles] = useState([]);
  const ref = useRef();

  const [usersData, setUsersData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortAsc, setSortAsc] = useState(false);

  const [activePage, setPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState({});

  useEffect(() => {
    getUsers();

    const fetchRoles = async () => {
      try {
        await axios
          .get(`${C.URLS.BASE_URL}/api/Roles`)
          .then((res) => setRoles(res.data.data));
        // .catch((error) => console.log(error));
      } catch (error) {
        console.error("Network Error:", error.message);
      }
    };
    fetchRoles();
  }, [activePage]);

  const getUsers = async () => {
    setLoading(true);
    try {
      await axios
        .get(`${C.URLS.BASE_URL}/api/Users?PageNumber=${activePage}&PageSize=${PAGE_SIZE}`)
        .then((res) => setUsersData(res.data.data));
    } catch (error) {
      console.error("Network Error:", error.message);
    }
    setLoading(false);
  };

  const formatAndAdjustTimezone = dateString => {
    const utcDate = new Date(dateString);
    utcDate.setHours(utcDate.getHours() + 3);

    const formattedDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "UTC", 
    }).format(utcDate);

    return formattedDate;
  }

  const {
    currentPage,
    pageSize,
    totalCount,
    totalPages,
    collection: users,
    previousPage,
    nextPage,
  } = usersData;

  const tableFields = [
    "Name",
    "Creation Date",
    "Email",
    "Phone",
    "Role",
    "Status",
    "Action",
  ];

  const userStatusMapping = {
    Is_Active: "Active",
    Is_Inactive: "Inactive",
    Is_Delete: "Deleted",
    Is_Blocked: "Blocked",
  };

  const rowData =
    usersData.collection?.map((user) => ({
      id: user.id,
      Name: `${user.firstName} ${user.lastName}`,
      "Creation Date": formatAndAdjustTimezone(user.createdOn),
      Email: user.emailAddress,
      Phone: user.phoneNumber,
      Role: user.role.name,
      Status: userStatusMapping[user.status] || user.status,
    })) || [];

  const calculatePaginationRanges = (currentPage, pageSize, totalCount) => {
    if (totalCount === 0) {
      return { startRange: 0, endRange: 0 };
    }
    const startRange = (currentPage - 1) * pageSize + 1;
    const endRange = Math.min(startRange + pageSize - 1, totalCount);
    return { startRange, endRange };
  };

  let filteredUsers = users;
  filteredUsers =
    filteredUsers && filteredUsers.length > 0 && searchText
      ? filteredUsers.filter(
          (user) =>
            user.firstName &&
            user.lastName &&
            user.role.name &&
            (user.firstName.toLowerCase().indexOf(searchText.toLowerCase()) >
              -1 ||
              user.lastName.toLowerCase().indexOf(searchText.toLowerCase()) >
                -1 ||
              user.role.name.toLowerCase().indexOf(searchText.toLowerCase()) >
                -1)
        )
      : filteredUsers;

  const sortByFirstName = (ascOrDes) => {
    const sortedData = [...filteredUsers].sort((a, b) =>
      ascOrDes
        ? a.firstName.localeCompare(b.firstName)
        : b.firstName.localeCompare(a.firstName)
    );
    filteredUsers = sortedData;
  };

  const { startRange, endRange } = calculatePaginationRanges(
    activePage,
    pageSize,
    totalCount
  );

  return (
    <Layout>
      <Helmet>
        <title>Users - Mak Fleet</title>
      </Helmet>
      <div className="flex justify-between">
        <h2 className="flex gap-2 items-center font-medium text-md">
          <HiOutlineUserGroup size={14} />
          {users ? totalCount : 0} Users available
        </h2>
        <div className="flex font-medium text-sm text-[#434C50]">
          {/* <button
            className="px-7 py-2 bg-[#ffffff] text-[#002200] border border-[#e4e4e7] mr-2 rounded-md font-medium flex items-center text-base"
            onClick={() => generatePDF(users)}
          >
            <span className="mr-2"><AiFillPrinter size={18} /></span>
            <span className="text-sm">Print</span>
          </button> */}

          {/* <button
            className="px-7 py-2 bg-[#ffffff] text-[#002200]  border border-[#e4e4e7] mr-5 rounded-md font-medium flex items-center text-base"
            onClick={() =>
              generateCSV2(users, "users_table", [
                "firstName",
                "lastName",
                "status",
              ])
            }
          >
            <span className="mr-2"><AiFillFileExcel size={22} /></span>
            <span>Excel</span>
          </button> */}

          <button
            className="px-5 py-2 bg-[#23A74C] rounded-md font-medium text-white flex items-center text-base"
            onClick={openAddDrawer}
          >
            <span className="mr-2">
              <IconCirclePlus size={18} />
            </span>
            <span className="text-base-extra">Add new user</span>
          </button>
        </div>
      </div>

      {!loading ? (
        <>
          {users && (
            <TableSort
              data={rowData}
              fields={tableFields}
              category={"fleetUsers"}
            />
          )}

          {/* <div className="bg-white px-3 py-2 mt-3 flex justify-between items-center ">
            <div className="text-sm text-gray-500">
              Showing {startRange ? startRange : 0} to {endRange ? endRange : 0} of {totalCount} entries
            </div>
            <div className="mt-2">
              <Pagination
                value={activePage}
                onChange={setPage}
                total={totalPages}
                position="right"
                withEdges
                styles={(theme) => ({
                  control: {
                    '&[data-active]': {
                      backgroundImage: theme.fn.gradient({ from: '#C1BCCD', to: '#C1BCCD' }),
                      border: 0,
                    },
                  },
                })}
              />
            </div>
          </div> */}

          <div className="px-3 py-2 mt-3 flex justify-end items-center font-medium">
            <div className="text-sm mr-3" style={{ fontSize: "12px" }}>
              Showing {startRange ? startRange : 0} to {endRange ? endRange : 0}{" "}
              of {totalCount} entries
            </div>
            <div className="">
              <Pagination
                value={activePage}
                onChange={setPage}
                total={totalPages}
                size="sm"
                position="right"
                withEdges
                styles={(theme) => ({
                  fontFamily: "Outfit",
                  fontSize: "12px",
                  control: {
                    "&[data-active]": {
                      backgroundImage: theme.fn.gradient({
                        from: "#C1BCCD",
                        to: "#C1BCCD",
                      }),
                      border: 0,
                    },
                  },
                })}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="flex justify-center px-5 py-5">
          <div
            style={{ minHeight: "50vh" }}
            className="flex items-center justify-center"
          >
            <div className="flex justify-center px-5 py-5">
              <MoonLoader color="#4CCEAC" size={50} />
            </div>
          </div>
        </div>
      )}

      <FleetDrawer
        opened={openedAddDrawer}
        onClose={closeAddDrawer}
        title="Add new user"
      >
        <AddUser onCancel={closeAddDrawer} />
      </FleetDrawer>
    </Layout>
  );
};

export default Users;
