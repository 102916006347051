import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import { C } from "../../common";
import toast from "react-hot-toast";
import { useAuth } from "../../helpers/auth/AuthContext";
import { useEffect, useState } from "react";
import { Pagination } from "@mantine/core";
import Select from "react-select";
import { Layout } from "../../components/Layout";

import { TableSort } from "../../components/TableSort";
import { MoonLoader } from "react-spinners";
import { FaDatabase, FaMinusCircle } from "react-icons/fa";

const VehicleReports = () => {
  const [loading, setLoading] = useState(false);
  const [fReports, setFReports] = useState([]);
  const [selectedReportArea, setSelectedReportArea] = useState(2);
  const [units, setUnits] = useState([]);
  const [vehiclesData, setVehiclesData] = useState([]);
  const [unitId, setUnitId] = useState(null);

  const [activePage, setPage] = useState(1);
  const { user } = useAuth();
  const [userRole, setUserRole] = useState('');

  const [reportTypes, setReportTypes] = useState([
    { id: 1, name: "Units" },
    { id: 2, name: "Vehicles" },
    { id: 3, name: "Drivers" },
    { id: 4, name: "Garages" },
    { id: 5, name: "Financial" },
    { id: 6, name: "Maintenances" },
  ]);

  useEffect(() => {
    const getUserRole = async (userID) => {
      try {
        await axios
          .get(`${C.URLS.BASE_URL}/api/Users/${userID}`)
          .then((res) => setUserRole(res.data?.role.name));
      } catch (error) {
        console.error("Network Error:", error.message);
      }
    };

    const fetchUnits = async () => {
      try {
        const allUnits = [];
        let currentPage = 1;

        while (true) {
          const response = await axios.get(
            `${C.URLS.BASE_URL}/api/Units?PageNumber=${currentPage}`
          );
          const unitsData = response.data.data.collection;

          if (unitsData.length === 0) {
            break;
          }

          allUnits.push(...unitsData);
          currentPage++;
        }

        setUnits(allUnits);
      } catch (error) {
        console.error(error.message);
      }
    };

    const fetchUnitId = async () => {
      if (userRole === "unit_admin" || userRole === "unit_head") {
        const unitsResponse = await axios.get(
          `${C.URLS.BASE_URL}/api/Units?UserId=${user.id}`
        );
        const userUnits = unitsResponse.data.data.collection[0].userUnits;

        if (!userUnits.length) {
          throw new Error("No userUnits found for the user");
        }

        const currentUnitId = userUnits[0].unitId;
        setUnitId(currentUnitId);
      }
    };

    getUserRole(user?.id);
    fetchUnits();
    fetchUnitId();
  }, []);

  const STATUSES = [
    "PARKED",
    "RUNNING",
    "DISPOSED",
    "GROUNDED",
    "OBSOLETE",
    "SOLD",
  ];

  const today = new Date();
  const currentYear = today.getFullYear();
  const firstOfJanuary = new Date(currentYear, 0, 1);

  const toDateString = today.toISOString().split("T")[0];
  const fromDateString = firstOfJanuary.toISOString().split("T")[0];

  const {
    currentPage,
    pageSize,
    totalCount,
    totalPages,
    collection: vehicles,
    previousPage,
    nextPage,
  } = vehiclesData;

  const tableFields = [
    "Reg No.",
    "Make/Model",
    "Type",
    "Y.O.M",
    "Chasis No.",
    "Status",
    "Unit",
  ];

  const rowData =
    vehiclesData.collection?.map((vehicle) => ({
      id: vehicle.id,
      "Reg No.": `${vehicle.regNo}`,
      "Make/Model": `${vehicle.vehicleMake.name}`,
      Type: `${vehicle.vehicleType.name}`,
      "Y.O.M": `${vehicle.yearOfManufacture}`,
      "Chasis No.": `${vehicle.chasisNumber}`,
      Status: `${vehicle.status}`,
      Unit: `${vehicle.unit.name}`,
    })) || [];

  const calculatePaginationRanges = (currentPage, pageSize, totalCount) => {
    if (totalCount === 0) {
      return { startRange: 0, endRange: 0 };
    }
    const startRange = (currentPage - 1) * pageSize + 1;
    const endRange = Math.min(startRange + pageSize - 1, totalCount);
    return { startRange, endRange };
  };

  const { startRange, endRange } = calculatePaginationRanges(
    activePage,
    pageSize,
    totalCount
  );

  return (
    <Layout>
      <Formik
        initialValues={{
          reportTypeId: 2,
          vehicleStatus: "",
          acquisitionyear: "",
          manufactureYear: "",
          vehicleUnitId: unitId,
          To: toDateString,
          From: fromDateString,
        }}
        // validationSchema={getFinancialReports}
        enableReinitialize
        onSubmit={async (values, { resetForm }) => {
          setLoading(true);
          console.log(values);
          try {
            const response = await axios.get(
              `${C.URLS.BASE_URL}/api/Vehicles`,
              {
                params: {
                  Status: values.vehicleStatus,
                  UnitId: values.vehicleUnitId,
                },
              }
            );
            const responseData = response.data.data;
            setVehiclesData(responseData);

            if (responseData.code === "BadRequest") {
              const errorMessage =
                responseData.message ||
                "Oops! It looks like some information is missing or incorrect";
              toast.error(errorMessage, { position: "bottom-center" });
            } else if (response.status === 200 || response.status === 201) {
              const successMessage =
                responseData.message || "Successfully loaded";
              toast.success(successMessage, {
                position: "bottom-center",
              });
              setLoading(false);
            } else {
              toast.error("An error occurred while processing your request.", {
                position: "bottom-center",
              });
            }

            setLoading(false);
          } catch (error) {}
        }}
      >
        {({
          values,
          setFieldValue,
          handleChange,
          handleBlur,
          errors,
          touched,
          resetForm,
        }) => {
          return (
            <>
              <Form className="h-full overflow-y-scroll">
                <main>
                  <div className="flex justify-between items-center gap-3 md:gap-5 px-2">
                    {/* Report Area */}
                    <div className="w-1/2 flex flex-wrap -mx-2">
                      <div className="pr-1 flex flex-col">
                        <label className="block mb-5">
                          <span className="block text-base font-semibold text-primary-color">
                            Report area
                          </span>
                          <div className="">
                            <Select
                              isSearchable
                              name="reportTypeId"
                              value={
                                reportTypes
                                  .filter(
                                    (type) => type.id === values.reportTypeId
                                  )
                                  .map((type) => ({
                                    value: type.id,
                                    label: type.name,
                                  }))[0]
                              }
                              isDisabled={true}
                              onChange={(option) => {
                                const newValue = option ? option.value : "";
                                setFieldValue("reportTypeId", newValue);
                                setSelectedReportArea(newValue);
                              }}
                              options={reportTypes.map((type) => ({
                                value: type.id,
                                label: type.name,
                              }))}
                              className={`mt-1 bg-gray-50 block w-full rounded-md sm:text-base focus:ring-0 mb-1`}
                              classNamePrefix="select"
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  borderColor: state.isFocused
                                    ? "rgba(76, 206, 172, 0.4)"
                                    : "rgba(76, 206, 172, 0.7)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px rgba(76, 206, 172, 0)"
                                    : "",
                                  "&:hover": {
                                    borderColor: "rgba(76, 206, 172, 0.4)",
                                  },
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: state.isSelected
                                    ? "#23A74C"
                                    : state.isFocused
                                    ? "#EAF2F1"
                                    : provided.backgroundColor,
                                  color: state.isSelected
                                    ? "#FFFFFF"
                                    : provided.color,
                                }),
                              }}
                            />
                            <ErrorMessage name="reportTypeId">
                              {(msg) => (
                                <div className="text-xs text-red-500 text-left w-full">
                                  <p>{msg}</p>
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                        </label>
                      </div>

                      <div className="pr-1 flex flex-col">
                        <label className="block mb-5">
                          <span className="block text-base font-semibold text-primary-color">
                            Vehicle status
                          </span>
                          <div className="">
                            <Select
                              isSearchable
                              name="vehicleStatus"
                              value={
                                values.vehicleStatus
                                  ? {
                                      value: values.vehicleStatus,
                                      label: values.vehicleStatus,
                                    }
                                  : null
                              }
                              onChange={(option) =>
                                setFieldValue(
                                  "vehicleStatus",
                                  option ? option.value : ""
                                )
                              }
                              onBlur={handleBlur("vehicleStatus")}
                              options={STATUSES.map((vStatus) => ({
                                value: vStatus,
                                label: vStatus,
                              }))}
                              className={`mt-1 bg-gray-50 block w-full rounded-md sm:text-base focus:ring-0 mb-1`}
                              classNamePrefix="select"
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  borderColor: state.isFocused
                                    ? "rgba(76, 206, 172, 0.4)"
                                    : "rgba(76, 206, 172, 0.7)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px rgba(76, 206, 172, 0)"
                                    : "",
                                  "&:hover": {
                                    borderColor: "rgba(76, 206, 172, 0.4)",
                                  },
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: state.isSelected
                                    ? "#23A74C"
                                    : state.isFocused
                                    ? "#EAF2F1"
                                    : provided.backgroundColor,
                                  color: state.isSelected
                                    ? "#FFFFFF"
                                    : provided.color,
                                }),
                              }}
                            />
                          </div>
                        </label>
                      </div>

                      {userRole === "super_admin" && (
                        <div className="pr-1 flex flex-col">
                          <label className="block mb-5">
                            <span className="block text-base font-semibold text-primary-color">
                              Unit or college
                            </span>
                            <div className="">
                              <Select
                                isSearchable
                                name="vehicleUnitId"
                                value={
                                  units.filter(
                                    (unit) => unit.id === values.vehicleUnitId
                                  ).length > 0
                                    ? units
                                        .filter(
                                          (unit) =>
                                            unit.id === values.vehicleUnitId
                                        )
                                        .map((unit) => ({
                                          value: unit.id,
                                          label: unit.name,
                                        }))[0]
                                    : null
                                }
                                onChange={(option) =>
                                  setFieldValue(
                                    "vehicleUnitId",
                                    option ? option.value : ""
                                  )
                                }
                                onBlur={handleBlur("vehicleUnitId")}
                                options={[
                                  { value: "", label: "Select Unit/College" },
                                  ...units.map((unit) => ({
                                    value: unit.id,
                                    label: unit.name,
                                  })),
                                ]}
                                className={`mt-1 bg-gray-50 block w-full rounded-md sm:text-base focus:ring-0 mb-1`}
                                classNamePrefix="select"
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    borderColor: state.isFocused
                                      ? "rgba(76, 206, 172, 0.4)"
                                      : "rgba(76, 206, 172, 0.7)",
                                    boxShadow: state.isFocused
                                      ? "0 0 0 1px rgba(76, 206, 172, 0)"
                                      : "",
                                    "&:hover": {
                                      borderColor: "rgba(76, 206, 172, 0.4)",
                                    },
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isSelected
                                      ? "#23A74C"
                                      : state.isFocused
                                      ? "#EAF2F1"
                                      : provided.backgroundColor,
                                    color: state.isSelected
                                      ? "#FFFFFF"
                                      : provided.color,
                                  }),
                                }}
                              />
                            </div>
                          </label>
                        </div>
                      )}
                    </div>

                    <div className="w-1/2 flex flex-wrap justify-end -mx-2">
                      <div className="flex justify-end">
                        <button
                          type="button"
                          className="px-4 py-2 my-2 font-medium bg-[#E1F5E2] mr-2 text-base text-[#006400] border rounded-md flex items-center justify-center"
                          onClick={() => resetForm()}
                        >
                          <span className="mr-2">
                            <FaMinusCircle size={14} />
                          </span>
                          <span>Clear fields</span>
                        </button>
                      </div>
                      <div className="flex justify-end">
                        <button
                          type="submit"
                          className="px-4 py-2 my-2 font-medium bg-[#23A74C] text-base text-white border rounded-md flex items-center justify-center"
                        >
                          <span className="mr-2">
                            <FaDatabase size={14} />
                          </span>
                          <span>{loading ? "Loading..." : "Load data"}</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="mb-2">
                    <ErrorMessage name="unitLocation">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>

                    {/* <ErrorMessage name="From">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                    <ErrorMessage name="To">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage> */}
                  </div>

                  {/* Section 1 */}
                  <div className="bg-white border border-gray-200 rounded-md px-3 pt-2 pb-5">
                    {!loading ? (
                      <>
                        {fReports && (
                          <TableSort
                            data={rowData}
                            fields={tableFields}
                            category={"fleetUnits"}
                          />
                        )}
                      </>
                    ) : (
                      <div className="w-full flex justify-center">
                        <div
                          style={{ minHeight: "20vh" }}
                          className="flex items-center justify-center"
                        >
                          <div className="flex justify-center px-5 py-5">
                            <MoonLoader color="#4CCEAC" size={50} />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <>
                    <div className="px-3 py-2 mt-3 flex justify-end items-center font-medium">
                      <div
                        className="text-sm mr-3"
                        style={{ fontSize: "12px" }}
                      >
                        Showing {startRange ? startRange : 0} to{" "}
                        {endRange ? endRange : 0} of {totalCount} entries
                      </div>
                      <div className="">
                        <Pagination
                          value={activePage}
                          onChange={setPage}
                          total={totalPages}
                          size="sm"
                          position="right"
                          withEdges
                          styles={(theme) => ({
                            fontFamily: "Outfit",
                            fontSize: "12px",
                            control: {
                              "&[data-active]": {
                                backgroundImage: theme.fn.gradient({
                                  from: "#C1BCCD",
                                  to: "#C1BCCD",
                                }),
                                border: 0,
                              },
                            },
                          })}
                        />
                      </div>
                    </div>
                  </>
                </main>
              </Form>
            </>
          );
        }}
      </Formik>
    </Layout>
  );
};

export default VehicleReports;
