import { Formik, Form, ErrorMessage } from "formik";
import { MdClose } from "react-icons/md";
import axios from "axios";
import { C } from '../../common';
import toast from "react-hot-toast";
// import { addUserValidationSchema } from "../../helpers/validators";
import { addUnitValidationSchema } from "../../helpers/validators";
import { useEffect, useState } from "react";

export function AddUnit({ onCancel }) {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        axios
          .get(`${C.URLS.BASE_URL}/api/Roles`)
          .then((res) => setRoles(res.data.data))
          .catch((error) => console.log(error));
      } catch (error) { }
    };
    fetchRoles();
  }, []);

  return (
    <Formik
      initialValues={{
        name: "",
        location: "",
        metaData: "",
      }}
      validationSchema={addUnitValidationSchema}
      enableReinitialize
      onSubmit={async (values, { resetForm }) => {
        setLoading(true);
        try {
          await axios
            .post(`${C.URLS.BASE_URL}/api/Units`, {
              // .post("${C.URLS.BASE_URL}/api/Users", {
              ...values,
            })
            .then(() =>
              toast.success("Successfully added unit.", {
                position: "bottom-center",
              })
            )
            .then(() => {
              setLoading(false);
              resetForm();
            })
            .catch((error) => {
              toast.error(`${error.message}`, {
                position: "bottom-center",
              });
              setLoading(false);
            });
        } catch (error) { }
      }}
    >
      {({ values, handleChange, handleBlur, errors, touched }) => {
        return (
          <>
            <Form className="h-full overflow-y-scroll pt-20 px-4 md:px-10 bg-white">
              <main>
                <label className="block mb-5">
                  <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                    Unit Name
                  </span>
                  <div className="">
                    <input
                      type="text"
                      name=""
                      placeholder="Unit name"
                      onChange={handleChange("name")}
                      onBlur={handleBlur("name")}
                      value={values.name}
                      className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors &&
                        errors?.name &&
                        touched &&
                        touched?.name
                        ? "border-red-400"
                        : "border-slate-400"
                        }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-sm focus:ring-1 mb-1`}
                    />
                    <ErrorMessage name="name">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </label>

                <label className="block mb-5">
                  <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                    Location
                  </span>
                  <div className="">
                    <input
                      type="text"
                      name=""
                      placeholder="Location"
                      onChange={handleChange("location")}
                      onBlur={handleBlur("location")}
                      value={values.lastName}
                      className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors &&
                        errors?.lastName &&
                        touched &&
                        touched?.lastName
                        ? "border-red-400"
                        : "border-slate-400"
                        }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-sm focus:ring-1 mb-1`}
                    />
                    <ErrorMessage name="location">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </label>

                {/* <label className="block mb-5">
                  <span className="block text-sm font-medium text-slate-700">
                    Description
                  </span>
                  <div className="">s
                    <input
                      type="text"
                      name="metaData"
                      placeholder="Description"
                      onChange={handleChange("metaData")}
                      onBlur={handleBlur("metaData")}
                      value={values.middleName}
                      className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-400 placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-sm focus:ring-1 mb-1"
                    />
                  </div>
                </label> */}

                <div className="flex justify-end gap-3 md:gap-5 mt-4">
                  <button
                    type="button"
                    className="text-sm border-neutral-600 border-[1px] cursor-pointer text-neutral-600 py-1 px-4 my-2 rounded-md"
                    onClick={onCancel}
                  >
                    Cancel
                  </button>
                  <input
                    type="submit"
                    value={loading ? "Adding unit..." : "Add Unit"}
                    className="bg-[#23A74C] text-sm border-[#23A74C] border-2 text-white py-1 px-4 my-2 rounded-md cursor-pointer"
                  />
                </div>
              </main>
            </Form>
          </>
        );
      }}
    </Formik>
  );
}
