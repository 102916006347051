import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  ArcElement,
);

const DoughnutChart = ({ data, options }) => {
  return (
    <div className="h-[40vh] flex justify-center items-center">
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default DoughnutChart;
