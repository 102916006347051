import React from 'react';
import { Drawer } from '@mantine/core';

const FleetDrawer = ({ opened, onClose, children, title, size = 'lg' }) => {
    return (
        <Drawer
            position="right"
            size={size}
            opened={opened}
            onClose={onClose}
            title={title}
            overlayProps={{ opacity: 0.7, blur: 1 }}
            styles={{
                title: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: '#434C50',
                },
                header: {
                    // borderBottom: '1px solid #ddd',
                    padding: '1.2rem 3rem',
                    backgroundColor: '#EAF2F1',
                },
            }}
        >
            {children}
        </Drawer>
    );
}

export default FleetDrawer;
