import { Formik, Form, ErrorMessage } from "formik";
import { MdClose } from "react-icons/md";
import axios from "axios";
import { C } from '../common';
import toast from "react-hot-toast";
import { useEffect, useState } from "react";

export function EditGarage({ garage }) {
    const [loading, setLoading] = useState(false);
    // const [currentGarage, setCurrentGarage] = useState({});

    useEffect(() => {
    }, []);

    // const isObjectEmpty = (obj) => {
    //     return Object.entries(obj).length === 0;
    // }

    // console.log(garage);

    return (
        <Formik
            initialValues={{
                id: garage.id,
                "name": garage.Name,
                "contact": garage.Contact,
                "location": garage.Location,
                "email": garage.Email,
                status: garage.Status === "Qualified" ? "QUALIFIED" : "NOT_QUALIFIED",
                "isDeleted": false,
            }}
            enableReinitialize

            onSubmit={async (values, { resetForm }) => {
                console.log(values);
                setLoading(true);
                try {
                    const response = await axios
                        .put(`${C.URLS.BASE_URL}/api/Garages/${values.id}`, {
                            ...values,
                        });
                    const responseData = response.data;

                    if (responseData.code === 'BadRequest') toast.error(responseData.message, { position: "bottom-center" });
                    else if (response.status === 200 || response.status === 201) {
                        toast.success(responseData.message, { position: "bottom-center" });
                        setLoading(false);
                        resetForm();
                    } else {
                        toast.error("An error occurred while processing your request.", { position: "bottom-center" });
                    }

                    setLoading(false);
                } catch (error) { }
                resetForm();
            }}

        >
            {({ values, handleChange, handleBlur, errors, touched }) => {
                return (
                    <>
                        <Form className="h-full overflow-y-scroll pt-10 px-4 md:px-10 bg-white">
                            <main>
                                <label className="block mb-5 w-full">
                                    <span className="block text-sm font-medium text-slate-700">
                                        Name
                                    </span>
                                    <div className="">
                                        <input
                                            type="text"
                                            name="name"
                                            readOnly
                                            placeholder="Unit name"
                                            onChange={handleChange("name")}
                                            onBlur={handleBlur("name")}
                                            value={`${garage.Name}`}
                                            className={`mt-1 px-3 py-2 bg-gray-50 border shadow-sm capitalize border-gray-400 focus:outline-none focus:border-gray-400 focus:ring-gray-400 block w-full rounded-md sm:text-sm focus:ring-0 mb-1 text-gray-500`}
                                        />
                                    </div>
                                </label>

                                <label className="block mb-5 w-full">
                                    <span className="block text-sm font-medium text-slate-700">
                                        Location
                                    </span>
                                    <div className="">
                                        <input
                                            type="text"
                                            name="location"
                                            readOnly
                                            placeholder="Garage Location"
                                            onChange={handleChange("location")}
                                            onBlur={handleBlur("location")}
                                            value={`${garage.Location}`}
                                            className={`mt-1 px-3 py-2 bg-gray-50 border shadow-sm capitalize border-gray-400 focus:outline-none focus:border-gray-400 focus:ring-gray-400 block w-full rounded-md sm:text-sm focus:ring-0 mb-1 text-gray-500`}
                                        />
                                    </div>
                                </label>

                                <label className="block mb-5 w-full">
                                    <span className="block text-sm font-medium text-slate-700">
                                        Contact
                                    </span>
                                    <div className="">
                                        <input
                                            type="text"
                                            name="contact"
                                            readOnly
                                            placeholder="Garage Phone Number"
                                            onChange={handleChange("contact")}
                                            onBlur={handleBlur("contact")}
                                            value={`${garage.Contact}`}
                                            className={`mt-1 px-3 py-2 bg-gray-50 border shadow-sm capitalize border-gray-400 focus:outline-none focus:border-gray-400 focus:ring-gray-400 block w-full rounded-md sm:text-sm focus:ring-0 mb-1 text-gray-500`}
                                        />
                                    </div>
                                </label>

                                <label className="block mb-5 w-full">
                                    <span className="block text-sm font-medium text-slate-700">
                                        Email Address
                                    </span>
                                    <div className="">
                                        <input
                                            type="text"
                                            name="email"
                                            placeholder="Email Address"
                                            onChange={handleChange("email")}
                                            onBlur={handleBlur("email")}
                                            // value={`${garage.Email}`}
                                            value={values.email}
                                            className={`mt-1 px-3 py-2 bg-gray-50 border shadow-sm border-gray-400 focus:outline-none focus:border-gray-400 focus:ring-gray-400 block w-full rounded-md sm:text-sm focus:ring-0 mb-1 text-gray-500`}
                                        />
                                    </div>
                                </label>

                                {/* <input
                                    type="text"
                                    name="email"
                                    placeholder="Email Address"
                                    onChange={handleChange("email")}    // Use "email" instead of "name"
                                    onBlur={handleBlur("email")}        // Use "email" instead of "name"
                                    value={values.email}                // Use Formik value for email
                                    className={`mt-1 px-3 py-2 bg-gray-50 border shadow-sm capitalize border-gray-400 focus:outline-none focus:border-gray-400 focus:ring-gray-400 block w-full rounded-md sm:text-sm focus:ring-0 mb-1 text-gray-500`}
                                /> */}


                                {/* Garage Qualification Status */}
                                <label className="block mb-5">
                                    <span className="block text-sm font-medium text-slate-700">
                                        Garage Qualification Status
                                    </span>
                                    <div className="">
                                        <select
                                            name="status"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.status}
                                            className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors && errors?.status && touched && touched?.status
                                                ? "border-red-400"
                                                : "border-slate-400"
                                                }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-sm focus:ring-1 mb-1`}
                                        >
                                            <option key="QUALIFIED" value="QUALIFIED">Qualified</option>
                                            <option key="NOT_QUALIFIED" value="NOT_QUALIFIED">Unqualified</option>
                                        </select>
                                    </div>
                                </label>

                                <label className="block mb-5 w-full flex items-center">
                                    <div className="flex items-center">
                                        <input
                                            type="checkbox"
                                            name="isDeleted"
                                            onChange={handleChange("isDeleted")}
                                            onBlur={handleBlur("isDeleted")}
                                            checked={values.isDeleted}
                                            className={`w-5 h-5 px-3`}
                                        />
                                        <span className="ml-3 font-bold text-[#434C50]">Delete Garage?</span>
                                    </div>
                                </label>


                                <div className="flex justify-end gap-3 md:gap-5 mt-4">
                                    <input
                                        type="submit"
                                        value={loading ? "Updating..." : "Update Garage"}
                                        // {currentUser?.status === "Active" ? "Deactivate" : "Activate"}
                                        className="bg-[#23A74C] text-sm border-[#23A74C] border-2 text-white py-1 px-4 my-2 rounded-md cursor-pointer"
                                    />
                                </div>
                            </main>
                        </Form>
                    </>
                );
            }}
        </Formik>
    );
}
