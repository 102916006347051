import { Formik, Form, ErrorMessage } from "formik";
import React, { useState, useEffect } from "react";
// import { Formik, Form } from "formik";
import axios from "axios";
import { C } from '../common';
import toast from "react-hot-toast";
import userPlaceholder from "../assets/img/user_placeholder.svg";

export function EditUser({ user }) {
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);

  const [units, setUnits] = useState([]);

  useEffect(() => {
    getUser();

    const fetchRoles = async () => {
      try {
        axios
          .get(`${C.URLS.BASE_URL}/api/Roles`)
          .then((res) => setRoles(res.data.data.collection))
          .catch((error) => console.log(error));
      } catch (error) {
        console.error(error.message)
      }

    };

    const fetchUnits = async () => {
      try {
        const allUnits = [];
        let currentPage = 1;
  
        while (true) {
          // const response = await axios.get(`${C.URLS.BASE_URL}/api/VehicleTypeMakes?PageNumber=${currentPage}`);
          const response = await axios.get(
            `${C.URLS.BASE_URL}/api/Units?PageNumber=${currentPage}`
          );
          const unitsData = response.data.data.collection;
  
          if (unitsData.length === 0) {
            break;
          }
  
          allUnits.push(...unitsData);
          currentPage++;
        }
  
        setUnits(allUnits);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchRoles();
    fetchUnits();
  }, []);

  const handleImageSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
    }
  };

  const getUser = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${C.URLS.BASE_URL}/api/Users/${user.id}`);
      setCurrentUser(response.data);
    } catch (error) {
      console.error("Network Error:", error.message);
    }
    setLoading(false);
  };


  // console.log("here we go: ", currentUser)

  return (
    <Formik
      initialValues={{
        ...currentUser,
      }}
      enableReinitialize
      onSubmit={async (values, { resetForm }) => {
        // console.log(values);
        const newStatus = values.status === "Is_Active" ? "Is_Inactive" : "Is_Active";

        setLoading(true);

        const formData = new FormData();

        for (const key in values) {
          formData.append(key, values[key]);
        }

        formData.append("status", newStatus);

        // console.log(selectedImage)

        try {
          await axios.put(`${C.URLS.BASE_URL}/api/Users/${user.id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          toast.success("User status updated successfully.", {
            position: "bottom-center",
          });

          setLoading(false);
        //   resetForm();

          const response = await axios.get(`${C.URLS.BASE_URL}/api/Users/${user.id}`);
          setCurrentUser(response.data);
        } catch (error) {
          toast.error(`${error.message}`, {
            position: "bottom-center",
          });
          setLoading(false);
        }
      }}
    >
      {({ values, handleChange, handleBlur, errors, touched, setFieldValue }) => (
        <Form className="h-full overflow-y-scroll pt-10 px-4 md:px-10 bg-white">
          <main>

            {/* First Name */}
            <label className="block mb-5 w-full">
              <span className="block text-sm font-medium text-slate-700">
                First Name
              </span>
              <div className="">
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  className={`mt-1 px-3 py-2 bg-gray-50 border shadow-sm capitalize border-gray-400 focus:outline-none focus:border-gray-400 focus:ring-gray-400 block w-full rounded-md sm:text-sm focus:ring-0 mb-1 text-gray-500`}
                />
              </div>
            </label>

            {/* Last Name */}
            <label className="block mb-5 w-full">
              <span className="block text-sm font-medium text-slate-700">
                Last Name
              </span>
              <div className="">
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  className={`mt-1 px-3 py-2 bg-gray-50 border shadow-sm capitalize border-gray-400 focus:outline-none focus:border-gray-400 focus:ring-gray-400 block w-full rounded-md sm:text-sm focus:ring-0 mb-1 text-gray-500`}
                />
              </div>
            </label>

            {/* Units
            <label className="block mb-5">
                        <span className="block text-base font-semibold text-primary-color">
                          Unit or college
                        </span>
                        <div className="">
                          <select
                            name="unitId"
                            onChange={handleChange("unitId")}
                            onBlur={handleBlur("unitId")}
                            value={values.unitId}
                            className={`mt-1 px-3 py-2 bg-transparent border border-custom-green ${
                              errors &&
                              errors?.unitId &&
                              touched &&
                              touched?.unitId
                                ? "border-red-400"
                                : "border-custom-green"
                            }  placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-green block w-full rounded-md sm:text-base mb-1`}
                          >
                            <option value="">-- Select Unit --</option>
                            {units &&
                              units.length > 0 &&
                              units.map((unit) => (
                                <option key={unit.id} value={unit.id}>
                                  {unit.name}
                                </option>
                              ))}
                          </select>
                          <ErrorMessage name="vehicleMakeId">
                            {(msg) => (
                              <div className="text-xs text-red-500 text-left w-full">
                                <p>{msg}</p>
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </label> */}

            {/* Status*/}
            <label className="block mb-5 w-full">
              <span className="block text-sm font-medium text-slate-700">
                User Status
              </span>
              <div className="">
                <select
                  name="status"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.status}
                  className={`mt-1 px-3 py-2 bg-gray-50 border shadow-sm capitalize border-gray-400 focus:outline-none focus:border-gray-400 focus:ring-gray-400 block w-full rounded-md sm:text-sm focus:ring-0 mb-1 text-gray-500`}
                >
                  <option value="Is_Active">Is_Active</option>
                  <option value="Is_Inactive">Is_Inactive</option>
                  <option value="Is_Delete">Is_Delete</option>
                  <option value="Is_Blocked">Is_Blocked</option>
                </select>
              </div>
            </label>

            {/* Phone Number */}
            <label className="block mb-5 w-full">
              <span className="block text-sm font-medium text-slate-700">
                Phone Number
              </span>
              <div className="">
                <input
                  type="text"
                  name="phoneNumber"
                  placeholder="phoneNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phoneNumber}
                  className={`mt-1 px-3 py-2 bg-gray-50 border shadow-sm capitalize border-gray-400 focus:outline-none focus:border-gray-400 focus:ring-gray-400 block w-full rounded-md sm:text-sm focus:ring-0 mb-1 text-gray-500`}
                />
              </div>
            </label>

            {/* <label className="block mb-5">
              <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                Role
              </span>
              <div className="">
                <select
                  name="admin"
                  onChange={handleChange("roleId")}
                  onBlur={handleBlur("roleId")}
                  value={values.roleId}
                  className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors && errors?.admin && touched && touched?.admin
                    ? "border-red-400"
                    : "border-slate-400"
                    }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-sm focus:ring-1 mb-1`}
                >
                  {roles &&
                    roles.length > 0 &&
                    roles.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                </select>
              </div>
            </label> */}

            {/* Profile Image */}
            {/* <label className="block mb-5 w-full">
              <span className="block text-sm font-medium text-slate-700">
                Profile Image
              </span>
              <div className="h-[150px] w-[150px] my-2 rounded-md border border-gray-400 bg-gray-100 overflow-hidden">
              {values && values.imageUrl ?
              <div>
              <img
                src={values.imageUrl}
                alt="Selected Preview"
                style={{ maxWidth: '100%', maxHeight: '200px' }}
              />
            </div>
               :
               selectedImage ? (
                <div>
                  <img
                    src={selectedImage}
                    alt="Selected Preview"
                    style={{ maxWidth: '100%', maxHeight: '200px' }}
                  />
                </div>
              ) : <img src={userPlaceholder} alt="Placeholder" width={150} />}
              
              </div>
              <div className="">
                <input
                  type="file"
                  name="imageUrl"
                  placeholder="Select Profile Image"
                  onChange={(e) => {
                    handleImageSelect(e)
                    setFieldValue("imageUrl", selectedImage)
                  }}
                  onBlur={handleBlur}
                  value={values.imageUrl}
                  className={`mt-1 px-3 py-2 bg-gray-50 border shadow-sm capitalize border-gray-400 focus:outline-none focus:border-gray-400 focus:ring-gray-400 block w-full rounded-md sm:text-sm focus:ring-0 mb-1 text-gray-500`}
                />
              </div>
            </label> */}

            <div className="flex justify-end gap-3 md:gap-5 mt-4">
              <input
                type="submit"
                value={loading ? "Updating..." : "Update user"}
                className="bg-[#23A74C] text-sm border-[#23A74C] border-2 text-white py-1 px-4 my-2 rounded-md cursor-pointer"
              />
            </div>
          </main>
        </Form>
      )}
    </Formik>
  );
}
