import { Formik, Form, ErrorMessage } from "formik";
// import { MdClose } from "react-icons/md";
import axios from "axios";
import { C } from "../common";
import { useEffect, useState } from "react";

import { FaListUl, FaFolderOpen, FaFile } from "react-icons/fa";

export function ViewServiceds({ completedRequested }) {
  const [loading, setLoading] = useState(false);
  const [currentMaintainence, setCurrentMaintainence] = useState({});
  const [maintainenceDocs, setMaintainenceDocs] = useState([]);
  const [activeTab, setActiveTab] = useState("details");

  useEffect(() => {
    getMaintainence();
    downloadMaintenanceSupportDocs();
  }, []);

  const getMaintainence = async () => {
    setLoading(true);
    try {
      const maintenanceResponse = await axios.get(
        `${C.URLS.BASE_URL}/api/Maintenances/${completedRequested.id}`
      );
      const maintenanceData = maintenanceResponse.data.data;
      setCurrentMaintainence(maintenanceData);
    } catch (error) {
      console.error("Network Error:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const downloadMaintenanceSupportDocs = async () => {
    try {
      const response = await axios.get(
        `${C.URLS.BASE_URL}/api/Maintenances/${completedRequested.id}/support_documents`
      );

      setMaintainenceDocs(response.data.data);
    } catch (error) {
      console.error("Network Error:", error.message);
    }
  };

  const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const getDifferenceInDays = (date1Str, date2Str) => {
    const date1 = new Date(date1Str);
    const date2 = new Date(date2Str);

    const differenceInTime = date2.getTime() - date1.getTime();
    const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));

    return differenceInDays;
  };

  //   console.log(maintainenceDocs);

  return (
    <div className="m-5">
      <div className="flex justify-between">
        <div className="flex mb-5">
          <button
            onClick={() => setActiveTab("details")}
            className={`rounded-l-md rounded-r-none flex gap-2 items-center font-medium text-base py-2 px-4 ${
              activeTab === "details"
                ? "bg-[#C1BCCD] text-white"
                : "bg-gray-100 border"
            }`}
          >
            <FaListUl size={14} />
            Details
          </button>
          <button
            onClick={() => setActiveTab("documents")}
            className={`flex gap-2 items-center font-medium text-sm py-2 px-4 ${
              activeTab === "documents"
                ? "bg-[#C1BCCD] text-white"
                : "bg-gray-100 border"
            }`}
          >
            <FaFolderOpen size={14} />
            Documents
          </button>
        </div>
      </div>
      {activeTab === "details" && (
        <div>
          <div className="px-3 py-2 mt-3 flex text-sm flex-col border-b border-[#EEE]">
            <i className="text-base font-bold">Status:</i>
            <div>{currentMaintainence.status}</div>
          </div>
          <div className="px-3 py-2 mt-3 flex text-sm flex-col border-b border-[#EEE]">
            <i className="text-base font-bold">Duration:</i>
            <div>
              {getDifferenceInDays(
                currentMaintainence.startDate,
                currentMaintainence.endDate
              )}{" "}
              days
            </div>
          </div>
          <div className="px-3 py-2 mt-3 flex text-sm flex-col border-b border-[#EEE]">
            <i className="text-base font-bold">Estimated cost:</i>
            <div>UGX {currentMaintainence.estimatedCost}</div>
          </div>
          <div className="px-3 py-2 mt-3 flex text-sm flex-col border-b border-[#EEE]">
            <i className="text-base font-bold">Actual cost:</i>
            <div>UGX {currentMaintainence.cost}</div>
          </div>
          <div className="px-3 py-2 mt-3 flex text-sm flex-col border-b border-[#EEE]">
            <i className="text-base font-bold">Garage used:</i>
            <div>{currentMaintainence.garage?.name}</div>
          </div>
          <div className="px-3 py-2 mt-3 flex text-sm flex-col border-b border-[#EEE] list-none">
            <i className="text-base font-bold">Work requested:</i>
            <div
              dangerouslySetInnerHTML={{
                __html: currentMaintainence.workRequest,
              }}
            />
          </div>
          <div className="px-3 py-2 mt-3 flex text-sm flex-col border-b border-[#EEE] list-none">
            <i className="text-base font-bold">Comments:</i>
            <div
              dangerouslySetInnerHTML={{ __html: currentMaintainence.comments }}
            />
          </div>

          <div className="px-3 py-2 mt-3 flex text-sm flex-col border-b border-[#EEE] list-none">
            <i className="text-base font-bold">Additional note:</i>
            <div
              dangerouslySetInnerHTML={{ __html: currentMaintainence.note }}
            />
          </div>
        </div>
      )}

      {activeTab === "documents" && (
        <div className="px-3 py-2 mt-3 flex font-normal text-base-extra">
          <ul>
            {maintainenceDocs.map((doc, index) => (
              <li key={index} className="flex items-center gap-2 mb-2">
                <FaFile color="green" size={12} />
                <a
                  href={doc.fileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-green-600 hover:underline"
                >
                  {doc.fileName}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
