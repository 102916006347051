import { useEffect, useState } from "react";
import axios from "axios";
import { C } from "../../common";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Navbar,
  Group,
  Code,
  getStylesRef,
  createStyles,
  ThemeIcon,
} from "@mantine/core";
import { LinksGroup } from "./NavbarLinksGroup";
import { menuData } from "./menuData";
import { useAuth } from "../../helpers/auth/AuthContext";
import { FaCog, FaSignOutAlt } from "react-icons/fa";

const useStyles = createStyles((theme) => ({
  navbar: {
    fontFamily: '"Jost", sans-serif',
  },

  navbarContainer: {
    height: "100vh",
    overflowY: "auto",
  },

  version: {
    fontFamily: '"Outfit", sans-serif',
    backgroundColor: "green",
    color: theme.white,
    fontWeight: 700,
  },

  header: {
    fontFamily: '"Poppins", sans-serif',
    marginBottom: `calc(${theme.spacing.md} * 1.5)`,
  },

  footer: {
    paddingTop: theme.spacing.md,
    marginTop: theme.spacing.md,
  },

  link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: theme.white,
    cursor: "pointer",
    borderRadius: theme.radius.sm,
    fontFamily: '"DM Sans", sans-serif',
    fontSize: "14px",

    "&:hover": {
      // backgroundColor: 'green',
    },
  },

  linkIcon: {
    ref: getStylesRef("icon"),
    color: theme.white,
    opacity: 0.75,
    marginRight: 15,
    fontSize: "14px",
  },
}));

export function Sidebar() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, signOut } = useAuth();
  const [userRole, setUserRole] = useState('');

  const allowedMenuItems = menuData.admin.filter((item) =>
    item.roles.includes(userRole)
  );

  useEffect(() => {
    getUserRole(user?.id);
  }, []);

  const getUserRole = async (userID) => {
    try {
      await axios
        .get(`${C.URLS.BASE_URL}/api/Users/${userID}`)
        .then((res) => setUserRole(res.data?.role.name));
    } catch (error) {
      console.error("Network Error:", error.message);
    }
  };

  // const linksGroups = allowedMenuItems.map((group) => ({
  //   icon: group.icon,
  //   label: group.label,
  //   link: group.link,
  //   links: Array.isArray(group.links)
  //     ? group.links.map((link) => ({
  //       label: link.label,
  //       link: link.link,
  //     }))
  //     : [],
  //   initiallyOpened: location.pathname.startsWith(group.link),
  // }));

  // const toggleActiveClassStyle = (current_path) =>
  //   current_path === location.pathname
  //     ? " my-1 w-full font-medium relative bg-white/10"
  //     : " my-1 w-full font-medium relative";

  // TODO: Allow for toggling
  // const [toggleMenu, setToggleMenu] = useState(true);
  // const [toggleMenu, setToggleMenu] = useLocalStorage("toggleMenu", true);

  const sidebarLinks = allowedMenuItems.map((item, index) => {
    // Check if the item has links, if so render LinksGroup
    if (Array.isArray(item.links) && item.links.length) {
      return (
        <LinksGroup
          icon={item.icon}
          label={item.label}
          links={item.links}
          initiallyOpened={location.pathname.startsWith(item.link)}
          key={item.label}
          classes={classes}
        />
      );
    } else {
      return (
        <li
          className={classes.link}
          key={item.label}
          style={
            index % 2 === 1
              ? {
                borderColor: "#002800",
                borderWidth: "1px",
                borderStyle: "solid",
              }
              : {}
          }
        >
          <Link
            to={item.link}
            className={`${classes.link} w-full px-5 py-3 hover:bg-[#012904] ${item.link === location.pathname ? "text-[#fff]" : "text-[#ccc]"
              }`}
          >
            {/* <item.icon className={classes.linkIcon} stroke={1.5} /> */}
            <ThemeIcon
              style={{ backgroundColor: "#012904" }}
              className={classes.linkIcon}
            >
              <item.icon />
            </ThemeIcon>
            <span className="text-md">{item.label}</span>
          </Link>
        </li>
      );
    }
  });

  return (
    <Navbar
      width={{ sm: 300 }}
      className={`${classes.navbarContainer} px-2 py-2 bg-[#002200] text-white`}
    >
      <Navbar.Section className="flex-grow">
        <Group
          className={`${classes.header} border-b border-b-[#23A74C]/10 `}
          position="apart"
        >
          <header className="p-5 flex gap-5 items-center">
            <h1 className="f text-lg">MAKfleet</h1>
          </header>
          <Code className={classes.version}>v2.1.0</Code>
        </Group>

        {sidebarLinks}
      </Navbar.Section>

      <Navbar.Section
        className={`${classes.footer} border-t border-[#23A74C]/10`}
      >
        {userRole === "super_admin" && (
          <li
            className={`${classes.link} font-medium w-full px-5 py-3 hover:bg-[#012904] text-[#fff]' toggleActiveClassStyle(item.link)`}
            onClick={(event) => {
              event.preventDefault();
              navigate("/settings");
            }}
          >
            <FaCog className={classes.linkIcon} stroke={1.5} />
            <span>Settings</span>
          </li>
        )}

        <li
          className={`${classes.link} font-medium w-full px-5 py-3 hover:bg-[#012904] text-[#fff]' border border-[#002800]`}
          onClick={(event) => {
            event.preventDefault();
            signOut();
            navigate("/login");
          }}
        >
          <FaSignOutAlt className={classes.linkIcon} stroke={1.5} />
          <span>Logout</span>
        </li>
      </Navbar.Section>
    </Navbar>
  );
}
