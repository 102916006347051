import { useContext, useState, useEffect, createContext } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const signOut = () => {
    sessionStorage.removeItem("user");
    setUser(null);
  };

  useEffect(() => {
    setUser(JSON.parse(sessionStorage.getItem("user")));
    setLoading(false);
  }, []);

  const value = {
    user,
    loading,
    setUser,
    signOut,
  };

  if (loading) return null;

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
