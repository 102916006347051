import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController
);

const BarChart = ({ data }) => {
    
  
    const options = {
      scales: {
        y: {
          beginAtZero: false,
          border: { dash: [6, 6], display: true },
          weight: '700',
          grid: {
            display: true
          },
          ticks: {
            padding: 15,
            weight: '700',
          }
        },
        x: {
          beginAtZero: true,
          border: { display: false },
          grid: {
            display: false
          },
          ticks: {
            padding: 7,
            weight: '700',
          }
        }
      },
      legend: {
        labels: {
            fontSize: 10,
            fontFamily: '"DM Sans, sans-serif"',
            weight: '700'
            // display: 'false',
        },
    },
    plugins: {
      // title: {
      //     display: true,
      //     text: 'Repairs vs. Servicings'
      // }
  },
    elements: {
      bar: {
        borderRadius: 5,
        borderWidth: 0.5,
      },
    },
    };
  
    return (
      <div className="h-[40vh] flex justify-center items-center">
        <Bar data={data} options={options} />
      </div>
    );
  };
  
  export default BarChart;