import Login from "./pages/auth/Login";
import PasswordReset from "./pages/auth/PasswordReset";
import NewPassword from "./pages/auth/NewPassword";
import Dashboard from "./pages/estates/Dashboard";
import Users from "./pages/estates/Users";
import Drivers from "./pages/estates/Drivers";
import { EditDriver } from "./components";
import Units from "./pages/estates/Units";
import { EditUnit } from "./components";
import Vehicles from "./pages/estates/Vehicles";
import { EditVehicle } from "./components";
import Garages from "./pages/estates/Garages";
import Repairs from "./pages/estates/Repairs";

import { EditRepairForm } from "./components";
import { UpdateRepairForm } from "./components";

import { EditServiceForm } from "./components";
import { UpdateServiceForm } from "./components";

import Servicing from "./pages/estates/Servicing";
import Settings from "./pages/estates/Settings"; 

import { HashRouter, Routes, Route } from "react-router-dom";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoutes from "./routes/PrivateRoutes";
import { Toaster } from "react-hot-toast";

import DriverReports from "./pages/estates/DriverReports";
import VehicleReports from "./pages/estates/VehicleReports";
import Reports from "./pages/estates/Reports";
import UnitReports from "./pages/estates/UnitReports";

function App() {
  return (
    <HashRouter>
      <Toaster
        toastOptions={{
          className: "",

          success: {
            iconTheme: {
              primary: "white",
              secondary: "green",
            },
            style: {
              color: "white",
              padding: "8px 20px",
              background: "green",
            },
          },
          error: {
            style: {
              color: "white",
              padding: "8px 20px",
              background: "red",
            },
          },
        }}
      />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/reset-password" element={<PasswordReset />} /> */}
        <Route path="/new-password" element={<NewPassword />} />

        <Route
          path="/dashboard"
          element={
            <PrivateRoutes>
              <Dashboard />
            </PrivateRoutes>
          }
        />
        <Route
          path="/users"
          element={
            <PrivateRoutes>
              <Users />
            </PrivateRoutes>
          }
        />
        <Route
          path="/drivers"
          element={
            <PrivateRoutes>
              <Drivers />
            </PrivateRoutes>
          }
        />
        <Route
          path="/drivers/edit/:id"
          element={
            <PrivateRoutes>
              <EditDriver />
            </PrivateRoutes>
          }
        />

        <Route
          path="/units"
          element={
            <PrivateRoutes>
              <Units />
            </PrivateRoutes>
          }
        />
        <Route
          path="/units/edit/:id"
          element={
            <PrivateRoutes>
              <EditUnit />
            </PrivateRoutes>
          }
        />

        <Route
          path="/vehicles"
          element={
            <PrivateRoutes>
              <Vehicles />
            </PrivateRoutes>
          }
        />
        <Route
          path="/vehicles/edit/:id"
          element={
            <PrivateRoutes>
              <EditVehicle />
            </PrivateRoutes>
          }
        />

        <Route
          path="/garages"
          element={
            <PrivateRoutes>
              <Garages />
            </PrivateRoutes>
          }
        />

        <Route
          path="/repairs"
          element={
            <PrivateRoutes>
              <Repairs />
            </PrivateRoutes>
          }
        />
        <Route
          path="/repairs/:id"
          element={
            <PrivateRoutes>
              <EditRepairForm />
            </PrivateRoutes>
          }
        />
        <Route
          path="/repairs/update/:id"
          element={
            <PrivateRoutes>
              <UpdateRepairForm />
            </PrivateRoutes>
          }
        />

        <Route
          path="/servicing"
          element={
            <PrivateRoutes>
              <Servicing />
            </PrivateRoutes>
          }
        />
        <Route
          path="/servicings/:id"
          element={
            <PrivateRoutes>
              <EditServiceForm />
            </PrivateRoutes>
          }
        />
        <Route
          path="/servicings/update/:id"
          element={
            <PrivateRoutes>
              <UpdateServiceForm />
            </PrivateRoutes>
          }
        />

        <Route
          path="/settings"
          element={
            <PrivateRoutes>
              <Settings />
            </PrivateRoutes>
          }
        />
        <Route
          path="/reports"
          element={
            <PrivateRoutes>
              <Reports />
            </PrivateRoutes>
          }
        />
        <Route
          path="/reports/vehicles"
          element={
            <PrivateRoutes>
              <VehicleReports />
            </PrivateRoutes>
          }
        />
        <Route
          path="/reports/drivers"
          element={
            <PrivateRoutes>
              <DriverReports />
            </PrivateRoutes>
          }
        />
        <Route
          path="/reports/units"
          element={
            <PrivateRoutes>
              <UnitReports />
            </PrivateRoutes>
          }
        />
      </Routes>
    </HashRouter>
  );
}

export default App;
