import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { useAuth } from "../../helpers/auth/AuthContext";
import { C } from "../../common";
import axios from "axios";
import toast from "react-hot-toast";
import { IconCirclePlus, IconSquareLetterX } from "@tabler/icons-react";
import { addServicingRequestValidationSchema } from "../../helpers/validators";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export function AddRepairRequestForm({ onCancel }) {
  const [loading, setLoading] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [units, setUnits] = useState([]);
  const [garages, setGarages] = useState([]);

  const { user } = useAuth();

  useEffect(() => {
    fetchUnits();
    fetchGarages();
    fetchVehicles();
  }, []);

  const fetchVehicles = async () => {
    // setLoading(true);
    try {
      const unitsResponse = await axios.get(
        `${C.URLS.BASE_URL}/api/Units?UserId=${user.id}`
      );

      const userUnits = unitsResponse.data.data.collection[0].userUnits;
      if (userUnits.length === 0) {
        console.error("No userUnits found for the user");
        return;
      }

      const unitId = userUnits[0].unitId;
      const vehiclesResponse = await axios.get(
        `${C.URLS.BASE_URL}/api/Vehicles?UnitId=${unitId}`
      );
      setVehicles(vehiclesResponse.data.data.collection);
    } catch (error) {
      console.error("Network Error:", error.message);
    }
    // setLoading(false);
  };

  // console.log(vehicles);

  const fetchUnits = async () => {
    try {
      const allUnits = [];
      let currentPage = 1;

      while (true) {
        const response = await axios.get(
          `${C.URLS.BASE_URL}/api/Units?PageNumber=${currentPage}`
        );
        const unitsData = response.data.data.collection;

        if (unitsData.length === 0) {
          break; // No more users, exit the loop
        }

        allUnits.push(...unitsData);
        currentPage++;
      }

      setUnits(allUnits);
    } catch (error) {
      console.error(error.message);
    }
  };

  const fetchGarages = async () => {
    try {
      const allGarages = [];
      let currentPage = 1;

      while (true) {
        const response = await axios.get(
          `${C.URLS.BASE_URL}/api/Garages?PageNumber=${currentPage}`
        );
        const garagesData = response.data.data.collection;

        if (garagesData.length === 0) {
          break; // No more users, exit the loop
        }

        allGarages.push(...garagesData);
        currentPage++;
      }

      setGarages(allGarages);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <Formik
      initialValues={{
        vehicleId: "",
        unitId: "",
        // "garageId": "",
        note: "",
        maintenanceType: "REPAIRE",
      }}
      validationSchema={addServicingRequestValidationSchema}
      enableReinitiali
      onSubmit={async (values, { resetForm }) => {
        console.log(values);
        setLoading(true);

        try {
          const response = await axios.post(
            `${C.URLS.BASE_URL}/api/Maintenances`,
            {
              ...values,
            }
          );

          const responseData = response.data;

          if (responseData.code === "BadRequest")
            toast.error(responseData.message, { position: "bottom-center" });
          else if (response.status === 200 || response.status === 201) {
            toast.success(responseData.message, { position: "bottom-center" });
            setLoading(false);
            resetForm();
          } else {
            toast.error("An error occurred while processing your request.", {
              position: "bottom-center",
            });
          }

          setLoading(false);
        } catch (error) {
          // Handle network errors or other exceptions
          toast.error(`${error.message}`, { position: "bottom-center" });
          setLoading(false);
        }
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
        handleSubmit,
      }) => (
        <Form
          className="h-full overflow-y-scroll pt-10 px-4 md:px-10 bg-white"
          onSubmit={handleSubmit}
        >
          <main>
            {/* Vehicles */}
            <label className="block mb-5">
              <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base font-semibold text-primary-color mb-2">
                Vehicle (Reg No.)
              </span>
              <div className="">
                <select
                  name="vehicles"
                  onChange={handleChange("vehicleId")}
                  onBlur={handleBlur("vehicleId")}
                  value={values.vehicleId}
                  className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${
                    errors && errors?.vehicles && touched && touched?.vehicles
                      ? "border-red-400"
                      : "border-slate-400"
                  }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-sm focus:ring-1 mb-1`}
                >
                  <option value="">-- Select Vehicle --</option>
                  {vehicles &&
                    vehicles.length > 0 &&
                    vehicles.map((vehicle) => (
                      <option key={vehicle.id} value={vehicle.id}>
                        {vehicle.regNo}
                      </option>
                    ))}
                </select>
                <ErrorMessage name="vehicleId">
                  {(msg) => (
                    <div className="text-xs text-red-500 text-left w-full">
                      <p>{msg}</p>
                    </div>
                  )}
                </ErrorMessage>
              </div>
            </label>

            <label className="block mb-5">
              <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base font-semibold text-primary-color mb-2">
                Work requested
              </span>
              <ErrorMessage name="note">
                {(msg) => (
                  <div className="text-xs text-red-500 text-left w-full">
                    <p>{msg}</p>
                  </div>
                )}
              </ErrorMessage>
              <div className="">
                <ReactQuill
                  name="note"
                  placeholder="Work requested..."
                  onChange={handleChange("note")}
                  onClicknBlur={(range, source, quill) => {
                    handleBlur({
                      target: {
                        name: "note",
                        value: quill.getText(),
                      },
                    });
                  }}
                  style={{ height: "100px" }}
                  value={values.note}
                />
              </div>
            </label>

            <div className="flex justify-end gap-3 md:gap-3 py-5 mt-10">
              <button
                type="button"
                className="px-5 py-2 bg-[#FDE8EB] rounded-md font-bold text-[#B21028] flex items-center text-base"
                onClick={onCancel}
              >
                <span className="mr-2">
                  <IconSquareLetterX size={18} />
                </span>
                Cancel
              </button>
              <button
                type="submit"
                value={loading ? "Adding request..." : "Add request"}
                className="px-5 py-2 bg-[#23A74C] rounded-md font-bold text-white flex items-center text-base"
              >
                <span className="mr-2">
                  <IconCirclePlus size={18} />
                </span>
                {loading ? "Adding request..." : "Add request"}
              </button>
            </div>
          </main>
        </Form>
      )}
    </Formik>
  );
}
