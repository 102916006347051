import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import { C } from "../common";
import toast from "react-hot-toast";
import { editMaintainenceRequestValidationSchema } from "../helpers/validators";
import { useEffect, useState } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { useDisclosure } from "@mantine/hooks";
import { Modal, useMantineTheme } from "@mantine/core";

import { useNavigate, useParams } from "react-router-dom";
import { Layout } from "./Layout";

import {
  FaCaretLeft,
  FaTrash,
  FaSave,
  FaTimes,
  FaBan,
  FaCheck,
} from "react-icons/fa";

export function EditServiceForm() {
  const [loading, setLoading] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [opened2, { open: open2, close: close2 }] = useDisclosure(false);
  const theme = useMantineTheme();

  const [currentMaintainence, setCurrentMaintainence] = useState({});
  const [reqUnit, setReqUnit] = useState({});
  const [garages, setGarages] = useState([]);

  const [currentVehicle, setCurrentVehicle] = useState({});
  const [typeMakes, setTypeMakes] = useState([]);
  const [units, setUnits] = useState([]);
  const [uploadedImage, setUploadedImage] = useState(null);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchGarages();
    getMaintainence();
    getVehicle();
    fetchVehicleTypeMakes();
    fetchUnits();
  }, []);

  const getMaintainence = async () => {
    setLoading(true);
    try {
      const maintenanceResponse = await axios.get(
        `${C.URLS.BASE_URL}/api/Maintenances/${id}`
      );
      const maintenanceData = maintenanceResponse.data.data;
      setCurrentMaintainence(maintenanceData);

      const unitId = maintenanceData.unitId;
      if (unitId !== undefined && unitId !== "") {
        const unitResponse = await axios.get(
          `${C.URLS.BASE_URL}/api/Units/${unitId}`
        );
        const unitData = unitResponse.data.data;
        setReqUnit(unitData);
      }
    } catch (error) {
      console.error("Network Error:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const getVehicle = async () => {
    setLoading(true);
    try {
      await axios
        .get(`${C.URLS.BASE_URL}/api/Vehicles/${id}`)
        .then((res) => setCurrentVehicle(res.data.data));
    } catch (error) {
      // console.error("Network Error:", error.message);
    }
    setLoading(false);
  };

  const fetchVehicleTypeMakes = async () => {
    try {
      const allVehicleTypeMakes = [];
      let currentPage = 1;

      while (true) {
        const response = await axios.get(
          `${C.URLS.BASE_URL}/api/VehicleTypeMakes?PageNumber=${currentPage}`
        );
        const typeMakeData = response.data.data.collection;

        if (typeMakeData.length === 0) {
          break;
        }

        allVehicleTypeMakes.push(...typeMakeData);
        currentPage++;
      }

      setTypeMakes(allVehicleTypeMakes);
    } catch (error) {
      console.error(error.message);
    }
  };

  const fetchUnits = async () => {
    try {
      const allUnits = [];
      let currentPage = 1;

      while (true) {
        const response = await axios.get(
          `${C.URLS.BASE_URL}/api/Units?PageNumber=${currentPage}`
        );
        const unitsData = response.data.data.collection;

        if (unitsData.length === 0) {
          break;
        }

        allUnits.push(...unitsData);
        currentPage++;
      }

      setUnits(allUnits);
    } catch (error) {
      console.error(error.message);
    }
  };

  const fetchGarages = async () => {
    try {
      const allGarages = [];
      let currentPage = 1;

      while (true) {
        const response = await axios.get(
          `${C.URLS.BASE_URL}/api/Garages?PageNumber=${currentPage}`
        );
        const garagesData = response.data.data.collection;

        if (garagesData.length === 0) {
          break;
        }

        allGarages.push(...garagesData);
        currentPage++;
      }

      setGarages(allGarages);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleBackClick = () => {
    navigate(`/servicing`);
  };

  const handleDeclineConfirm = async () => {
    try {
      await axios.put(`${C.URLS.BASE_URL}/api/Maintenances/${id}/decline`);
      toast.success("Request declined.", { position: "bottom-center" });
    } catch (error) {
      toast.error(`Failed to decline requesr. Error: ${error.message}`, {
        position: "bottom-center",
      });
    } finally {
      close();
    }
  };

  const handleFormSubmit = async (values, actions) => {
    setLoading(true);
    try {
      const response = await axios.put(
        `${C.URLS.BASE_URL}/api/Maintenances/${id}/step-one`,
        {
          ...values,
        }
      );

      const responseData = response.data;

      if (responseData.code === "BadRequest")
        toast.error(responseData.message, { position: "bottom-center" });
      else if (response.status === 200 || response.status === 201) {
        toast.success(responseData.message, { position: "bottom-center" });
        setLoading(false);
        printRepairsRequestForm();
      } else {
        toast.error("An error occurred while processing your request.", {
          position: "bottom-center",
        });
      }

      setLoading(false);
      handleBackClick();
    } catch (error) {
      // Handle the error if needed
    }
    setLoading(false);
  };

  const printRepairsRequestForm = () => {
    const repairFormLink = `${C.URLS.BASE_URL}/api/Maintenances/${id}/download_form`;
    console.log(repairFormLink);

    const newTab = window.open(repairFormLink, "_blank");
    if (newTab) {
      newTab.focus();
    } else {
      console.error("Failed to open new tab.");
    }
  };

  const groupGaragesByStatus = (garages) => {
    const groups = {};

    garages.forEach((garage) => {
      const status = garage.status;

      if (!groups[status]) {
        groups[status] = [];
      }

      groups[status].push(garage);
    });

    // Sort statuses so "QUALIFIED" comes first
    const sortedStatuses = Object.keys(groups).sort((a, b) => {
      if (a === "QUALIFIED") return -1;
      if (b === "QUALIFIED") return 1;
      return 0;
    });

    // Create an array of grouped objects based on sorted statuses
    return sortedStatuses.map((status) => ({
      label: status === "QUALIFIED" ? "Qualified" : "Inqualified",
      garages: groups[status],
    }));
  };

  return (
    <Layout>
      <Formik
        initialValues={{
          id: currentVehicle.id,
          regNo: currentMaintainence.vehicle?.regNo,
          unitId: currentMaintainence.unitId,
          garageId: "",
          estimatedCost: "",
          workRequest: currentMaintainence.note,
        }}
        validationSchema={editMaintainenceRequestValidationSchema}
        enableReinitialize
        onSubmit={async (values, { resetForm }) => {
          open2();
        }}
      >
        {({
          values,
          setFieldValue,
          handleChange,
          handleBlur,
          errors,
          touched,
        }) => {
          return (
            <>
              <Form className="h-full">
                <main>
                  <div className="flex justify-between items-center gap-3 md:gap-5 mb-2">
                    <button
                      className="px-4 py-2 my-2 font-medium bg-[#C1BCCD] rounded-md text-[#FFF] flex items-center text-base"
                      onClick={handleBackClick}
                    >
                      <span className="mr-2">
                        <FaCaretLeft size={14} />
                      </span>
                      <span className="text-base-extra">View all requests</span>
                    </button>

                    <div className="flex gap-3 md:gap-3">
                      <button
                        type="button"
                        className="px-4 py-2 my-2 font-medium bg-[#FDE8EB] rounded-md text-[#B21028] flex items-center text-base"
                        onClick={open}
                      >
                        <span className="mr-2">
                          <FaTimes size={14} />
                        </span>
                        <span className="text-base-extra">Decline request</span>
                      </button>

                      <button
                        type="submit"
                        // onClick={open2}
                        className="px-4 py-2 my-2 font-medium bg-[#23A74C] text-sm text-white border rounded-md flex items-center text-base"
                      >
                        <span className="mr-2">
                          <FaCheck size={14} />
                        </span>
                        <span className="text-base-extra">
                          {loading ? "Forwarding..." : "Forward & print"}
                        </span>
                      </button>
                      {/* <button
                                                type="submit"
                                                className="px-4 py-2 my-2 font-medium bg-[#23A74C] text-sm text-white border rounded-md flex items-center text-base"
                                            >
                                                <span className="mr-2"><FaSave size={14} /></span>
                                                <span className="text-base-extra">{loading ? "Forwarding..." : "Forward / Print"}</span>
                                            </button> */}
                    </div>
                  </div>

                  {/* Section 1 */}
                  <div className="bg-white flex justify-between rounded-md px-10 pt-10 pb-5 flex">
                    {/* Left Column */}
                    <div className="w-1/2 pr-5">
                      {/* Registration number */}
                      <label className="block mb-5 w-full ">
                        <span className="block text-base font-semibold text-primary-color">
                          Registration number
                        </span>
                        <div className="">
                          <input
                            type="text"
                            name="regNo"
                            placeholder="Enter Reg No."
                            readOnly
                            onChange={handleChange("regNo")}
                            onBlur={handleBlur("regNo")}
                            value={values.regNo}
                            className={`mt-1 px-3 py-2 bg-gray-50 border border-gray-200 focus:outline-none focus:border-gray-200 focus:ring-gray-400 block w-full rounded-md sm:text-base focus:ring-0 mb-1 text-gray-500`}
                          />
                        </div>
                      </label>

                      {/* Unit */}
                      <label className="block mb-5 w-full ">
                        <span className="block text-base font-semibold text-primary-color">
                          Requesting unit
                        </span>
                        <div className="">
                          <input
                            type="text"
                            name="unitName"
                            readOnly
                            onChange={handleChange("unitName")}
                            onBlur={handleBlur("unitName")}
                            value={reqUnit.name}
                            className={`mt-1 px-3 py-2 bg-gray-50 border border-gray-200 focus:outline-none focus:border-gray-200 focus:ring-gray-400 block w-full rounded-md sm:text-base focus:ring-0 mb-1 text-gray-500`}
                          />
                        </div>
                      </label>

                      {/* Garages */}
                      <label className="block mb-5">
                        <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base font-semibold text-primary-color">
                          Select garage
                        </span>
                        <div className="">
                          <select
                            name="garages"
                            onChange={handleChange("garageId")}
                            onBlur={handleBlur("garageId")}
                            value={values.garageId}
                            className={`mt-1 px-3 py-2 bg-transparent border border-custom-green ${
                              errors &&
                              errors?.unitId &&
                              touched &&
                              touched?.unitId
                                ? "border-red-400"
                                : "border-custom-green"
                            }  placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-green block w-full rounded-md sm:text-base mb-1`}
                          >
                            <option value="">-- Select Garage --</option>
                            {garages &&
                              garages.length > 0 &&
                              groupGaragesByStatus(garages).map((group) => (
                                <optgroup label={group.label} key={group.label}>
                                  {group.garages.map((garage) => (
                                    <option key={garage.id} value={garage.id}>
                                      {garage.name}
                                    </option>
                                  ))}
                                </optgroup>
                              ))}
                          </select>
                          <ErrorMessage name="garageId">
                            {(msg) => (
                              <div className="text-xs text-red-500 text-left w-full">
                                <p>{msg}</p>
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </label>

                      <label className="block mb-5 w-full">
                        {/* <span className="block text-sm font-medium text-slate-700"> */}
                        <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base font-semibold text-primary-color">
                          Estimated cost (UGX)
                        </span>
                        <div className="">
                          <input
                            type="number"
                            name="estimatedCost"
                            placeholder="Estimated Cost"
                            onChange={handleChange("estimatedCost")}
                            onBlur={handleBlur("estimatedCost")}
                            value={`${values.estimatedCost}`}
                            className={`mt-1 px-3 py-2 bg-transparent border border-custom-green ${
                              errors &&
                              errors?.engineNo &&
                              touched &&
                              touched?.regBook
                                ? "border-red-400"
                                : "border-custom-green"
                            }  placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-base mb-1`}
                            // className={`mt-1 px-3 py-2 bg-gray-50 border shadow-sm capitalize border-gray-400 focus:outline-none focus:border-gray-400 focus:ring-gray-400 block w-full rounded-md sm:text-sm focus:ring-0 mb-1 text-gray-500`}
                          />
                          <ErrorMessage name="estimatedCost">
                            {(msg) => (
                              <div className="text-xs text-red-500 text-left w-full">
                                <p>{msg}</p>
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </label>
                    </div>

                    {/* Right Column */}
                    <div className="w-1/2 flex items-center">
                      <label className="block mb-5 w-full">
                        <span className="block text-base font-semibold text-primary-color">
                          Work requested
                          <span className="block text-base font-medium text-primary-color mb-3">
                            OR <i>additional notes</i>
                          </span>
                        </span>

                        <div className="w-full">
                          <ReactQuill
                            value={values.workRequest}
                            onChange={(value) =>
                              handleChange("workRequest")(value)
                            }
                            style={{ height: "150px" }}
                          />
                          <ErrorMessage name="workRequest">
                            {(msg) => (
                              <div className="text-xs text-red-500 text-left w-full">
                                <p>{msg}</p>
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </label>
                    </div>
                  </div>

                  {/* <DeleteConfirmModal /> */}
                  <Modal
                    opened={opened}
                    onClose={close}
                    title="Decline Request?"
                    centered
                    overlayProps={{
                      color:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[9]
                          : theme.colors.gray[2],
                      opacity: 0.55,
                      blur: 3,
                    }}
                  >
                    <div className="border-t border-[#EEE] pt-3">
                      <p className="text-base text-primary-color leading-snug">
                        Are you sure you want to decline this request?
                      </p>

                      <label className="block mb-2">
                        <span className="after:ml-0.5 after:text-red-500 block text-base font-medium text-slate-700">
                          {/* Decline reason(s) */}
                        </span>
                        <div className="">
                          <textarea
                            name="remarks"
                            placeholder="Please provide reason for decline..."
                            onChange={handleChange("remarks")}
                            onBlur={handleBlur("remarks")}
                            value={values.remarks}
                            className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${
                              errors &&
                              errors?.remarks &&
                              touched &&
                              touched?.remarks
                                ? "border-red-400"
                                : "border-slate-400"
                            } placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-base focus:ring-1 mb-1`}
                            rows="4"
                          />
                          <ErrorMessage name="remarks">
                            {(msg) => (
                              <div className="text-xs text-red-500 text-left w-full">
                                <p>{msg}</p>
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </label>
                    </div>
                    <div className="flex gap-3 mt-3 md:gap-3 justify-end">
                      <button
                        type="button"
                        className="px-4 py-2 my-2 font-medium bg-[#FDE8EB] rounded-md text-[#B21028] flex items-center text-base"
                        onClick={close}
                      >
                        <span className="mr-2">
                          <FaTimes size={12} />
                        </span>
                        <span className="text-base-extra">Cancel</span>
                      </button>

                      <button
                        type="button"
                        onClick={handleDeclineConfirm}
                        className="px-4 py-2 my-2 font-medium bg-[#23A74C] text-sm text-white border rounded-md flex items-center text-base"
                      >
                        <span className="mr-2">
                          <FaBan size={12} />
                        </span>
                        <span className="text-base-extra">Yes, decline</span>
                        {/* <span className="text-base-extra">{loading ? "Updating vehicle..." : "Delete vehicle"}</span> */}
                      </button>
                    </div>
                  </Modal>
                </main>

                {/* <ApproveConfirmModal /> */}
                <Modal
                  opened={opened2}
                  onClose={close2}
                  title="Approve request?"
                  centered
                  overlayProps={{
                    color:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[9]
                        : theme.colors.gray[2],
                    opacity: 0.55,
                    blur: 3,
                  }}
                >
                  <div className="border-t border-[#EEE] pt-2">
                    <p className="text-base-extra fpmt-medium text-primary-color leading-snug">
                      Are you sure you want to approve this request?
                    </p>
                  </div>

                  <div className="flex gap-3 mt-7 md:gap-5">
                    <button
                      type="button"
                      className="px-4 py-1 my-2 font-medium bg-[#FDE8EB] border rounded-md text-[#B21028] flex items-center text-base"
                      onClick={close2}
                    >
                      <span className="mr-2">
                        <FaTimes size={12} />
                      </span>
                      <span className="text-base-extra">Cancel</span>
                    </button>

                    <button
                      className="px-4 py-1 my-2 font-medium bg-[#23A74C] text-sm text-white border rounded-md flex items-center text-base"
                      onClick={() => {
                        handleFormSubmit(values);
                        close2();
                      }}
                    >
                      <span className="mr-2">
                        <FaCheck size={12} />
                      </span>
                      <span className="text-base-extra">Approve</span>
                    </button>
                  </div>
                </Modal>
              </Form>
            </>
          );
        }}
      </Formik>
    </Layout>
  );
}
