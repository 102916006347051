import { Layout, Paginate } from "../../components";
import { AddDriver } from "../../components";
import { useAuth } from "../../helpers/auth/AuthContext";

import FleetDrawer from "../../components/FleetDrawer";
import { useEffect, useState } from "react";
import axios from "axios";
import { C } from "../../common";
import { Helmet } from "react-helmet";
// import { Formik, Form, ErrorMessage } from "formik";
import { useOnClickOutside } from "../../hooks";
import { useRef } from "react";

import { useDisclosure } from "@mantine/hooks";
import { Pagination } from "@mantine/core";
import { TableSort } from "../../components/TableSort";

import { MoonLoader } from "react-spinners";

import { IconCirclePlus, IconUsersGroup } from "@tabler/icons-react";
import { RiUserSharedFill } from "react-icons/ri";
import { FaUserTie } from "react-icons/fa";

const Drivers = () => {
  const [loading, setLoading] = useState(false);

  const PAGE_SIZE = 20;

  const [openedAddDrawer, { open: openAddDrawer, close: closeAddDrawer }] =
    useDisclosure(false);

  const [roles, setRoles] = useState([]);
  const ref = useRef();
  const { user } = useAuth();
  const [currentUserRole, setcurrentUserRole] = useState("");

  const [driversData, setDriversData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortAsc, setSortAsc] = useState(false);

  const [activePage, setPage] = useState(1);
  const [selectedDriver, setSelectedDriver] = useState({});

  useEffect(() => {
    getUserRole(user?.id);
    getDrivers();

    const fetchRoles = async () => {
      try {
        axios
          .get(`${C.URLS.BASE_URL}/api/Roles`)
          .then((res) => setRoles(res.data.data));
        // .catch((error) => console.log(error));
      } catch (error) {
        console.error("Network Error:", error.message);
      }
    };

    fetchRoles();
  },  [activePage, currentUserRole, user.id]);

  const getUserRole = async (userID) => {
    try {
      await axios
        .get(`${C.URLS.BASE_URL}/api/Users/${userID}`)
        .then((res) => setcurrentUserRole(res.data?.role.name));
    } catch (error) {
      console.error("Network Error:", error.message);
    }
  };

  const getDrivers = async () => {
    setLoading(true);

    try {
      const apiUrl = `${C.URLS.BASE_URL}/api/Drivers?PageNumber=${activePage}&PageSize=${PAGE_SIZE}`;

      if (currentUserRole === "super_admin") {
        const response = await axios.get(apiUrl);
        setDriversData(response.data);
      } else {
        const unitsResponse = await axios.get(
          `${C.URLS.BASE_URL}/api/Units?UserId=${user.id}`
        );
        const userUnits = unitsResponse.data.data.collection;
        if (userUnits.length === 0) {
          // console.error("No userUnits found for the user");
          return;
        }
        const unitId = userUnits[0].id;
        const driverResponse = await axios.get(`${apiUrl}&UnitId=${unitId}`);
        setDriversData(driverResponse.data);
      }
    } catch (error) {
      const errorMessage = error.message.includes("userUnits")
        ? error.message
        : "Network Error: " + error.message;
      console.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const formatAndAdjustTimezone = (dateString) => {
    const utcDate = new Date(dateString);
    utcDate.setHours(utcDate.getHours() + 3);

    const formattedDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "UTC",
    }).format(utcDate);

    return formattedDate;
  };

  const {
    currentPage,
    pageSize,
    totalCount,
    totalPages,
    collection: drivers,
    previousPage,
    nextPage,
  } = driversData;

  const driverStatusMapping = {
    ACTIVE: "Active",
    NOT_ACTIVE: "Inactive",
    DISCONTINUED: "Discontinued",
    LEAVE: "On leave",
    RETIRED: "Retired",
  };

  const tableFields = [
    "Name",
    "Driver No.",
    "Contact",
    "Permit",
    "Verified",
    "Creation Date",
    "Status",
    "Action",
  ];

  const rowData =
    driversData.collection?.map((driver) => ({
      id: driver.id,
      "Driver No.": `${driver.driverNumber}`,
      Name: `${driver.name}`,
      Contact: `${driver.contact}`,
      Permit: `${
        driver.driverPermits && driver.driverPermits[0]
          ? driver.driverPermits[0].permitClass.name
          : ""
      }`,
      Verified: `${driver.isVerified ? "Yes" : "No"}`,
      "Creation Date": formatAndAdjustTimezone(user.createdOn),
      Status: `${driverStatusMapping[driver.status] || driver.status}`,
    })) || [];

  const calculatePaginationRanges = (currentPage, pageSize, totalCount) => {
    if (totalCount === 0) {
      return { startRange: 0, endRange: 0 };
    }
    const startRange = (currentPage - 1) * pageSize + 1;
    const endRange = Math.min(startRange + pageSize - 1, totalCount);
    return { startRange, endRange };
  };

  let filteredDrivers = drivers ? drivers.slice() : [];

  if (filteredDrivers.length > 0 && searchText) {
    filteredDrivers = filteredDrivers.filter((driver) => {
      const lowerSearchText = searchText.toLowerCase();
      const lowerName = driver.name ? driver.name.toLowerCase() : "";
      const lowerContact = driver.contact ? driver.contact.toLowerCase() : "";
      const lowerDriverNumber = driver.driverNumber
        ? driver.driverNumber.toLowerCase()
        : "";

      return (
        lowerName.includes(lowerSearchText) ||
        lowerContact.includes(lowerSearchText) ||
        lowerDriverNumber.includes(lowerSearchText)
      );
    });
  }

  const sortByFirstName = (ascOrDes) => {
    const sortedData = [...filteredDrivers].sort((a, b) =>
      ascOrDes
        ? a.firstName.localeCompare(b.firstName)
        : b.firstName.localeCompare(a.firstName)
    );
    filteredDrivers = sortedData;
  };

  const { startRange, endRange } = calculatePaginationRanges(
    activePage,
    pageSize,
    totalCount
  );

  return (
    <Layout>
      <Helmet>
        <title>Drivers - Mak Fleet</title>
      </Helmet>
      <div className="flex justify-between">
        <h2 className="flex gap-2 items-center font-medium text-md">
          <FaUserTie size={14} />
          {drivers ? drivers.length : 0} Drivers available
        </h2>
        <div className="flex font-medium text-sm text-[#434C50]">
          {(currentUserRole === "unit_admin" || currentUserRole === "super_admin" ||
            currentUserRole === "unit_head") && (
            <button
              className="px-5 py-2 bg-[#23A74C] rounded-md font-medium text-white flex items-center text-base"
              onClick={openAddDrawer}
            >
              <span className="mr-2">
                <IconCirclePlus size={18} />
              </span>
              <span className="text-sm">Add new driver</span>
            </button>
          )}
        </div>
      </div>

      {!loading ? (
        <>
          {drivers && (
            <TableSort
              data={rowData}
              fields={tableFields}
              category={"fleetDrivers"}
            />
          )}

          <div className="px-3 py-2 mt-3 flex justify-end items-center font-medium">
            <div className="text-sm mr-3" style={{ fontSize: "12px" }}>
              Showing {startRange ? startRange : 0} to {endRange ? endRange : 0}{" "}
              of {totalCount} entries
            </div>
            <div className="">
              <Pagination
                value={activePage}
                onChange={setPage}
                total={totalPages}
                size="sm"
                position="right"
                withEdges
                styles={(theme) => ({
                  fontFamily: "Outfit",
                  fontSize: "12px",
                  control: {
                    "&[data-active]": {
                      backgroundImage: theme.fn.gradient({
                        from: "#C1BCCD",
                        to: "#C1BCCD",
                      }),
                      border: 0,
                    },
                  },
                })}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="flex justify-center px-5 py-5">
          <div
            style={{ minHeight: "50vh" }}
            className="flex items-center justify-center"
          >
            <div className="flex justify-center px-5 py-5">
              <MoonLoader color="#4CCEAC" size={50} />
            </div>
          </div>
        </div>
      )}

      <FleetDrawer
        opened={openedAddDrawer}
        onClose={closeAddDrawer}
        title="Add New Driver"
      >
        <AddDriver onCancel={closeAddDrawer} />
      </FleetDrawer>
    </Layout>
  );
};

export default Drivers;
