import {
  Paper,
  Title,
} from '@mantine/core';
import Logo from "../../assets/img/mak-logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { BiSolidChevronRight, BiLock } from "react-icons/bi";
import { useAuth } from "../../helpers/auth/AuthContext";
import { Helmet } from "react-helmet";
import classes from './AuthenticationImage.module.css';
import { Formik, Form, ErrorMessage } from "formik";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useState } from "react";
import axios from "axios";
import { C } from "../../common"
import { signInWithEmailValidationSchema } from "../../helpers/validators";
import toast from "react-hot-toast";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useAuth();
  const navigate = useNavigate();

  return (
    <div className={classes.wrapper}>
      <Helmet>
        <title>Welcome - Mak Fleet</title>
      </Helmet>

      <Paper className={`${classes.form}`} radius={0} p={30}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={Logo} alt="MakFleet logo" width={80} />
        </div>

        <Title order={2} className={`${classes.title} text-md font-medium text-[#222]`} ta="center" mt="md" mb={50}>
          Welcome back to <span className='font-black'>MAKfleet</span>!
          {/* Welcome back to MAKfleet! */}
        </Title>

        {/* <section className="bg-white w-full h-full flex flex-col px-5 items-center justify-center border-l-[1px] border-neutral-200"> */}
        <Formik
          initialValues={{
            username_email: "",
            password: "",
          }}
          enableReinitialize
          validationSchema={signInWithEmailValidationSchema}
          onSubmit={async (values) => {
            setLoading(true);
            try {
              const response = await axios
                .post(
                  `${C.URLS.BASE_URL}/api/Authentication/login`,
                  {
                    username_email: values.username_email,
                    password: values.password,
                  }
                );
              const responseData = response.data;

              if (responseData.code === 'BadRequest') toast.error(responseData.message, { position: "bottom-center" });
              else if (response.status === 200 || response.status === 201) {
                toast.success(responseData.message, { position: "bottom-center" });
                setUser(response.data.data);
                sessionStorage.setItem(
                  "user",
                  JSON.stringify(response.data.data)
                );
                navigate("/dashboard");
                setLoading(false);
              } else {
                toast.error("An error occurred while processing your request.", { position: "bottom-center" });
              }

              setLoading(false);

            } catch (error) { }
          }}
        >
          {({ values, handleChange, handleBlur }) => (
            <Form className="w-80 md:w-96 flex flex-col gap-4">
              <div className="flex flex-col gap-1 group">
                <label htmlFor="username_email" className="font-semibold text-base text-[#515861]" style={{ pointerEvents: 'none' }}>
                  EMAIL ADDRESS
                </label>
                <input
                  type="text"
                  name="username_email"
                  placeholder="john.doe@mak.ac.ug"
                  onChange={handleChange("username_email")}
                  onBlur={handleBlur("username_email")}
                  className="mt-1 px-3 py-3 bg-gray-50 border border-[#d4e7e2] focus:outline-none focus:border-custom-greener focus:ring-gray-400 block w-full rounded-sm sm:text-base-extra focus:ring-0 mb-1 text-[#000]"
                  value={values.username_email}
                />
                <ErrorMessage name="username_email">
                  {(msg) => (
                    <div className="text-xs text-red-500 text-left w-full">
                      <p>{msg}</p>
                    </div>
                  )}
                </ErrorMessage>
              </div>

              <div className="flex flex-col gap-1 group relative">
                <label htmlFor="email" className="font-semibold text-base text-[#515861]" style={{ pointerEvents: 'none' }}>
                  PASSWORD
                </label>
                <div className=" placeholder-neutral-500 focus:ring-0 focus:outline-none w-full rounded-sm flex items-center">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Enter your password"
                    onChange={handleChange("password")}
                    onBlur={handleBlur("password")}
                    className="mt-1 px-3 py-3 bg-gray-50 border border-[#d4e7e2] focus:outline-none focus:border-custom-greener focus:ring-gray-400 block w-full rounded-sm sm:text-base-extra focus:ring-0 mb-1 text-[#000]"
                    value={values.password}
                  />
                  <span className="text-[#333] absolute right-2 cursor-pointer" onClick={() => setShowPassword((prev) => !prev)}>
                    {showPassword ? (
                      <AiOutlineEyeInvisible size={20} />
                    ) : (
                      <AiOutlineEye size={20} />
                    )}
                  </span>
                </div>

                <ErrorMessage name="password">
                  {(msg) => (
                    <div className="text-xs text-red-500 text-left w-full">
                      <p>{msg}</p>
                    </div>
                  )}
                </ErrorMessage>
              </div>

              <button
                type="submit"
                className="bg-[#449D44] text-white py-3 px-4 mt text-base-xxl rounded-sm font-medium w-full cursor-pointer flex items-center justify-between hover:bg-[#5BBF61]"
              >
                <span>{loading ? "Signing in..." : "Sign in"}</span>
                <BiSolidChevronRight />
              </button>
            </Form>
          )}
        </Formik>
      </Paper>

      <div className={classes.image}></div>
    </div>
  );
}

export default Login;