import { useEffect, useState } from "react";
import axios from "axios";
import { C } from "../common";
import {
  createStyles,
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  rem,
} from "@mantine/core";
import { keys } from "@mantine/utils";
import {
  IconSelector,
  IconChevronDown,
  IconChevronUp,
  IconSearch,
} from "@tabler/icons-react";
import {
  RiEdit2Fill,
  RiFolder2Line,
  RiCheckboxBlankCircleFill,
} from "react-icons/ri";
import { FiEye, FiDownload } from "react-icons/fi";
import { useDisclosure } from "@mantine/hooks";

import FleetDrawer from "./FleetDrawer";

import { EditUser } from "./EditUser";
import { ViewUnit } from "./ViewUnit";
import { ViewRepaireds } from "./ViewRepaireds";
import { ViewServiceds } from "./ViewServiceds";
import { EditGarage } from "./EditGarage";
import { ViewVehicle } from "./ViewVehicle";

import { EditRepairForm } from "./EditRepairForm";
import { UpdateRepairForm } from "./UpdateRepairForm";
import { EditRepaired } from "./EditRepaired";
import { EditServiceForm } from "./EditServiceForm";
import { EditServiced } from "./EditServiced";

import { EditRole } from "./EditRole";
import { EditVehicleMake } from "./EditVehicleMake";
import { EditVehicleType } from "./EditVehicleType";
import { EditPermitClass } from "./EditPermitClass";
import { EditDocumentCategory } from "./EditDocumentCategory";

// import { UploadDocuments } from './UploadDocuments';

import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  th: {
    padding: "0 !important",
    whiteSpace: "normal",
    textAlign: "left",
    width: "max-content",
  },

  actionField: {
    display: "flex",
  },

  searchField: {
    fontFamily: "Jost",
    fontSize: "10px",

    // '&:focus': {
    //     color: 'rgba(76, 206, 172, 0.3)',
    // }

    "&:focus": {
      outline: "none !important",
      border: "1px solid red",
      // box-shadow: 0 0 10px #719ECE;
    },
  },

  statusField: {
    textTransform: "capitalize",
  },

  control: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: `${theme.spacing.md} ${theme.spacing.md}`,

    // '&:hover': {
    //     backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    // },
  },

  actionControl: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  text: {
    flexGrow: 1,
    whiteSpace: "normal",
    overflow: "hidden",
    marginRight: "10px",
    // fontSize: '10px',
  },

  tableContainer: {
    width: "100%",
    maxWidth: "100%",
    overflowX: "auto",
    border: "1px solid rgba(76, 206, 172, 0.3)",
    borderRadius: "5px",
  },
}));

function Th({ children, reversed, sorted, onSort }) {
  const { classes } = useStyles();
  const Icon = sorted
    ? reversed
      ? IconChevronUp
      : IconChevronDown
    : IconSelector;

  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group position="">
          <Text
            className={`${classes.text} flex justify-center items-center text-base font-dm font-semibold text-[#515861]`}
            fw={500}
          >
            {children}
            <Icon className="inline ml-2" size="0.7rem" stroke={1} />
          </Text>
        </Group>
      </UnstyledButton>
    </th>
  );
}

function filterData(data, search) {
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    keys(data[0]).some((key) => {
      const value = item[key];
      return typeof value === "string" && value.toLowerCase().includes(query);
    })
  );
}

const sortData = (data, payload) => {
  const { sortBy } = payload;

  if (!sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      let valA = a[sortBy];
      let valB = b[sortBy];

      if (typeof valA === "number" && typeof valB === "number") {
        return payload.reversed ? valB - valA : valA - valB;
      }

      valA = String(valA).toLowerCase();
      valB = String(valB).toLowerCase();

      if (payload.reversed) {
        return valB.localeCompare(valA);
      }
      return valA.localeCompare(valB);
    }),
    payload.search
  );
};

export function TableSort({ data, fields, category }) {
  const navigate = useNavigate();
  const { classes } = useStyles();
  // const { classes } = useStyles();

  // console.log(row);
  // console.log(category);

  const [search, setSearch] = useState("");
  const [sortedData, setSortedData] = useState(data);
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const [opened, { open: open, close: close }] = useDisclosure(false);
  const [opened2, { open: open2, close: close2 }] = useDisclosure(false);

  const [selectedRow, setSelectedRow] = useState({});
  const [rowToEdit, setRowToEdit] = useState({});

  useEffect(() => {
    setRowToEdit(selectedRow);
  }, [selectedRow]);

  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(data, { sortBy: field, reversed, search }));
  };

  const handleSearchChange = (event) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(
      sortData(data, { sortBy, reversed: reverseSortDirection, search: value })
    );
  };

  const handleActionClick = (row) => {
    setSelectedRow(row);
    open();
  };

  const handleEditClick = (row) => {
    if (category === "fleetUnits") navigate(`/units/edit/${row.id}`);
    else if (category === "fleetVehicles") navigate(`/vehicles/edit/${row.id}`);
    else if (category === "fleetDrivers") navigate(`/drivers/edit/${row.id}`);
    else if (category === "fleetRepairForms") navigate(`/repairs/${row.id}`);
    else if (category === "fleetServiceForms")
      navigate(`/servicings/${row.id}`);
    else if (category === "fleetRepairings")
      navigate(`/repairs/update/${row.id}`);
    else if (category === "fleetServicings")
      navigate(`/servicings/update/${row.id}`);
    else handleActionClick(row);
  };

  const getEditTitle = (rowToEdit) => {
    if (rowToEdit["Reg No."]) return rowToEdit["Reg No."];
    else if (rowToEdit["Reg no."]) return rowToEdit["Reg no."];
    return rowToEdit.Name;
  };

  const rows = sortedData.map((row) => (
    <tr key={row.id}>
      {fields.map((field) => (
        <td
          className={`${classes.td} text-base font-dm text-primary-color break-words`}
          fw={500}
          key={field}
        >
          <div className="font-dm text-base">
            {field === "Action" ? (
              <div className={`${classes.actionField}`}>
                {category !== "fleetRepaireds" &&
                  category !== "fleetServiceds" &&
                  (category !== "fleetDocumentCategories" ||
                    (category === "fleetDocumentCategories" &&
                      ![
                        "Certificate of Completion",
                        "LPO",
                        "Upload Quotation",
                      ].includes(row["Name"]))) &&
                  row["Approval"] !== "Declined" && (
                    <span
                      className="cursor-pointer mr-2"
                      onClick={() => handleEditClick(row)}
                    >
                      <RiEdit2Fill size={14} className="cursor-pointer" />
                    </span>
                  )}

                {(category === "fleetUnits" ||
                  category === "fleetVehicles" ||
                  category === "fleetRepaireds" ||
                  category === "fleetServiceds") && (
                  <span
                    className="cursor-pointer mr-3"
                    onClick={() => handleActionClick(row)}
                  >
                    <FiEye size={14} className="cursor-pointer" />
                  </span>
                )}
              </div>
            ) : field === "Status" || field === "Approval" ? (
              <div className={classes.statusField}>
                <span
                  className={`mr-3 w-fit-content px-3 lowercase flex items-center justify-center p-1 rounded-full border ${
                    row[field] === "Active" ||
                    row[field] === "RUNNING" ||
                    row[field] === "Qualified" ||
                    row[field] === "COMPLETED" ||
                    row[field] === "Is_Active"
                      ? "border-green-200 text-green-600"
                      : row[field] === "Discontinued" ||
                        row[field] === "Declined"
                      ? "border-[#A50F1B]/20 text-[#A50F1B]"
                      : row[field] === "On leave" ||
                        row[field] === "PARKED" ||
                        row[field] === "REPAIRING" ||
                        row[field] === "SERVICING"
                      ? "border-yellow-200 text-yellow-600"
                      : "border-[#C1BCCD]/20"
                  }`}
                >
                  <RiCheckboxBlankCircleFill
                    size={12}
                    className={`mr-2 ${
                      row[field] === "Active" ||
                      row[field] === "RUNNING" ||
                      row[field] === "Qualified" ||
                      row[field] === "COMPLETED" ||
                      row[field] === "Is_Active"
                        ? "text-green-500"
                        : row[field] === "Discontinued" ||
                          row[field] === "Declined"
                        ? "text-[#A50F1B]"
                        : row[field] === "On leave" ||
                          row[field] === "PARKED" ||
                          row[field] === "REPAIRING" ||
                          row[field] === "SERVICING"
                        ? "text-yellow-500"
                        : "text-[#C1BCCD]"
                    }`}
                  />
                  <span className="italic">{row[field]}</span>
                </span>
              </div>
            ) : (
              row[field]
            )}
          </div>
        </td>
      ))}
    </tr>
  ));

  const getDrawerSize = () => {
    switch (category) {
      case "fleetUnits":
        return "70%";
      case "fleetVehicles":
        return "70%";
      default:
        return "lg";
    }
  };

  const renderEditComponent = () => {
    switch (category) {
      case "fleetUsers":
        return <EditUser user={rowToEdit} onCancel={close} />;
      case "fleetVehicles":
        return <ViewVehicle vehicle={rowToEdit} onCancel={close} />;
      case "fleetUnits":
        return <ViewUnit unit={rowToEdit} onCancel={close} />;
      case "fleetRepaireds":
        return (
          <ViewRepaireds completedRequested={rowToEdit} onCancel={close} />
        );
      case "fleetGarages":
        return <EditGarage garage={rowToEdit} onCancel={close} />;
      case "fleetServiceForms":
        return <EditServiceForm serviceForm={rowToEdit} onCancel={close} />;
      case "fleetServiceds":
        return (
          <ViewServiceds completedRequested={rowToEdit} onCancel={close} />
        );
      case "fleetRoles":
        return <EditRole role={rowToEdit} onCancel={close} />;
      case "fleetVehicleMake":
        return <EditVehicleMake vehicleMake={rowToEdit} onCancel={close} />;
      case "fleetVehicleType":
        return <EditVehicleType vehicleType={rowToEdit} onCancel={close} />;
      case "fleetPermitClasses":
        return <EditPermitClass permitClass={rowToEdit} onCancel={close} />;
      case "fleetDocumentCategories":
        return (
          <EditDocumentCategory documentCategory={rowToEdit} onCancel={close} />
        );
      case "fleetInsuranceProviders":
        return (
          <EditDocumentCategory documentCategory={rowToEdit} onCancel={close} />
        );
      default:
        return null;
    }
  };

  return (
    <ScrollArea className="mt-4">
      {category !== "fleetRepairedsDashboard" && (
        <TextInput
          placeholder="Search by any field"
          mb="sm"
          className={`${classes.searchField} focus:border-#4CCEAC font-dm text-base placeholder-outfit`}
          icon={<IconSearch size="0.9rem" stroke={1.5} />}
          value={search}
          onChange={handleSearchChange}
          styles={{
            input: {
              fontFamily: "Outfit",
              height: "35px",
              ":focus": {
                borderColor: "#4CCEAC",
              },
              "::placeholder": {
                fontSize: "12px",
              },
            },
          }}
        />
      )}

      <div className={classes.tableContainer}>
        <Table
          className="w-full"
          horizontalSpacing="md"
          verticalSpacing="md"
          sx={{ tableLayout: "fixed", width: "fit-content" }}
        >
          {/* <Table className='w-full' horizontalSpacing="md" verticalSpacing="md" sx={{ tableLayout: 'fixed', width: 'max-content' }}> */}
          <thead className="bg-[#C2E9DF]/20">
            <tr>
              {fields.map((field) => (
                <Th
                  key={field}
                  sorted={sortBy === field}
                  reversed={reverseSortDirection}
                  onSort={
                    field !== "Action" ? () => setSorting(field) : () => {}
                  }
                  className={
                    field === "Action" ? "flex justify-center items-center" : ""
                  }
                >
                  {field}
                </Th>
              ))}
            </tr>
          </thead>

          <tbody className="text-base">
            {rows.length > 0 ? (
              rows
            ) : (
              <tr>
                {/* <td colSpan={Object.keys(fields[0]).length}> */}
                <td colSpan={fields.length}>
                  <Text
                    align="center"
                    className="text-base text-primary-color font-dm"
                  >
                    Nothing found.
                  </Text>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      <FleetDrawer
        opened={opened}
        onClose={close}
        title={getEditTitle(rowToEdit)}
        size={getDrawerSize()}
      >
        {renderEditComponent()}
      </FleetDrawer>

      {/* <FleetDrawer opened={opened2} onClose={close2} title="Upload Documents">
                <UploadDocuments repairForm={rowToEdit} onCancel={close2} />
            </FleetDrawer> */}
    </ScrollArea>
  );
}
