import { Formik, Form, ErrorMessage } from "formik";
import { C } from "../../common";
import axios from "axios";
import toast from "react-hot-toast";
import { addDriverValidationSchema } from "../../helpers/validators";
import { useAuth } from "../../helpers/auth/AuthContext";
import { IconCirclePlus, IconSquareLetterX } from "@tabler/icons-react";
import { useEffect, useState } from "react";

export function AddDriver({ onCancel }) {
  const { user } = useAuth();
  const [currentUserUnit, setcurrentUserUnit] = useState("");
  const [permitClasses, setPermitClasses] = useState([]);
  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPermitClasses = async () => {
      try {
        axios
          .get(`${C.URLS.BASE_URL}/api/PermitClasses`)
          .then((res) => setPermitClasses(res.data.data.collection))
          .catch((error) => console.log(error));
      } catch (error) {}
    };

    const getUserUnit = async () => {
      const unitsResponse = await axios.get(
        `${C.URLS.BASE_URL}/api/Units?UserId=${user.id}`
      );
      const userUnits = unitsResponse.data.data.collection;
      if (userUnits.length === 0) {
        // console.error("No userUnits found for the user");
        return;
      }
      const unitId = userUnits[0].id;
      setcurrentUserUnit(unitId);
    };

    const fetchUnits = async () => {
      try {
        const allUnits = [];
        let currentPage = 1;

        while (true) {
          const response = await axios.get(
            `${C.URLS.BASE_URL}/api/Units?PageNumber=${currentPage}`
          );
          const unitsData = response.data.data.collection;

          if (unitsData.length === 0) {
            break; 
          }

          allUnits.push(...unitsData);
          currentPage++;
        }

        setUnits(allUnits);
      } catch (error) {
        console.error(error.message);
      }
    };

    getUserUnit(user?.id);
    fetchPermitClasses();
    fetchUnits();
  }, []);

  // console.log(units);
  const validateImage = (file) => {
    const maxSize = 1024 * 1024; // 1MB in bytes
    if (!file) {
      return "Please select an image.";
    }
    if (file.size > maxSize) {
      return "Image size exceeds 1MB.";
    }
    return null;
  };

  const handleImageChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        // e.target.result contains the Base64 encoded string of the image
        // sendImageToServer(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Formik
      initialValues={{
        driverNumber: "",
        unitId: currentUserUnit,
        name: "",
        contact: "",
        name: "",
        email: "",
        permitNo: "",
        imageUrl: "",
        expiresOn: "",
        issuedOn: "",
      }}
      validationSchema={addDriverValidationSchema}
      enableReinitialize
      onSubmit={async (values, { resetForm }) => {
        console.log(values);
        setLoading(true);

        const formData = new FormData();

        for (const [key, value] of Object.entries(values)) {
          formData.append(key, value);
        }

        try {
          await axios
            .post(`${C.URLS.BASE_URL}/api/Drivers`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(() => {
              toast.success("Successfully added driver.", {
                position: "bottom-center",
              });
              setLoading(false);
              resetForm();
            })
            .catch((error) => {
              toast.error(`${error.message}`, {
                position: "bottom-center",
              });
              setLoading(false);
            });
        } catch (error) {
          console.error(error);
        }
      }}
    >
      {({ values, handleChange, handleBlur, errors, touched }) => {
        return (
          <>
            <Form className="h-full overflow-y-scroll pt-10 px-4 md:px-10 bg-white">
              <main>
                <label className="block mb-5">
                  <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base font-medium text-slate-700">
                    Driver name
                  </span>
                  <div className="">
                    <input
                      type="text"
                      name=""
                      placeholder="Name"
                      onChange={handleChange("name")}
                      onBlur={handleBlur("name")}
                      value={values.name}
                      className={`mt-1 px-3 py-2 bg-transparent border border-custom-green ${
                        errors && errors?.name && touched && touched?.name
                          ? "border-red-400"
                          : "border-custom-green"
                      }  placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-base mb-1`}
                    />
                    <ErrorMessage name="name">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </label>

                <label className="block mb-5">
                  <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base font-medium text-slate-700">
                    Driver number
                  </span>
                  <div className="">
                    <input
                      type="text"
                      name=""
                      placeholder="Driver Number"
                      onChange={handleChange("driverNumber")}
                      onBlur={handleBlur("driverNumber")}
                      value={values.driverNumber}
                      className={`mt-1 px-3 py-2 bg-transparent border ${
                        errors &&
                        errors?.driverNumber &&
                        touched &&
                        touched?.driverNumber
                          ? "border-red-400"
                          : "border-custom-green"
                      }  placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-base mb-1`}
                    />
                    <ErrorMessage name="driverNumber">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </label>

                <label className="block mb-5">
                  <span className="block text-base font-medium text-slate-700">
                    Contact
                  </span>
                  <div className="">
                    <input
                      type="text"
                      name="contact"
                      placeholder="Contact"
                      onChange={handleChange("contact")}
                      onBlur={handleBlur("contact")}
                      value={values.contact}
                      className="mt-1 px-3 py-2 bg-transparent border border-custom-green placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-base mb-1"
                    />
                    <ErrorMessage name="contact">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </label>

                <label className="block mb-5">
                  <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base font-medium text-slate-700">
                    Email
                  </span>
                  <div className="">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      onChange={handleChange("email")}
                      onBlur={handleBlur("email")}
                      value={values.email}
                      className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${
                        errors && errors?.email && touched && touched?.email
                          ? "border-red-400"
                          : "border-custom-green"
                      }  placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-base mb-1`}
                    />
                    <ErrorMessage name="email">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </label>

                {/* Units */}
                <label className="block mb-7">
                  <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base font-medium text-slate-700">
                    Unit or college
                  </span>
                  <div className="">
                    <select
                      name="units"
                      onChange={handleChange("unitId")}
                      onBlur={handleBlur("unitId")}
                      value={currentUserUnit}
                      disabled={true}
                      className={`mt-1 px-3 py-3 bg-gray-50 border border-gray-200 ${
                        errors && errors?.units && touched && touched?.units
                          ? "border-red-400"
                          : "border-gray-200"
                      }  placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-base mb-1 text-gray-500`}
                    >
                      <option value="">-- Select Unit --</option>
                      {units &&
                        units.length > 0 &&
                        units.map((unit) => (
                          <option key={unit.id} value={unit.id}>
                            {unit.name}
                          </option>
                        ))}
                    </select>
                    <ErrorMessage name="unitId">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </label>

                <label className="block mb-5">
                  <span className="block text-base font-medium text-slate-700">
                    Permit number
                  </span>
                  <div className="">
                    <input
                      type="text"
                      name="permitNo"
                      placeholder="permitNo"
                      onChange={handleChange("permitNo")}
                      onBlur={handleBlur("permitNo")}
                      value={values.permitNo}
                      className="mt-1 px-3 py-2 bg-transparent border border-custom-greener placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-base mb-1"
                    />
                    <ErrorMessage name="permitNo">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </label>

                {/* Permit classes */}
                <label className="block mb-5">
                  <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base font-medium text-slate-700">
                    Permit class
                  </span>
                  <div className="">
                    <select
                      name="permitClassId"
                      onChange={handleChange("permitClassId")}
                      onBlur={handleBlur("permitClassId")}
                      value={values.permitClassId}
                      className={`mt-1 px-3 py-2 bg-transparent border ${
                        errors &&
                        errors?.permitClass &&
                        touched &&
                        touched?.permitClass
                          ? "border-red-400"
                          : "border-custom-green"
                      }  placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-base mb-1`}
                    >
                      <option value="">-- Select Permit Class --</option>
                      {permitClasses &&
                        permitClasses.length > 0 &&
                        permitClasses.map((permitClass) => (
                          <option key={permitClass.id} value={permitClass.id}>
                            {permitClass.name}
                          </option>
                        ))}
                    </select>
                    <ErrorMessage name="permitClassId">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </label>

                {/* Permit Image */}
                <label className="block mb-5">
                  <span className="block text-base font-medium text-slate-700">
                    Upload permit image
                  </span>
                  <div className="">
                    <input
                      type="file"
                      name="imageUrl"
                      // validate={values.imageUrl}
                      onChange={handleChange("imageUrl")}
                      // onChange={(event) => handleImageChange(event, setFieldValue)}
                      onBlur={handleBlur("imageUrl")}
                      className="mt-1 px-3 py-2 bg-transparent border placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-base mb-1"
                    />
                    <ErrorMessage name="imageUrl">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </label>

                {/* Issued On */}
                <label className="block mb-5">
                  <span className="block text-base font-medium text-slate-700">
                    Permit issue date
                  </span>
                  <div className="">
                    <input
                      type="date"
                      name="issuedOn"
                      // placeholder="permitNumber"
                      onChange={handleChange("issuedOn")}
                      onBlur={handleBlur("issuedOn")}
                      value={values.issuedOn}
                      className="mt-1 px-3 py-2 bg-transparent border border-custom-greener placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-base mb-1"
                    />
                    <ErrorMessage name="issuedOn">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </label>

                {/* Issued On */}
                <label className="block mb-5">
                  <span className="block text-base font-medium text-slate-700">
                    Permit expiry date
                  </span>
                  <div className="">
                    <input
                      type="date"
                      name="expiresOn"
                      // placeholder="permitNumber"
                      onChange={handleChange("expiresOn")}
                      onBlur={handleBlur("expiresOn")}
                      value={values.expiresOn}
                      className="mt-1 px-3 py-2 bg-transparent border border-custom-greener placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-base mb-1"
                    />
                    <ErrorMessage name="expiresOn">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </label>

                <div className="flex justify-end gap-3 md:gap-2 mt-4">
                  <button
                    type="button"
                    className="px-5 py-2 bg-[#FDE8EB] rounded-md font-bold text-[#B21028] flex items-center text-base"
                    onClick={onCancel}
                  >
                    <span className="mr-2">
                      <IconSquareLetterX size={18} />
                    </span>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    value={loading ? "Adding driver..." : "Add driver"}
                    className="px-5 py-2 bg-[#23A74C] rounded-md font-bold text-white flex items-center text-base"
                  >
                    <span className="mr-2">
                      <IconCirclePlus size={18} />
                    </span>
                    {loading ? "Adding driver..." : "Add driver"}
                  </button>
                </div>
              </main>
            </Form>
          </>
        );
      }}
    </Formik>
  );
}
