import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import { C } from "../../common";
import toast from "react-hot-toast";
import { getFinancialReports } from "../../helpers/validators";
import { useEffect, useState } from "react";
import Select from "react-select";
import { Layout } from "../../components/Layout";

import { TableSort } from "../../components/TableSort";
import { MoonLoader } from "react-spinners";
import { FaDatabase } from "react-icons/fa";

const Reports = () => {
  const [loading, setLoading] = useState(false);
  const [availableStatuses, setAvailableStatuses] = useState([]);
  const [fReports, setFReports] = useState([]);
  const [selectedReportArea, setSelectedReportArea] = useState(5);

  const [reportTypes, setReportTypes] = useState([
    { id: 1, name: "Units" },
    { id: 2, name: "Vehicles" },
    { id: 3, name: "Drivers" },
    { id: 4, name: "Garages" },
    { id: 5, name: "Financial" },
    { id: 6, name: "Maintenances" },
  ]);

  const reportAreas = [
    { id: 1, name: "Units" },
    { id: 2, name: "Vehicles" },
    { id: 3, name: "Drivers" },
    { id: 4, name: "Garages" },
    { id: 5, name: "Financial" },
    { id: 6, name: "Maintenances" },
  ];

  const categories = ["GARAGE", "UNIT", "VEHICLE"];
  const statuses = [
    // "NEW_REQUEST",
    "VERIFIED",
    "APPROVED",
    "DECLINED",
    "SERVICING",
    "REPAIRING",
    "COMPLETED",
    "COMPLETED2",
  ];

  // console.log(fReports);

  const categoryStatusMap = {
    GARAGE: ["REPAIRING", "SERVICING"],
    UNIT: ["APPROVED", "REPAIRING", "SERVICING", "DECLINED"],
    VEHICLE: ["SERVICING", "REPAIRING", "COMPLETED"],
  };

  // const categoryStatusMap = {
  //   GARAGE: ["REPAIRING", "SERVICING"],
  //   UNIT: ["NEW_REQUEST", "APPROVED", "REPAIRING", "SERVICING", "DECLINED"],
  //   VEHICLE: ["NEW_REQUEST", "SERVICING", "REPAIRING", "COMPLETED"],
  // };

  const today = new Date();
  const currentYear = today.getFullYear();
  // const firstOfJanuary = new Date(currentYear, 0, 1);
  const firstOfJanuary2023 = new Date(2023, 0, 1);

  const toDateString = today.toISOString().split("T")[0];
  const fromDateString = firstOfJanuary2023.toISOString().split("T")[0];
  // const fromDateString = firstOfJanuary.toISOString().split("T")[0];

  const handleCategoryChange = (selectedOption, setFieldValue, values) => {
    const category = selectedOption ? selectedOption.value : "";
    setFieldValue("Category", category);

    const statuses = categoryStatusMap[category] || [];
    setAvailableStatuses(statuses);

    if (!statuses.includes(values.Status)) {
      setFieldValue("Status", "");
    }
  };

  const tableFields = [
    "reference",
    "vehiclesRepaired",
    "vehiclesServiced",
    "repairedCost",
    "servicedCost",
    "total",
  ];

  const finalTableFields = [
    "Reference",
    "Repairs",
    "Servicings",
    "Repair cost",
    "Servicing cost",
    "Total cost",
  ];

  const fieldMapping = {
    reference: "Reference",
    repairedCost: "Repair cost",
    servicedCost: "Servicing cost",
    total: "Total cost",
    vehiclesRepaired: "Repairs",
    vehiclesServiced: "Servicings",
  };

  const rowData =
    fReports.map((report) => {
      return tableFields.reduce((accumulator, field) => {
        const newFieldName = fieldMapping[field] || field;
        const originalValue = report[field];

        accumulator[newFieldName] =
          typeof originalValue === "number"
            ? originalValue.toLocaleString()
            : originalValue;

        return accumulator;
      }, {});
    }) || [];

  useEffect(() => {}, []);

  return (
    <Layout>
      <Formik
        initialValues={{
          reportTypeId: reportTypes.find((type) => type.name === "Financial")
            .id,
          Category: "",
          Status: "",
          To: toDateString,
          From: fromDateString,
        }}
        validationSchema={getFinancialReports}
        enableReinitialize
        onSubmit={async (values, { resetForm }) => {
          setLoading(true);
          try {
            const response = await axios.get(
              `${C.URLS.BASE_URL}/api/Maintenances/finance-report`,
              {
                params: {
                  Category: values.Category,
                  Status: values.Status,
                  From: values.From,
                  To: `{values.To + 1}`,
                  // To: values.To,
                },
              }
            );
            const responseData = response.data.data;
            setFReports(responseData);

            if (responseData.code === "BadRequest") {
              const errorMessage =
                responseData.message ||
                "Oops! It looks like some information is missing or incorrect";
              toast.error(errorMessage, { position: "bottom-center" });
            } else if (response.status === 200 || response.status === 201) {
              const successMessage =
                responseData.message || "Successfully loaded";
              toast.success(successMessage, {
                position: "bottom-center",
              });
              setLoading(false);
            } else {
              toast.error("An error occurred while processing your request.", {
                position: "bottom-center",
              });
            }

            setLoading(false);
          } catch (error) {}
        }}
      >
        {({
          values,
          setFieldValue,
          handleChange,
          handleBlur,
          errors,
          touched,
        }) => {
          return (
            <>
              <Form className="h-full overflow-y-scroll">
                <main>
                  <div class="flex justify-between items-center gap-3 md:gap-5 mb-2 px-2">
                    {/* Report Area */}
                    <div class="w-1/2 flex flex-wrap -mx-2">
                      <div className="w-1/4 pr-1 flex flex-col">
                        <label className="block mb-5">
                          <span className="block text-base font-semibold text-primary-color">
                            Report area
                          </span>
                          <div className="">
                            <Select
                              isSearchable
                              name="reportTypeId"
                              value={
                                reportTypes
                                  .filter(
                                    (type) => type.id === values.reportTypeId
                                  )
                                  .map((type) => ({
                                    value: type.id,
                                    label: type.name,
                                  }))[0]
                              }
                              isDisabled={true}
                              onChange={(option) => {
                                const newValue = option ? option.value : "";
                                setFieldValue("reportTypeId", newValue);
                                setSelectedReportArea(newValue);
                                // console.log("Selected option:", newValue);
                              }}
                              options={reportTypes.map((type) => ({
                                value: type.id,
                                label: type.name,
                              }))}
                              className={`mt-1 bg-gray-50 block w-full rounded-md sm:text-base focus:ring-0 mb-1`}
                              classNamePrefix="select"
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  borderColor: state.isFocused
                                    ? "rgba(76, 206, 172, 0.4)"
                                    : "rgba(76, 206, 172, 0.7)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px rgba(76, 206, 172, 0)"
                                    : "",
                                  "&:hover": {
                                    borderColor: "rgba(76, 206, 172, 0.4)",
                                  },
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: state.isSelected
                                    ? "#23A74C"
                                    : state.isFocused
                                    ? "#EAF2F1"
                                    : provided.backgroundColor,
                                  color: state.isSelected
                                    ? "#FFFFFF"
                                    : provided.color,
                                }),
                              }}
                            />
                            <ErrorMessage name="reportTypeId">
                              {(msg) => (
                                <div className="text-xs text-red-500 text-left w-full">
                                  <p>{msg}</p>
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                        </label>
                      </div>

                      <div className="w-1/4 pr-1 flex flex-col">
                        <label className="block mb-5">
                          <span className="block text-base font-semibold text-primary-color">
                            Category
                          </span>
                          <div className="">
                            <Select
                              isSearchable
                              name="Category"
                              value={
                                categories
                                  .filter(
                                    (category) => category === values.Category
                                  )
                                  .map((category) => ({
                                    value: category,
                                    label: category,
                                  }))[0] || ""
                              }
                              // onChange={handleCategoryChange(setFieldValue, values)}
                              // onChange={() =>
                              //   handleCategoryChange(setFieldValue, values)
                              // }
                              onChange={(selectedOption) =>
                                handleCategoryChange(
                                  selectedOption,
                                  setFieldValue,
                                  values
                                )
                              }
                              // onChange={(event) => handleFileChange(event, setFieldValue, index)}
                              options={categories.map((category) => ({
                                value: category,
                                label: category,
                              }))}
                              className={`mt-1 bg-gray-50 block w-full rounded-md sm:text-base focus:ring-0 mb-1`}
                              classNamePrefix="select"
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  borderColor: state.isFocused
                                    ? "rgba(76, 206, 172, 0.4)"
                                    : "rgba(76, 206, 172, 0.7)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px rgba(76, 206, 172, 0)"
                                    : "",
                                  "&:hover": {
                                    borderColor: "rgba(76, 206, 172, 0.4)",
                                  },
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: state.isSelected
                                    ? "#23A74C"
                                    : state.isFocused
                                    ? "#EAF2F1"
                                    : provided.backgroundColor,
                                  color: state.isSelected
                                    ? "#FFFFFF"
                                    : provided.color,
                                }),
                              }}
                            />
                          </div>
                        </label>
                      </div>

                      {/* Status */}
                      <div className="w-1/4 pr-1 flex flex-col">
                        <label className="block mb-5">
                          <span className="block text-base font-semibold text-primary-color">
                            Status
                          </span>
                          <div className="">
                            <Select
                              isSearchable
                              name="Status"
                              value={
                                availableStatuses
                                  .filter((status) => status === values.Status)
                                  .map((status) => ({
                                    value: status,
                                    label: status,
                                  }))[0] || ""
                              }
                              onChange={(selectedOption) =>
                                setFieldValue(
                                  "Status",
                                  selectedOption ? selectedOption.value : ""
                                )
                              }
                              options={availableStatuses.map((status) => ({
                                value: status,
                                label: status,
                              }))}
                              className={`mt-1 bg-gray-50 block w-full rounded-md sm:text-base focus:ring-0 mb-1`}
                              classNamePrefix="select"
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  borderColor: state.isFocused
                                    ? "rgba(76, 206, 172, 0.4)"
                                    : "rgba(76, 206, 172, 0.7)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px rgba(76, 206, 172, 0)"
                                    : "",
                                  "&:hover": {
                                    borderColor: "rgba(76, 206, 172, 0.4)",
                                  },
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: state.isSelected
                                    ? "#23A74C"
                                    : state.isFocused
                                    ? "#EAF2F1"
                                    : provided.backgroundColor,
                                  color: state.isSelected
                                    ? "#FFFFFF"
                                    : provided.color,
                                }),
                              }}
                            />
                          </div>
                        </label>
                      </div>

                      {/* Submit Button - Adjusted to align with other form elements */}
                      <div className="w-1/4 flex justify-center items-center">
                        <button
                          type="submit"
                          className="px-4 py-2 my-2 font-medium bg-[#23A74C] text-base text-white border rounded-md flex items-center justify-center"
                        >
                          <span className="mr-2">
                            <FaDatabase size={14} />
                          </span>
                          <span>{loading ? "Loading..." : "Load data"}</span>
                        </button>
                      </div>
                    </div>

                    <div class="w-1/2 flex flex-wrap justify-end -mx-2">
                      <div className="w-1/4 px flex flex-col mr-2">
                        <label className="block mb-5 w-full">
                          <span className="block text-base font-semibold text-primary-color">
                            Start date
                          </span>
                          <div className="">
                            <input
                              type="date"
                              name="From"
                              placeholder="Start date"
                              onChange={handleChange("From")}
                              onBlur={handleBlur("From")}
                              value={`${values.From}`}
                              className={`mt-1 px-2 py-2 text-base bg-[#FFF] border border-green-400 focus:outline-none focus:border-green-400 focus:ring-gray-400 block w-full rounded-md sm:text-base focus:ring-0 mb-1`}
                            />
                          </div>
                        </label>
                      </div>

                      <div className="w-1/4 px flex flex-col mr-2">
                        <label className="block mb-5 w-full">
                          <span className="block text-base font-semibold text-primary-color">
                            End date
                          </span>
                          <div className="">
                            <input
                              type="date"
                              name="To"
                              placeholder="End date"
                              onChange={handleChange("To")}
                              onBlur={handleBlur("To")}
                              value={values.To || ""}
                              className="mt-1 px-2 py-2 text-base bg-[#FFF] border border-green-400 focus:outline-none focus:border-green-400 focus:ring-gray-400 block w-full rounded-md sm:text-base focus:ring-0 mb-1"
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="mb-5">
                    <ErrorMessage name="Category">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                    <ErrorMessage name="Status">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>

                    <ErrorMessage name="From">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                    <ErrorMessage name="To">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>

                  {/* Section 1 */}
                  <div className="bg-white shadow-md border border-[#E4E4E7]/50 rounded-md px-3 pt-2 pb-5 flex">
                    {!loading ? (
                      <>
                        {fReports && (
                          <TableSort
                            data={rowData}
                            fields={finalTableFields}
                            category={"fleetUnits"}
                          />
                        )}

                        {/* <div className="px-3 py-2 mt-3 flex justify-end items-center font-medium">
                          <div
                            className="text-sm mr-3"
                            style={{ fontSize: "12px" }}
                          >
                            Showing {startRange ? startRange : 0} to{" "}
                            {endRange ? endRange : 0} of {totalCount} entries
                          </div>
                          <div className="">
                            <Pagination
                              value={activePage}
                              onChange={setPage}
                              total={totalPages}
                              size="sm"
                              position="right"
                              withEdges
                              styles={(theme) => ({
                                fontFamily: "Outfit",
                                fontSize: "12px",
                                control: {
                                  "&[data-active]": {
                                    backgroundImage: theme.fn.gradient({
                                      from: "#C1BCCD",
                                      to: "#C1BCCD",
                                    }),
                                    border: 0,
                                  },
                                },
                              })}
                            />
                          </div>
                        </div> */}
                      </>
                    ) : (
                      <div className="w-full flex justify-center">
                        <div
                          style={{ minHeight: "20vh" }}
                          className="flex items-center justify-center"
                        >
                          <div className="flex justify-center px-5 py-5">
                            <MoonLoader color="#4CCEAC" size={50} />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </main>
              </Form>
            </>
          );
        }}
      </Formik>
    </Layout>
  );
};

export default Reports;
