import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import { C } from "../../common";
import toast from "react-hot-toast";
import { useState } from "react";

import {
  addRoleValidationSchema,
  addPermitClassValidationSchema,
  addDocumentCategoryValidationSchema,
  addInsuranceProviderValidationSchema,
  addVehicleMakeValidationSchema,
  addVehicleTypeValidationSchema,
} from "../../helpers/validators";

export function AddSettingItemForm({ entityType, onCancel }) {
  const [loading, setLoading] = useState(false);

  const CONFIG = {
    Roles: {
      endpoint: `${C.URLS.BASE_URL}/api/Roles`,
      validationSchema: addRoleValidationSchema,
      placeholder: "Enter role name",
    },
    VehicleMakes: {
      endpoint: `${C.URLS.BASE_URL}/api/VehicleTypeMakes`,
      validationSchema: addVehicleMakeValidationSchema,
      placeholder: "Enter vehicle make name",
      typeMakeModelStatus: "MAKEMODEL",
    },
    VehicleTypes: {
      endpoint: `${C.URLS.BASE_URL}/api/VehicleTypeMakes`,
      validationSchema: addVehicleTypeValidationSchema,
      placeholder: "Enter vehicle type name",
      typeMakeModelStatus: "TYPE",
    },
    PermitClasses: {
      endpoint: `${C.URLS.BASE_URL}/api/PermitClasses`,
      validationSchema: addPermitClassValidationSchema,
      placeholder: "Enter permit class name",
    },
    DocumentCategories: {
      endpoint: `${C.URLS.BASE_URL}/api/DocumentCategories`,
      validationSchema: addDocumentCategoryValidationSchema,
      placeholder: "Enter document category name",
    },
    InsuranceProviders: {
      endpoint: `${C.URLS.BASE_URL}/api/Insurances/providers`,
      validationSchema: addInsuranceProviderValidationSchema,
      placeholder: "Enter provider name",
    },
  };

  const currentConfig = CONFIG[entityType];

  return (
    <Formik
      initialValues={{
        name: "",
        status: "Is_Active",
        contact: "",
        typeMakeModelStatus: currentConfig.typeMakeModelStatus,
        isEditable: true,
      }}
      validationSchema={currentConfig.validationSchema}
      enableReinitialize
      onSubmit={async (values, { resetForm }) => {
        setLoading(true);
        try {
          const response = await axios.post(currentConfig.endpoint, {
            ...values,
          });

          const responseData = response.data;

          if (responseData.code === "BadRequest")
            toast.error(responseData.message, { position: "bottom-center" });
          else if (response.status === 200 || response.status === 201) {
            toast.success(responseData.message, { position: "bottom-center" });
          } else {
            toast.error("An error occurred while processing your request.", {
              position: "bottom-center",
            });
          }
          setLoading(false);
          resetForm();
        } catch (error) {
          toast.error("An unexpected error occurred. Please try again.", {
            position: "bottom-center",
          });
          console.error("Error:", error);
        }
      }}
    >
      {({ values, handleChange, handleBlur, errors, touched }) => {
        return (
          <>
            <Form className="h-full overflow-y-scroll pt-20 px-4 md:px-10 bg-white">
              <main>
                <label className="block mb-5">
                  <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base font-medium text-slate-700">
                    Name
                  </span>
                  <div className="">
                    <input
                      type="text"
                      name="name"
                      placeholder={currentConfig.placeholder}
                      onChange={handleChange("name")}
                      onBlur={handleBlur("name")}
                      value={values.name}
                      className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors &&
                        errors?.lastName &&
                        touched &&
                        touched?.lastName
                        ? "border-red-400"
                        : "border-slate-400"
                        }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-sm focus:ring-1 mb-1`}
                    />
                    <ErrorMessage name="name">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </label>

                {entityType === "InsuranceProviders" && (
                  <label className="block mb-5 text-base">
                    <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base font-medium text-slate-700">
                      Provider contact
                    </span>
                    <div className="">
                      <input
                        type="text"
                        name="contact"
                        placeholder="Contact e.g 256702481437"
                        onChange={handleChange("contact")}
                        onBlur={handleBlur("contact")}
                        value={values.contact}
                        className={`text-base mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors &&
                          errors?.lastName &&
                          touched &&
                          touched?.lastName
                          ? "border-red-400"
                          : "border-slate-400"
                          }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-sm focus:ring-1 mb-1`}
                      />
                      <ErrorMessage name="contact">
                        {(msg) => (
                          <div className="text-xs text-red-500 text-left w-full">
                            <p>{msg}</p>
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                  </label>
                )}

                <div className="flex justify-end gap-3 md:gap-2 mt-4">
                  <button
                    type="button"
                    className="text-base border-neutral-600 border-[1px] cursor-pointer text-neutral-600 py-1 px-4 my-2 rounded-md"
                    onClick={onCancel}
                  >
                    Cancel
                  </button>
                  <input
                    type="submit"
                    value={loading ? "Adding..." : "Add"}
                    className="bg-[#23A74C] text-base border-[#23A74C] border-2 text-white py-1 px-4 my-2 rounded-md cursor-pointer"
                  />
                </div>
              </main>
            </Form>
          </>
        );
      }}
    </Formik>
  );
}
