// import { BiSolidChevronDown } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import { menuData } from "../menu/menuData";
import { useAuth } from "../../helpers/auth/AuthContext";
import { useEffect, useState } from "react";
// import { EditUser } from "../EditUser";
// import FleetDrawer from "../FleetDrawer";
import { useDisclosure } from '@mantine/hooks';
import axios from "axios";
import { C } from "../../common"

// import {
//   FaCaretDown,
//   FaAngleDown,
// } from 'react-icons/fa';

export function Navbar() {
  const location = useLocation();
  const { user } = useAuth();
  const [role, setRole] = useState();
  // const [unitName, setUnitName] = useState("ghhfg");
  const [opened, { open: open, close: close }] = useDisclosure(false);

  useEffect(() => {
    const fetchRoles = async (id) => {
      try {
        axios
          .get(`${C.URLS.BASE_URL}/api/Roles`)
          .then((res) =>
            setRole(
              res.data &&
              res.data.data &&
              res.data.data.collection.filter((role) => role.id === id)
            )
          )
          .catch((error) => console.log(error));
      } catch (error) {
        console.error("Network Error:", error.message);
      }
    };

    // const getUserUnit = async () => {
    //   try {
    //     const unitsResponse = await axios.get(`${C.URLS.BASE_URL}/api/Units?UserId=${user.id}`);

    //     const userUnits = unitsResponse.data.data.collection[0].userUnits;
    //     if (userUnits.length === 0) {
    //       console.error("No userUnits found for the user");
    //       return;
    //     }

    //     const unitId = userUnits[0].unitId;
    //     const unitName = await axios.get(`${C.URLS.BASE_URL}/Units/${unitId}`);
    //     console.log(unitName);
    //     setUnitName(unitName);
    //   } catch (error) {
    //     console.error("Network Error:", error.message);
    //   }
    // };

    if (user) {
      fetchRoles(user?.roleId);
    }

    // getUserUnit();
  }, []);


  const getPathParts = (pathname) => pathname.split('/').filter(Boolean);
  const currentPathParts = getPathParts(location.pathname);
  // console.log(currentPathParts);

  const heading = (() => {
    if (currentPathParts.length === 0) return '';

    const mainItem = menuData.admin.find(item => item.link.includes(currentPathParts[0]));
    if (!mainItem) {
      return currentPathParts[0].charAt(0).toUpperCase() + currentPathParts[0].slice(1);
    }

    let headerName = mainItem.label;

    if (currentPathParts.length > 1) {
      if (mainItem.links) {
        const subItem = mainItem.links.find(sub => sub.link.includes(currentPathParts[1]));
        if (subItem) {
          headerName += ` > ${subItem.label}`;
        }
      }
      else if (mainItem.link) {
        const subItem = currentPathParts[1];
        console.log(subItem)
        if (subItem) {
          headerName += ` > ${subItem}`;
        }
      }

    }

    return headerName;
  })();

  // const userData = user
  //   ? {
  //     id: user.id,
  //     Name: `${user.firstName} ${user.lastName}`,
  //     Email: user.emailAddress,
  //     Phone: user.phoneNumber,
  //     Role: user.Role,
  //     Status: user.status === 0 ? 'active' : 'inactive'
  //   }
  //   : {};

  return (
    <nav className="w-full h-[77px] flex justify-between items-center px-12 py-7 bg-[#FFF] text-[#515861]">
      <h2 className="font-bold text-lg tracking-tight">{heading}</h2>

      <div className="flex gap-2 items-center cursor-pointer" onClick={() => open()}>
        <div className="font-bold w-8 h-8 rounded-full overflow-hidden bg-[#C1BCCD] text-[#fff] flex justify-center items-center">
          {user && user?.imageUrl ? (
            <img src={`${user?.imageUrl}`} alt="Profile photo" />
          ) : (
            user && <p className="font-medium text-sm">{user.firstName[0]}{user.lastName[0]}</p>
          )}
        </div>
        <p className="text-base font-medium font-dm">{user.userName}</p>
        {/* <FaAngleDown size={12} className="text-[#515861]" /> */}
      </div>


      {/* <FleetDrawer opened={opened} onClose={close}>
        <EditUser
          user={userData}
          onCancel={close}
        />
      </FleetDrawer> */}
    </nav>
  );
}
