import { Formik, Form, ErrorMessage } from "formik";
// import { MdClose } from "react-icons/md";
import axios from "axios";
import { C } from '../common';
import toast from "react-hot-toast";
import { assignUnitAdminValidationSchema } from "../helpers/validators";
import { useEffect, useState } from "react";

import { useNavigate, useParams } from 'react-router-dom';
import { Layout } from "./Layout";

import Select from 'react-select';


import {
    FaCaretLeft,
    FaSave,
} from 'react-icons/fa';

export function EditUnit() {
    const [unitUsers, setUnitUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentUnit, setCurrentUnit] = useState({});

    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        getUnit();
        fetchUnitUsers();
    }, []);

    const getUnit = async () => {
        setLoading(true);
        try {
            await axios
                .get(`${C.URLS.BASE_URL}/api/Units/${id}`)
                .then((res) => setCurrentUnit(res.data));
        } catch (error) {
            console.error("Network Error:", error.message);
        }
        setLoading(false);
    };

    const fetchUnitUsers = async () => {
        try {
            const allUnitUsers = [];
            let currentPage = 1;

            while (true) {
                // const response = await axios.get(`${C.URLS.BASE_URL}/api/VehicleTypeMakes?PageNumber=${currentPage}`);
                const response = await axios.get(`${C.URLS.BASE_URL}/api/Users?PageNumber=${currentPage}`);
                const unitsUsersData = response.data.data.collection;

                if (unitsUsersData.length === 0) {
                    break;
                }

                allUnitUsers.push(...unitsUsersData);
                currentPage++;
            }

            setUnitUsers(allUnitUsers);
        } catch (error) {
            console.error(error.message);
        }
    };

    const isObjectEmpty = (obj) => {
        return Object.entries(obj).length === 0;
    }

    const handleBackClick = () => {
        navigate(`/units`);
    };

    return (
        <Layout>
            <Formik
                initialValues={{
                    unitId: currentUnit.data ? currentUnit.data.id : "",
                    // id: currentUnit.data ? currentUnit.data.id : "",
                    // name: currentUnit.data ? currentUnit.data.name : "",
                    // location: currentUnit.data ? currentUnit.data.location : "",
                }}
                enableReinitialize
                onSubmit={async (values, { resetForm }) => {
                    setLoading(true);
                    try {
                        const response = await axios
                            // .put(`${C.URLS.BASE_URL}/api/Drivers/${id}`, {
                            .put(`${C.URLS.BASE_URL}/api/Units/${values.unitId}/assign/${values.userId}/admin`, {
                                ...values,
                            });
                        const responseData = response.data;

                        if (responseData.code === 'BadRequest') toast.error(responseData.message, { position: "bottom-center" });
                        else if (response.status === 200 || response.status === 201) {
                            toast.success(responseData.message, { position: "bottom-center" });
                            setLoading(false);
                        } else {
                            toast.error("An error occurred while processing your request.", { position: "bottom-center" });
                        }

                        setLoading(false);
                    } catch (error) { }
                }}

            >
                {({ values, setFieldValue, handleChange, handleBlur, errors, touched }) => {
                    return (
                        <>
                            {/* <Form className="h-full overflow-y-scroll pt-10 px-4 md:px-10 bg-white"> */}
                            <Form className="h-full overflow-y-scroll px-4 md:px-10">
                                <main>

                                    <div className="flex justify-between items-center gap-3 md:gap-5 mb-2">
                                        <button
                                            className="px-4 py-2 my-2 font-medium bg-white border rounded-md text-[#23A74C] flex items-center text-base"
                                            onClick={handleBackClick}
                                        >
                                            <span className="mr-2"><FaCaretLeft size={14} /></span>
                                            <span className="text-base-extra">View all units</span>
                                        </button>

                                        <div className="flex gap-3 md:gap-5">
                                            <button
                                                type="submit"
                                                className="px-4 py-2 my-2 font-medium bg-[#23A74C] text-sm text-white border rounded-md flex items-center text-base"
                                            >
                                                <span className="mr-2"><FaSave size={14} /></span>
                                                <span className="text-base-extra">{loading ? "Updating unit..." : "Update unit"}</span>
                                            </button>
                                        </div>
                                    </div>

                                    {/* Section 1 */}
                                    <div className="bg-white shadow-md border border-[#E4E4E7]/50 rounded-md px-10 py-10 flex">
                                        {/* Left Column */}
                                        <div className="w-1/2 pr-5">

                                            <label className="block mb-5 w-full">
                                                <span className="block text-base font-semibold text-primary-color">
                                                    Name
                                                </span>
                                                <div className="">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        readOnly
                                                        placeholder="Unit name"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        // value={values.name}
                                                        value={`${!isObjectEmpty(currentUnit) ? currentUnit.data.name : ""}`}
                                                        className={`mt-1 px-3 py-2 bg-gray-50 border border-gray-200 focus:outline-none focus:border-gray-200 focus:ring-gray-400 block w-full rounded-md sm:text-base focus:ring-0 mb-1 text-gray-500`}
                                                    // className={`mt-1 px-3 py-2 bg-gray-50 border shadow-sm capitalize border-gray-400 focus:outline-none focus:border-gray-400 focus:ring-gray-400 block w-full rounded-md sm:text-sm focus:ring-0 mb-1 text-gray-500`}
                                                    />
                                                </div>
                                            </label>

                                            <label className="block mb-5 w-full">
                                                <span className="block text-base font-semibold text-primary-color">
                                                    Unit Location
                                                </span>
                                                <div className="">
                                                    <input
                                                        type="text"
                                                        name="location"
                                                        readOnly
                                                        placeholder="Unit Location"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        // value={values.location}
                                                        value={`${!isObjectEmpty(currentUnit) ? currentUnit.data.location : ""}`}
                                                        className={`mt-1 px-3 py-2 bg-gray-50 border border-gray-200 focus:outline-none focus:border-gray-200 focus:ring-gray-400 block w-full rounded-md sm:text-base focus:ring-0 mb-1 text-gray-500`}
                                                    // className={`mt-1 px-3 py-2 bg-gray-50 border shadow-sm capitalize border-gray-400 focus:outline-none focus:border-gray-400 focus:ring-gray-400 block w-full rounded-md sm:text-sm focus:ring-0 mb-1 text-gray-500`}
                                                    />
                                                </div>
                                            </label>

                                        </div>


                                        {/* Right Column */}
                                        <div className="w-1/2 pl-5">

                                            {/* Unit administrators */}
                                            <label className="block mb-5">
                                                <span className="block text-base font-semibold text-primary-color">
                                                    Unit Administrator
                                                </span>
                                                <div className="">
                                                    <Select
                                                        isSearchable
                                                        name="userId"
                                                        value={unitUsers
                                                            .filter((user) => user.id === values.userId)
                                                            .map((user) => ({ value: user.id, label: `${user.firstName} ${user.lastName}` }))[0] // Make sure it’s an object.
                                                        }
                                                        onChange={option => setFieldValue('userId', option ? option.value : '')}
                                                        options={unitUsers.map(user => ({ value: user.id, label: `${user.firstName} ${user.lastName}` }))}
                                                        
                                                        className={`mt-1 bg-gray-50  block w-full rounded-md sm:text-base focus:ring-0 mb-1 `}
                                                        classNamePrefix="select"

                                                        styles={{
                                                            control: (provided, state) => ({
                                                                ...provided,
                                                                borderColor: state.isFocused
                                                                    ? 'rgba(76, 206, 172, 0.4)'  
                                                                    : 'rgba(76, 206, 172, 0.7)',  
                                                                boxShadow: state.isFocused ? '0 0 0 1px rgba(76, 206, 172, 0)' : '', 
                                                                '&:hover': {
                                                                    borderColor: 'rgba(76, 206, 172, 0.4)' 
                                                                }
                                                            }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                backgroundColor: state.isSelected
                                                                    ? '#23A74C' 
                                                                    : state.isFocused
                                                                        ? '#EAF2F1' 
                                                                        : provided.backgroundColor, 
                                                                color: state.isSelected
                                                                    ? '#FFFFFF' 
                                                                    : provided.color,
                                                            })
                                                        }}
                                                    />

                                                    <ErrorMessage name="userId">
                                                        {(msg) => (
                                                            <div className="text-xs text-red-500 text-left w-full">
                                                                <p>{msg}</p>
                                                            </div>
                                                        )}
                                                    </ErrorMessage>
                                                </div>
                                            </label>
                                        </div>

                                    </div>

                                </main>
                            </Form>
                        </>
                    );
                }}
            </Formik>
        </Layout>
    );
}
