import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import { C } from '../../common';
import toast from "react-hot-toast";
import { addGarageValidationSchema } from "../../helpers/validators";

export function AddGarage({ onCancel }) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {

  }, []);

  return (
    <Formik
      initialValues={{
        name: "",
        contact: "",
        location: "",
        email: "",
        status: "",
      }}
      validationSchema={addGarageValidationSchema}
      enableReinitialize

      onSubmit={async (values, { resetForm }) => {
        console.log(values);
        setLoading(true);
        try {
          const response = await axios.post(`${C.URLS.BASE_URL}/api/Garages`, {
            ...values,
          });

          const responseData = response.data;

          if (responseData.code === 'BadRequest') toast.error(responseData.message, { position: "bottom-center" });
          else if (response.status === 200 || response.status === 201) {
            toast.success(responseData.message, { position: "bottom-center" });
            setLoading(false);
            resetForm();
          } else {
            toast.error("An error occurred while processing your request.", { position: "bottom-center" });
          }

          setLoading(false);
          resetForm();
        } catch (error) {
          toast.error(`${error.message}`, { position: "bottom-center" });
          setLoading(false);
        }
      }}
    >
      {({ values, handleChange, handleBlur, errors, touched, handleSubmit }) => (
        <Form className="h-full overflow-y-scroll pt-10 px-4 md:px-10 bg-white" onSubmit={handleSubmit}>
          <main>
            <label className="block mb-5">
              <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                Garage Name
              </span>
              <div className="">
                <input
                  type="text"
                  name=""
                  placeholder="Enter Garage name"
                  onChange={handleChange("name")}
                  onBlur={handleBlur("name")}
                  value={values.name}
                  className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors &&
                    errors?.name &&
                    touched &&
                    touched?.name
                    ? "border-red-400"
                    : "border-slate-400"
                    }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-sm focus:ring-1 mb-1`}
                />
                <ErrorMessage name="name">
                  {(msg) => (
                    <div className="text-xs text-red-500 text-left w-full">
                      <p>{msg}</p>
                    </div>
                  )}
                </ErrorMessage>
              </div>
            </label>

            <label className="block mb-5">
              <span className="block text-sm font-medium text-slate-700">
                Contact
              </span>
              <div className="">
                <input
                  type="text"
                  name="contact"
                  placeholder="Enter Garage Contact"
                  onChange={handleChange("contact")}
                  onBlur={handleBlur("contact")}
                  value={values.contact}
                  className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-400 placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-sm focus:ring-1 mb-1"
                />
              </div>
            </label>

            <label className="block mb-5">
              <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                Location
              </span>
              <div className="">
                <input
                  type="text"
                  name="location"
                  placeholder="Enter Garage Location"
                  onChange={handleChange("location")}
                  onBlur={handleBlur("location")}
                  value={values.lastName}
                  className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors &&
                    errors?.location &&
                    touched &&
                    touched?.location
                    ? "border-red-400"
                    : "border-slate-400"
                    }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-sm focus:ring-1 mb-1`}
                />
                <ErrorMessage name="location">
                  {(msg) => (
                    <div className="text-xs text-red-500 text-left w-full">
                      <p>{msg}</p>
                    </div>
                  )}
                </ErrorMessage>
              </div>
            </label>

            <label className="block mb-5">
              <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                Email Address
              </span>
              <div className="">
                <input
                  type="text"
                  name="email"
                  placeholder="Enter Garage's Email"
                  onChange={handleChange("email")}
                  onBlur={handleBlur("email")}
                  value={values.email}
                  className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors &&
                    errors?.emailAddress &&
                    touched &&
                    touched?.emailAddress
                    ? "border-red-400"
                    : "border-slate-400"
                    }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-sm focus:ring-1 mb-1`}
                />
                <ErrorMessage name="email">
                  {(msg) => (
                    <div className="text-xs text-red-500 text-left w-full">
                      <p>{msg}</p>
                    </div>
                  )}
                </ErrorMessage>
              </div>
            </label>

            {/* Garage Qualification Status */}
            <label className="block mb-5">
              <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                Is Garage Qualified?
              </span>
              <div className="">
                <select
                  name="status"
                  onChange={handleChange("status")}
                  onBlur={handleBlur("status")}
                  value={values.status}
                  className={`font-jost mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors && errors?.roleId && touched && touched?.roleId
                    ? "border-red-400"
                    : "border-slate-400"
                    }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-sm focus:ring-1 mb-1`}
                >
                  <option value="">-- Select Qualification Status --</option>
                  <option value="QUALIFIED">Qualified</option>
                  <option value="NOT_QUALIFIED">Inqualified</option>
                </select>
                <ErrorMessage name="status">
                  {(msg) => (
                    <div className="text-xs text-red-500 text-left w-full">
                      <p>{msg}</p>
                    </div>
                  )}
                </ErrorMessage>
              </div>
            </label>


            <div className="flex justify-end gap-3 md:gap-5 mt-4">
              <button
                type="button"
                className="text-sm text-[#23A74C] border-[#23A74C] border cursor-pointer text-neutral-600 py-1 px-4 my-2 rounded-md"
                onClick={onCancel}
              >
                Cancel
              </button>
              <input
                type="submit"
                value={loading ? "Adding Garage..." : "Add Garage"}
                className="bg-[#23A74C] text-sm border-[#23A74C] border-2 text-white py-1 px-4 my-2 rounded-md cursor-pointer"
              />
            </div>
          </main>
        </Form>
      )}
    </Formik>
  );
}