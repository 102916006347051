import { Navigate } from "react-router-dom";
import { useAuth } from "../helpers/auth/AuthContext";

const PrivateRoutes = ({ children }) => {
  const { user } = useAuth();

  return user ? <>{children}</> : <Navigate to="/login" />;
}

export default PrivateRoutes;
