import { Layout, Paginate } from "../../components";
import { useAuth } from "../../helpers/auth/AuthContext";
import FleetDrawer from "../../components/FleetDrawer";
import { useEffect, useState } from "react";
import axios from "axios";
import { C } from "../../common";
import { Helmet } from "react-helmet";
import { useRef } from "react";

import { AddSettingItemForm } from "../../components";

import { useDisclosure } from "@mantine/hooks";
import { Pagination } from "@mantine/core";
import { TableSort } from "../../components/TableSort";
import { MoonLoader } from "react-spinners";

import { IconCirclePlus } from "@tabler/icons-react";

import { FaUserTag, FaTruckMoving, FaFileAlt, FaShieldAlt, FaCar, FaClipboard } from "react-icons/fa";

const Settings = () => {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("roles");
  const [activePage, setPage] = useState(1);

  const [openedRoleDrawer, { open: openRoleDrawer, close: closeRoleDrawer }] =
    useDisclosure(false);
  const [openedVTypeDrawer, { open: openVTypeDrawer, close: closeVTypeDrawer }] =
    useDisclosure(false);
  const [openedVMakeDrawer, { open: openVMakeDrawer, close: closeVMakeDrawer }] =
    useDisclosure(false);
  const [
    openedPermitClassDrawer,
    { open: openPermitClassDrawer, close: closePermitClassDrawer },
  ] = useDisclosure(false);
  const [
    openedDocumentCategoryDrawer,
    { open: openDocumentCategoryDrawer, close: closeDocumentCategoryDrawer },
  ] = useDisclosure(false);
  const [
    openedInsuranceProviderDrawer,
    { open: openInsuranceProviderDrawer, close: closeInsuranceProviderDrawer },
  ] = useDisclosure(false);

  // const [roles, setRoles] = useState([]);
  // const { user } = useAuth();
  const ref = useRef();

  const [rolesData, setRoles] = useState([]);
  const [vehicleTypesData, setVehicleTypesData] = useState([]);
  const [vehicleMakesData, setVehicleMakesData] = useState([]);
  const [permitsData, setPermits] = useState([]);
  const [documentCategoriesData, setDocumentCategories] = useState([]);
  const [insProvidersData, setInsProvidersData] = useState([]);

  // const [searchText, setSearchText] = useState("");
  // const [sortAsc, setSortAsc] = useState(false);
  // const [rolesActivePage, setRolesPage] = useState(1);

  // console.log(vehicleMakesData);
  // console.log(activePage);

  useEffect(() => {
    // console.log('Tab changes..');
    setPage(1);
  }, [activeTab]);

  useEffect(() => {
    // console.log('Making requests..');
    fetchRoles();
    fetchPermits();
    fetchDocumentCategories();
    fetchInsuranceProviders();
    fetchVehicleMakes();
    fetchVehicleTypes();
  }, [activePage]);

  const fetchRoles = async () => {
    setLoading(true);
    try {
      await axios
        .get(`${C.URLS.BASE_URL}/api/Roles?PageNumber=${activePage}`)
        .then((res) => setRoles(res.data.data));
    } catch (error) {
      console.error("Network Error:", error.message);
    }
    setLoading(false);
  };

  const fetchVehicleTypes = async () => {
    setLoading(true);
    try {
      await axios
        .get(`${C.URLS.BASE_URL}/api/VehicleTypeMakes?TypeMakeModelStatus=TYPE&PageNumber=${activePage}`)
        .then((res) => setVehicleTypesData(res.data.data));
    } catch (error) {
      console.error("Network Error:", error.message);
    }
    setLoading(false);
  };

  const fetchVehicleMakes = async () => {
    setLoading(true);
    try {
      await axios
        .get(`${C.URLS.BASE_URL}/api/VehicleTypeMakes?TypeMakeModelStatus=MAKEMODEL&PageNumber=${activePage}`)
        .then((res) => setVehicleMakesData(res.data.data));
    } catch (error) {
      console.error("Network Error:", error.message);
    }
    setLoading(false);
  };

  // console.log(vehicleMakesData);
  // console.log(vehicleTypesData);

  const fetchPermits = async () => {
    try {
      await axios
        .get(`${C.URLS.BASE_URL}/api/PermitClasses?PageNumber=${activePage}`)
        .then((res) => setPermits(res.data.data));
    } catch (error) {
      console.error("Network Error:", error.message);
    }
  };

  const fetchDocumentCategories = async () => {
    try {
      await axios
        .get(`${C.URLS.BASE_URL}/api/DocumentCategories?PageNumber=${activePage}`)
        .then((res) => setDocumentCategories(res.data.data));
    } catch (error) {
      console.error("Network Error:", error.message);
    }
  };

  const fetchInsuranceProviders = async () => {
    try {
      await axios
        .get(`${C.URLS.BASE_URL}/api/Insurances/providers?PageNumber=${activePage}`)
        .then((res) => setInsProvidersData(res.data.data));
    } catch (error) {
      console.error("Network Error:", error.message);
    }
  };

  const formatAndAdjustTimezone = (dateString) => {
    const utcDate = new Date(dateString);
    utcDate.setHours(utcDate.getHours() + 3);

    const formattedDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "UTC",
    }).format(utcDate);

    return formattedDate;
  };

  //  console.log(insProvidersData);

  const providerStatusMapping = {
    'Is_Active': 'Active',
    'Is_Inactive': 'Inactive',
    'Is_Delete': 'Deleted',
    'Is_Blocked': 'Blocked',
  };

  const {
    currentPage,
    pageSize: rolesPageSize,
    totalCount: rolesTotalCount,
    totalPages,
    collection: roles,
    previousPage,
    nextPage,
  } = rolesData;

  const {
    currentPage: vehicleMakesCurrentPage,
    pageSize: vehicleMakesPageSize,
    totalCount: vehicleMakesTotalCount,
    totalPages: vehicleMakesPages,
    collection: vehicleMakes,
    previousPage: vehicleMakesPreviousPage,
    nextPage: vehicleMakesNextPage,
  } = vehicleMakesData;

  const {
    currentPage: vehicleTypesCurrentPage,
    pageSize: vehicleTypesPageSize,
    totalCount: vehicleTypesTotalCount,
    totalPages: vehicleTypesPages,
    collection: vehicleTypes,
    previousPage: vehicleTypesPreviousPage,
    nextPage: vehicleTypesNextPage,
  } = vehicleTypesData;

  const {
    currentPage: permitsCurrentPage,
    pageSize: permitsPageSize,
    totalCount: permitsTotalCount,
    totalPages: permitsPages,
    collection: permits,
    previousPage: permitsPreviousPage,
    nextPage: permitsNextPage,
  } = permitsData;

  const {
    currentPage: documentCategoriesCurrentPage,
    pageSize: documentCategoriesPageSize,
    totalCount: documentCategoriesTotalCount,
    totalPages: documentCategoriesPages,
    collection: documentCategories,
    previousPage: documentCategoriesPreviousPage,
    nextPage: documentCategoriesNextPage,
  } = documentCategoriesData;

  const {
    currentPage: insProvidersCurrentPage,
    pageSize: insProvidersPageSize,
    totalCount: insProvidersTotalCount,
    totalPages: insProvidersPages,
    collection: insProviders,
    previousPage: insProvidersPreviousPage,
    nextPage: insProvidersNextPage,
  } = insProvidersData;

  const tableRolesFields = ["Name", "Description", "Creation Date", "Action"];
  const tablePermitsFields = ["Name", "Description", "Creation Date", "Action"];
  const tableDocumentCategoriesFields = ["Name", "Description", "Creation Date", "Action"];
  const insProvidersFields = ["Name", "Contact", "Creation Date", "Status"];
  const tableVehicleTypesFields = ["Name", "Description", "Creation Date", "Action"];
  const tableVehicleMakesFields = ["Name", "Description", "Creation Date", "Action"];

  const rolesRowData =
    rolesData.collection?.map((role) => ({
      id: role.id,
      Name: `${role.name}`,
      Description: "",
      "Creation Date": formatAndAdjustTimezone(role.createdOn),
    })) || [];

  const vehicleTypesRowData =
    vehicleTypesData.collection?.map((vType) => ({
      id: vType.id,
      Name: `${vType.name}`,
      Description: "",
      "Creation Date": formatAndAdjustTimezone(vType.createdOn),
    })) || [];

  const vehicleMakesRowData =
    vehicleMakesData.collection?.map((vMake) => ({
      id: vMake.id,
      Name: `${vMake.name}`,
      Description: "",
      "Creation Date": formatAndAdjustTimezone(vMake.createdOn),
    })) || [];

  const permitsRowData =
    permitsData.collection?.map((permit) => ({
      id: permit.id,
      Name: `${permit.name}`,
      Description: "",
      "Creation Date": formatAndAdjustTimezone(permit.createdOn),
    })) || [];

  const documentCategoriesRowData =
    documentCategoriesData.collection?.map((category) => ({
      id: category.id,
      Name: `${category.name}`,
      Description: "",
      "Creation Date": formatAndAdjustTimezone(category.createdOn),
    })) || [];

  const insProvidersRowData =
    insProvidersData.collection?.map((provider) => ({
      id: provider.id,
      Name: `${provider.name}`,
      Contact: `${provider.contact}`,
      Status: `${providerStatusMapping[provider.status] || provider.status}`,
      "Creation Date": formatAndAdjustTimezone(provider.createdOn),
    })) || [];

  const calculatePaginationRanges = (currentPage, pageSize, totalCount) => {
    if (totalCount === 0) {
      return { startRange: 0, endRange: 0 };
    }
    const startRange = (currentPage - 1) * pageSize + 1;
    const endRange = Math.min(startRange + pageSize - 1, totalCount);
    return { startRange, endRange };
  };

  const getButtonDetailsForTab = (activeTab) => {
    // setActiveTab(1);
    switch (activeTab) {
      case "roles":
        return {
          text: "New role",
          onClick: () => openRoleDrawer(),
        };
      case "vTypes":
        return {
          text: "New vehicle type",
          onClick: () => openVTypeDrawer(),
        };
      case "vMakes":
        return {
          text: "New vehicle make",
          onClick: () => openVMakeDrawer(),
        };
      case "permits":
        return {
          text: "New permit class",
          onClick: () => openPermitClassDrawer(),
        };
      case "categories":
        return {
          text: "New document category",
          onClick: () => openDocumentCategoryDrawer(),
        };
      case "insProviders":
        return {
          text: "New insurance provider",
          onClick: () => openInsuranceProviderDrawer(),
        };
      default:
        return {
          text: "Add",
          onClick: () => { },
        };
    }
  };

  const renderTable = (data, fields, category) => {
    if (loading) {
      return (
        <div
          style={{ minHeight: "50vh" }}
          className="flex items-center justify-center"
        >
          <MoonLoader color="#4CCEAC" size={50} />
        </div>
      );
    }

    if (data) {
      return data.length > 0 ? (
        <TableSort data={data} fields={fields} category={category} />
      ) : (
        <TableSort data={[]} fields={fields} category={category} />
      );
    }

    return null;
  };

  // const { rolesStartRange, rolesEndRange } = calculatePaginationRanges(rolesCurrentPage, rolesPageSize, rolesTotalCount);
  const { startRange, endRange } = calculatePaginationRanges(
    activePage,
    rolesPageSize,
    rolesTotalCount
  );

  const { startRange: vTypeStartRange, endRange: vTypeEndRange } =
    calculatePaginationRanges(activePage, vehicleTypesPageSize, vehicleTypesTotalCount);

  const { startRange: vMakeStartRange, endRange: vMakeEndRange } =
    calculatePaginationRanges(activePage, vehicleMakesPageSize, vehicleMakesTotalCount);

  const { startRange: permitsStartRange, endRange: permitsEndRange } =
    calculatePaginationRanges(activePage, permitsPageSize, permitsTotalCount);

  const { startRange: categoriesStartRange, endRange: categoriesEndRange } =
    calculatePaginationRanges(
      activePage,
      documentCategoriesPageSize,
      documentCategoriesTotalCount
    );

  const { startRange: insProviderStartRange, endRange: insProvidersEndRange } =
    calculatePaginationRanges(
      activePage,
      insProvidersPageSize,
      insProvidersTotalCount
    );

  const { text, onClick } = getButtonDetailsForTab(activeTab);

  return (
    <Layout>
      <Helmet>
        <title>Settings - Mak Fleet</title>
      </Helmet>

      <div className="flex justify-between">
        <div className="flex">
          <button
            onClick={() => setActiveTab("roles")}
            className={`rounded-l-md rounded-r-none flex gap-2 items-center font-medium text-base py-2 px-4 ${activeTab === "roles"
              ? "bg-[#C1BCCD] text-white"
              : "bg-gray-100 border"
              }`}
          >
            <FaUserTag size={14} />
            Roles
          </button>
          <button
            onClick={() => setActiveTab("vTypes")}
            className={`flex gap-2 items-center font-medium text-base py-2 px-4 ${activeTab === "vTypes"
              ? "bg-[#C1BCCD] text-white"
              : "bg-gray-100 border"
              }`}
          >
            <FaTruckMoving size={14} />
            Vehicle Types
          </button>
          <button
            onClick={() => setActiveTab("vMakes")}
            className={`flex gap-2 items-center font-medium text-base py-2 px-4 ${activeTab === "vMakes"
              ? "bg-[#C1BCCD] text-white"
              : "bg-gray-100 border"
              }`}
          >
            <FaCar size={14} />
            Vehicle Makes
          </button>
          <button
            onClick={() => setActiveTab("permits")}
            className={`flex gap-2 items-center font-medium text-base py-2 px-4 ${activeTab === "permits"
              ? "bg-[#C1BCCD] text-white"
              : "bg-gray-100 border"
              }`}
          >
            <FaClipboard size={14} />
            Permit Classes
          </button>
          <button
            onClick={() => setActiveTab("categories")}
            className={`flex gap-2 items-center font-medium text-base py-2 px-4 ${activeTab === "categories"
              ? "bg-[#C1BCCD] text-white"
              : "bg-gray-100 border"
              }`}
          >
            <FaFileAlt size={14} />
            Document Categories
          </button>
          <button
            onClick={() => setActiveTab("insProviders")}
            className={`rounded-r-md rounded-l-none flex gap-2 items-center font-medium text-base py-2 px-4 ${activeTab === "insProviders"
              ? "bg-[#C1BCCD] text-white"
              : "bg-gray-100 border"
              }`}
          >
            <FaShieldAlt size={14} />
            Insurance providers
          </button>
        </div>

        <div className="flex font-medium text-sm text-[#434C50]">
          <button
            className="px-5 py-2 bg-[#23A74C] rounded-md font-small text-white flex items-center text-base"
            onClick={onClick}
          >
            <span className="mr-2">
              <IconCirclePlus size={18} />
            </span>
            <span className="text-base">{text}</span>
          </button>
        </div>
      </div>

      {activeTab === "roles" && (
        <>
          {renderTable(rolesRowData, tableRolesFields, "fleetRoles")}

          <div className="px-3 py-2 mt-3 flex justify-end items-center font-medium">
            <div className="text-sm mr-3" style={{ fontSize: "12px" }}>
              Showing {startRange ? startRange : 0} to {endRange ? endRange : 0}{" "}
              of {rolesTotalCount} entries
            </div>
            <div className="">
              <Pagination
                value={activePage}
                onChange={setPage}
                total={totalPages}
                size="sm"
                position="right"
                withEdges
                styles={(theme) => ({
                  fontFamily: "Outfit",
                  fontSize: "12px",
                  control: {
                    "&[data-active]": {
                      backgroundImage: theme.fn.gradient({
                        from: "#C1BCCD",
                        to: "#C1BCCD",
                      }),
                      border: 0,
                    },
                  },
                })}
              />
            </div>
          </div>
        </>
      )}

      {activeTab === "vTypes" && (
        <>
          {renderTable(vehicleTypesRowData, tableVehicleTypesFields, "fleetVehicleType")}

          <div className="px-3 py-2 mt-3 flex justify-end items-center font-medium">
            <div className="text-sm mr-3" style={{ fontSize: "12px" }}>
              Showing {vTypeStartRange ? vTypeStartRange : 0} to {vTypeEndRange ? vTypeEndRange : 0}{" "}
              of {vehicleTypesTotalCount} entries
            </div>
            <div className="">
              <Pagination
                value={activePage}
                onChange={setPage}
                total={vehicleTypesPages}
                size="sm"
                position="right"
                withEdges
                styles={(theme) => ({
                  fontFamily: "Outfit",
                  fontSize: "12px",
                  control: {
                    "&[data-active]": {
                      backgroundImage: theme.fn.gradient({
                        from: "#C1BCCD",
                        to: "#C1BCCD",
                      }),
                      border: 0,
                    },
                  },
                })}
              />
            </div>
          </div>
        </>
      )}

      {activeTab === "vMakes" && (
        <>
          {renderTable(vehicleMakesRowData, tableVehicleMakesFields, "fleetVehicleMake")}

          <div className="px-3 py-2 mt-3 flex justify-end items-center font-medium">
            <div className="text-sm mr-3" style={{ fontSize: "12px" }}>
              Showing {vMakeStartRange ? vMakeStartRange : 0} to {vMakeEndRange ? vMakeEndRange : 0}{" "}
              of {vehicleMakesTotalCount} entries
            </div>
            <div className="">
              <Pagination
                value={activePage}
                onChange={setPage}
                total={vehicleMakesPages}
                size="sm"
                position="right"
                withEdges
                styles={(theme) => ({
                  fontFamily: "Outfit",
                  fontSize: "12px",
                  control: {
                    "&[data-active]": {
                      backgroundImage: theme.fn.gradient({
                        from: "#C1BCCD",
                        to: "#C1BCCD",
                      }),
                      border: 0,
                    },
                  },
                })}
              />
            </div>
          </div>
        </>
      )}

      {activeTab === "permits" && (
        <>
          {renderTable(
            permitsRowData,
            tablePermitsFields,
            "fleetPermitClasses"
          )}
          <div className="px-3 py-2 mt-3 flex justify-end items-center font-medium">
            <div className="text-sm mr-3" style={{ fontSize: "12px" }}>
              Showing {permitsStartRange ? permitsStartRange : 0} to{" "}
              {permitsEndRange ? permitsEndRange : 0} of {permitsTotalCount}{" "}
              entries
            </div>
            <div className="">
              <Pagination
                value={activePage}
                onChange={setPage}
                total={permitsPages}
                size="sm"
                position="right"
                withEdges
                styles={(theme) => ({
                  fontFamily: "Outfit",
                  fontSize: "12px",
                  control: {
                    "&[data-active]": {
                      backgroundImage: theme.fn.gradient({
                        from: "#C1BCCD",
                        to: "#C1BCCD",
                      }),
                      border: 0,
                    },
                  },
                })}
              />
            </div>
          </div>
        </>
      )}

      {activeTab === "categories" && (
        <>
          {renderTable(
            documentCategoriesRowData,
            tableDocumentCategoriesFields,
            "fleetDocumentCategories"
          )}

          <div className="px-3 py-2 mt-3 flex justify-end items-center font-medium">
            <div className="text-sm mr-3" style={{ fontSize: "12px" }}>
              Showing {categoriesStartRange ? categoriesStartRange : 0} to{" "}
              {categoriesEndRange ? categoriesEndRange : 0} of{" "}
              {documentCategoriesTotalCount} entries
            </div>
            <div className="">
              <Pagination
                value={activePage}
                onChange={setPage}
                total={documentCategoriesPages}
                size="sm"
                position="right"
                withEdges
                styles={(theme) => ({
                  fontFamily: "Outfit",
                  fontSize: "12px",
                  control: {
                    "&[data-active]": {
                      backgroundImage: theme.fn.gradient({
                        from: "#C1BCCD",
                        to: "#C1BCCD",
                      }),
                      border: 0,
                    },
                  },
                })}
              />
            </div>
          </div>
        </>
      )}

      {activeTab === "insProviders" && (
        <>
          {renderTable(
            insProvidersRowData,
            insProvidersFields,
            "fleetInsuranceProviders"
          )}
          <div className="px-3 py-2 mt-3 flex justify-end items-center font-medium">
            <div className="text-sm mr-3" style={{ fontSize: "12px" }}>
              Showing {insProviderStartRange ? insProviderStartRange : 0} to{" "}
              {insProvidersEndRange ? insProvidersEndRange : 0} of{" "}
              {insProvidersTotalCount} entries
            </div>
            <div className="">
              <Pagination
                value={activePage}
                onChange={setPage}
                total={insProvidersPages}
                size="sm"
                position="right"
                withEdges
                styles={(theme) => ({
                  fontFamily: "Outfit",
                  fontSize: "12px",
                  control: {
                    "&[data-active]": {
                      backgroundImage: theme.fn.gradient({
                        from: "#C1BCCD",
                        to: "#C1BCCD",
                      }),
                      border: 0,
                    },
                  },
                })}
              />
            </div>
          </div>
        </>
      )}

      <FleetDrawer
        opened={openedRoleDrawer}
        onClose={closeRoleDrawer}
        title="New role"
      >
        <AddSettingItemForm entityType="Roles" onCancel={closeRoleDrawer} />
      </FleetDrawer>

      <FleetDrawer
        opened={openedVTypeDrawer}
        onClose={closeVTypeDrawer}
        title="New vehicle type"
      >
        <AddSettingItemForm entityType="VehicleTypes" onCancel={closeVTypeDrawer} />
      </FleetDrawer>

      <FleetDrawer
        opened={openedVMakeDrawer}
        onClose={closeVMakeDrawer}
        title="New vehicle make"
      >
        <AddSettingItemForm entityType="VehicleMakes" onCancel={closeVMakeDrawer} />
      </FleetDrawer>

      <FleetDrawer
        opened={openedPermitClassDrawer}
        onClose={closePermitClassDrawer}
        title="New permit class"
      >
        <AddSettingItemForm
          entityType="PermitClasses"
          onCancel={closePermitClassDrawer}
        />
      </FleetDrawer>

      <FleetDrawer
        opened={openedInsuranceProviderDrawer}
        onClose={closeInsuranceProviderDrawer}
        title="New Insurance Provider"
      >
        <AddSettingItemForm
          entityType="InsuranceProviders"
          onCancel={closeInsuranceProviderDrawer}
        />
      </FleetDrawer>

      <FleetDrawer
        opened={openedDocumentCategoryDrawer}
        onClose={closeDocumentCategoryDrawer}
        title="New document category"
      >
        <AddSettingItemForm
          entityType="DocumentCategories"
          onCancel={closeDocumentCategoryDrawer}
        />
      </FleetDrawer>
    </Layout>
  );
};

export default Settings;
