import { useEffect, useState } from "react";
import { useAuth } from "../../helpers/auth/AuthContext";
import axios from "axios";
import { C } from "../../common";
import { createStyles, Group, Paper, SimpleGrid, Text } from "@mantine/core";

import {
  FaBus,
  FaUserTie,
  FaWrench,
  FaTools,
  FaExclamationTriangle,
} from "react-icons/fa";

import { Layout } from "../../components";
import BarChart from "../../components/ChartComponents/BarChart";
import DoughnutChart from "../../components/ChartComponents/DoughnutChart";
import LineChart from "../../components/ChartComponents/LineChart";
import { Line } from "react-chartjs-2";
import { Pagination, Tabs, rem } from "@mantine/core";
import { TableSort } from "../../components/TableSort";
import { MoonLoader } from "react-spinners";

const useStyles = createStyles((theme) => ({
  flexContainer: {
    display: "flex",
    flex: "0 0 25%",
    flexWrap: "wrap",
    justifyContent: "space-between",
    flexDirection: "BiColumns",
    fontFamily: "DM Sans, sans-serif",
    width: "100%",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 3.5px 5.5px",
  },

  flexContainer2: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    flexDirection: "row",
    fontFamily: "DM Sans, sans-serif",
    width: "100%",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 3.5px 5.5px",
    gap: "15px",
    marginTop: "10px",
  },

  flexItem2: {
    flex: "1",
    minWidth: "calc(50% - 10px);",
  },

  layoutContainer: {
    display: "flex",
    flexDirection: "column", // stack children vertically
    height: "100%", // fill the entire height
  },

  repairContainer: {
    display: "flex",
    // flex: "0 0 50%",
    justifyContent: "center",
    alignItems: "center",
    // flexWrap: "wrap",
    borderRadius: "8px",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 3.5px 5.5px",
    // flex: "0 0 37.5%",
  },

  barChartContainer: {
    // flex: "0 1 calc(50% - 10px)",
    flex: 1,
    marginBottom: "10px",
    // border: '1px solid blue',
  },

  pieChartContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    // border: '1px solid #eee',
  },

  dmFont: {
    fontFamily: "DM Sans, sans-serif",
  },

  item: {
    flex: "0 1 calc(25% - 10px)",
    justifyContent: "space-between",
    alignItems: "center",
    // marginBottom: "10px",
  },

  root: {
    padding: `calc(${theme.spacing.xl} * 1.5)`,
    fontFamily: "DM Sans, sans-serif",
  },

  value: {
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: 1,
    fontFamily: "DM Sans, sans-serif",
  },

  diff: {
    lineHeight: 1,
    display: "flex",
    alignItems: "center",
    fontFamily: "DM Sans, sans-serif",
  },

  icon: {
    color: "#FFFFFF",
    lineHeight: 1,
  },

  title: {
    fontWeight: 900,
    fontSize: "13px",
    // textTransform: "uppercase",
    color: "#515861",
    fontFamily: "DM Sans, sans-serif",
  },
}));

export default function Dashboard() {
  const { classes } = useStyles();
  const [loading, setLoading] = useState(false);
  const [activePage, setPage] = useState(1);

  const { user } = useAuth();
  const [vehiclesCount, setVehiclesCount] = useState(0);
  const [driversCount, setDriversCount] = useState(0);
  const [repairsCount, setRepairsCount] = useState(0);
  const [servicingCount, setServicingCount] = useState(0);
  const [currentUserRole, setCurrentUserRole] = useState("");

  const [approvedData, setApprovedData] = useState([]);
  const pageSize = 2;

  useEffect(() => {
    getUserRole(user?.id);
    getVehiclesCount();
    getDriversCount();
    getRepairedCount();
    getServicingCount();
    // getRequestsData();
    getApprovedData();
  }, [currentUserRole]);

  const getUserRole = async (userID) => {
    try {
      await axios.get(`${C.URLS.BASE_URL}/api/Users/${userID}`).then((res) => {
        setCurrentUserRole(res.data.role.name);
      });
    } catch (error) {
      console.error("Network Error:", error.message);
    }
  };

  // const getRequestsData = () =>
  //   fetchMaintenanceData("NEW_REQUEST", setRequestsData);
  // const getRepairingData = () =>
  //   fetchMaintenanceData("REPAIRING", setRepairingData);
  // const getApprovedData = () =>
  //   fetchMaintenanceData("COMPLETED", setApprovedData);

  const isValidDate = (dateString) => {
    if (dateString === null) return false;

    const date = new Date(dateString);
    return !isNaN(date.getTime()) && date instanceof Date;
  };

  const getApprovedData = () =>
    fetchMaintenanceData("COMPLETED", setApprovedData);

  const fetchMaintenanceData = async (status, setDataFunction) => {
    setLoading(true);

    try {
      const apiUrl = `${C.URLS.BASE_URL}/api/Maintenances?MaintenanceType=REPAIRE&Status=${status}`;

      if (currentUserRole === "super_admin") {
        const response = await axios.get(apiUrl);
        // console.log(status);
        // console.log(response.data.data);
        setDataFunction(response.data.data);
      } else {
        const unitsResponse = await axios.get(
          `${C.URLS.BASE_URL}/api/Units?UserId=${user.id}`
        );
        const userUnits = unitsResponse.data.data.collection[0].userUnits;

        if (!userUnits.length) {
          throw new Error("No userUnits found for the user");
        }

        const unitId = userUnits[0].unitId;
        const maintenanceResponse = await axios.get(
          `${apiUrl}&UnitId=${unitId}`
        );
        setDataFunction(maintenanceResponse.data.data);
      }
    } catch (error) {
      const errorMessage = error.message.includes("userUnits")
        ? error.message
        : "Network Error: " + error.message;
      // console.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const {
    currentPage: cpApprovedRequests,
    // pageSize: psApprovedRequests,
    totalCount: tcApprovedRequests,
    totalPages: tpApprovedRequests,
    collection: cApprovedRequests,
    previousPage: ppApprovedRequests,
    nextPage: npApprovedRequests,
  } = approvedData;

  const completedRequestsRowData =
    cApprovedRequests?.map((repair) => {
      const startDate = isValidDate(repair.startDate)
        ? new Date(repair.startDate)
        : null;
      const endDate = isValidDate(repair.endDate)
        ? new Date(repair.endDate)
        : null;

      let duration = "_";
      if (startDate && endDate) {
        const timeDifference = endDate.getTime() - startDate.getTime();
        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
        duration = daysDifference + " days";
      }

      return {
        id: repair.id,
        "Reg no.": `${repair.vehicle.regNo || "Unknown"}`,
        "Make/Model": `${
          repair.vehicle.vehicleMake ? repair.vehicle.vehicleMake.name : ""
        }`,
        Type: `${
          repair.vehicle.vehicleType ? repair.vehicle.vehicleType.name : ""
        }`,
        Cost: repair.cost.toLocaleString(),
        "Start date": startDate ? startDate.toLocaleDateString() : "_",
        "End date": endDate ? endDate.toLocaleDateString() : "_",
        Duration: duration,
        Status: repair.status,
      };
    }) || [];

  // const calculatePaginationRanges = (currentPage, pageSize, totalCount) => {
  //   if (totalCount === 0) {
  //     return { startRange: 0, endRange: 0 };
  //   }

  //   const startRange = (currentPage - 1) * pageSize + 1;
  //   const endRange = Math.min(startRange + pageSize - 1, totalCount);

  //   // console.log(`CP: ${currentPage}, PS: ${pageSize}, TC: ${totalCount} | SR: ${startRange}, ER: ${endRange}`);
  //   return { startRange, endRange };
  // };

  const baseTableFields2 = [
    "Reg no.",
    // "Type",
    "Cost",
    // "Start date",
    "End date",
    "Duration",
    // "Status",
  ];
  // const tableFields2 =
  //   currentUserRole === "super_admin"
  //     ? [...baseTableFields2, "Action"]
  //     : baseTableFields2;

  const getVehiclesCount = async () => {
    // setLoading(true);
    try {
      if (currentUserRole === "super_admin") {
        const response = await axios.get(`${C.URLS.BASE_URL}/api/Vehicles`);
        setVehiclesCount(response.data.data.totalCount);
      } else {
        const unitsResponse = await axios.get(
          `${C.URLS.BASE_URL}/api/Units?UserId=${user.id}`
        );
        const userUnits = unitsResponse.data.data.collection;
        if (userUnits.length === 0) {
          // console.error("No userUnits found for the user");
          return;
        }
        const unitId = userUnits[0].id;
        const vehiclesResponse = await axios.get(
          `${C.URLS.BASE_URL}/api/Vehicles?UnitId=${unitId}`
        );
        setVehiclesCount(vehiclesResponse.data.data.totalCount);
      }
    } catch (error) {
      console.error("Network Error:", error.message);
    } finally {
      // setLoading(false);
    }
  };

  const getDriversCount = async () => {
    // setLoading(true);
    try {
      const apiUrl = `${C.URLS.BASE_URL}/api/Drivers`;

      if (currentUserRole === "super_admin") {
        const response = await axios.get(apiUrl);
        setDriversCount(response.data.totalCount);
      } else {
        const unitsResponse = await axios.get(
          `${C.URLS.BASE_URL}/api/Units?UserId=${user.id}`
        );
        const userUnits = unitsResponse.data.data.collection;

        if (!userUnits.length) {
          // throw new Error("No userUnits found for the user");
          return;
        }
        const unitId = userUnits[0].id;
        const driverResponse = await axios.get(`${apiUrl}?UnitId=${unitId}`);
        setDriversCount(driverResponse.data.totalCount);
      }
    } catch (error) {
      const errorMessage = error.message.includes("userUnits")
        ? error.message
        : "Network Error: " + error.message;
      console.error(errorMessage);
    } finally {
      // setLoading(false);
    }
  };

  const getRepairedCount = async () => {
    // setLoading(true);
    try {
      // const apiUrl = `${C.URLS.BASE_URL}/api/Maintenances?MaintenanceType=REPAIRE&Status=COMPLETED`;
      const apiUrl = `${C.URLS.BASE_URL}/api/Maintenances?Status=COMPLETED&MaintenanceType=REPAIRE`;

      if (currentUserRole === "super_admin") {
        const response = await axios.get(apiUrl);
        setRepairsCount(response.data.data.totalCount);
      } else {
        const unitsResponse = await axios.get(
          `${C.URLS.BASE_URL}/api/Units?UserId=${user.id}`
        );
        const userUnits = unitsResponse.data.data.collection;

        if (!userUnits.length) {
          // throw new Error("No userUnits found for the user");
          return;
        }
        const unitId = userUnits[0].id;
        const maintenanceResponse = await axios.get(
          `${apiUrl}&UnitId=${unitId}`
        );
        setRepairsCount(maintenanceResponse.data.data.totalCount);
      }
    } catch (error) {
      const errorMessage = error.message.includes("userUnits")
        ? error.message
        : "Network Error: " + error.message;
      console.error(errorMessage);
    } finally {
      // setLoading(false);
    }
  };

  const getServicingCount = async () => {
    // setLoading(true);
    try {
      const apiUrl = `${C.URLS.BASE_URL}/api/Maintenances?MaintenanceType=SERVICE&Status=COMPLETED`;

      if (currentUserRole === "super_admin") {
        const response = await axios.get(apiUrl);
        setServicingCount(response.data.data.totalCount);
      } else {
        const unitsResponse = await axios.get(
          `${C.URLS.BASE_URL}/api/Units?UserId=${user.id}`
        );
        const userUnits = unitsResponse.data.data.collection;

        if (!userUnits.length) {
          // throw new Error("No userUnits found for the user");
          return;
        }

        const unitId = userUnits[0].id;
        const maintenanceResponse = await axios.get(
          `${apiUrl}&UnitId=${unitId}`
        );
        setServicingCount(maintenanceResponse.data.data.totalCount);
      }
    } catch (error) {
      const errorMessage = error.message.includes("userUnits")
        ? error.message
        : "Network Error: " + error.message;
      console.error(errorMessage);
    } finally {
      // setLoading(false);
    }
  };

  const servicingData = {
    labels: ["Non-Serviced", "Serviced"],
    datasets: [
      {
        data: [vehiclesCount - servicingCount, servicingCount],
        backgroundColor: ["#E9654B", "#7FBF7F"],
        borderColor: ["#FFFFFF", "#FFFFFF"],
        borderWidth: 1,
      },
    ],
  };

  const RepairData = {
    labels: ["Non-Repaired", "Repaired"],
    datasets: [
      {
        data: [50, 30],
        backgroundColor: [
          // 'rgba(227, 63, 30, 0.7)'
          "rgba(75, 192, 192, 0.6)",
          "rgba(75, 192, 192, 0.6)",
        ],
        borderColor: ["transparent", "transparent"],
        borderWidth: 1,
      },
    ],
  };

  const servicingOptions = {
    plugins: {
      legend: {
        labels: {
          font: {
            size: 11,
            fontFamily: '"DM Sans, sans-serif",',
          },
        },
      },
      // title: {
      //   display: true,
      //   text: 'Non-serviced vs. Serviced'
      // },
    },
    elements: {
      arc: {
        borderWidth: 0.25,
      },
    },
    cutout: "90%",
    maintainAspectRatio: true,
    responsive: true,
  };

  // const repairOptions = {
  //   legend: {
  //     labels: {
  //       fontSize: 10,
  //     },
  //   },
  //   plugins: {
  //     title: {
  //       display: true,
  //       text: 'Non-repaired vs. Repaired'
  //     }
  //   },
  //   elements: {
  //     arc: {
  //       borderWidth: 0.5,
  //     },
  //   },
  // };

  const lineChartData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Serviced Vehicles",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        fontFamily: '"DM Sans, sans-serif"',
        backgroundColor: "#7FBF7F",
        weight: "700",

        borderColor: ["#7FBF7F"],
        borderWidth: 0,
      },
    ],
  };

  // const lineChartOptions = {
  //   legend: {
  //     labels: {
  //       fontSize: 10,
  //       fontFamily: '"DM Sans, sans-serif",',
  //     },
  //   },
  //   plugins: {
  //     title: {
  //       display: false,
  //       text: ''
  //     }
  //   },
  //   elements: {
  //     arc: {
  //       borderWidth: 0.25,
  //     },
  //   },
  // };

  // const { startRange: startApprovedRange, endRange: endApprovedRange } =
  //   calculatePaginationRanges(
  //     activePage,
  //     psApprovedRequests,
  //     tcApprovedRequests
  //   );

  return (
    <Layout className={`${classes.dmFont} ${classes.layoutContainer}`}>
      <div className={classes.flexContainer}>
        <div className={`${classes.item} flex p-4 bg-[#FFFFFF] rounded-md`}>
          <div>
            <Text
              size="md"
              className={`${classes.title} text-[#A0AEC0] capitalize font-dm`}
            >
              No. Vehicles
            </Text>
            <Text className={`${classes.value} text-[#002200]`}>
              {vehiclesCount}
            </Text>
          </div>

          <div className="bg-[#36A2EB] p-2 rounded-md">
            <FaBus className={classes.icon} size="1.2rem" />
          </div>
        </div>

        <div className={`${classes.item} flex p-4 bg-[#FFFFFF] rounded-md`}>
          <div>
            <Text
              size="md"
              className={`${classes.title} text-[#A0AEC0] capitalize font-dm`}
            >
              No. Drivers
            </Text>
            <Text className={`${classes.value} text-[#002200]`}>
              {driversCount}
            </Text>
          </div>

          <div className="bg-[#FFE771] p-2 rounded-md">
            <FaUserTie className={classes.icon} size="1.2rem" />
          </div>
        </div>

        <div className={`${classes.item} flex p-4 bg-[#FFFFFF] rounded-md`}>
          <div>
            <Text
              size="md"
              className={`${classes.title} text-[#A0AEC0] capitalize font-dm`}
            >
              Serviced Vehicles
            </Text>
            <Text className={`${classes.value} text-[#002200]`}>
              {servicingCount}
            </Text>
          </div>

          <div className="bg-[#7fbf7f] p-2 rounded-md">
            <FaTools className={classes.icon} size="1.2rem" />
          </div>
        </div>

        <div className={`${classes.item} flex p-4 bg-[#FFFFFF] rounded-md`}>
          <div>
            <Text
              size="md"
              className={`${classes.title} text-[#A0AEC0] capitalize font-dm`}
            >
              Repaired Vehicles
            </Text>
            <Text className={`${classes.value} text-[#002200]`}>
              {repairsCount}
            </Text>
          </div>

          <div className="bg-[#E9654B] p-2 rounded-md">
            <FaWrench className={classes.icon} size="1.2rem" />
          </div>
        </div>
      </div>

      <div className={classes.flexContainer2}>
        <Paper className={classes.flexItem2} p="md" radius="md">
          <div className="font-dm text-sm font-bold text-[#515861] mb-2 pb-5">
            Non-serviced vs. Serviced
          </div>
          <DoughnutChart data={servicingData} options={servicingOptions} />
        </Paper>

        <Paper className={classes.flexItem2} p="md" radius="md">
          <div className="font-dm text-sm font-bold text-[#515861] mb-2 pb-5">
            Monthly Serviced Vehicles
          </div>
          {/* <DoughnutChart data={servicingData} options={servicingOptions} /> */}
          <BarChart data={lineChartData} />
        </Paper>

        {/* <Paper className={classes.flexItem2} p="md" radius="md">
    <div className="font-dm text-sm font-bold text-[#515861] mb-2">Monthly Serviced Vehicles</div>
    <BarChart data={lineChartData} />
  </Paper> */}
      </div>

      <div className={classes.flexContainer2}>
      <Paper className={classes.flexItem2} p="md" radius="md">
          <div className="font-dm text-sm font-bold text-[#515861] mb-2">
            Recently Repaired Vehicles
          </div>
          {/* <BarChart /> */}
          <>
            {!loading ? (
              <>
                {cApprovedRequests ? (
                  cApprovedRequests.length > 0 ? (
                    <TableSort
                      data={completedRequestsRowData}
                      fields={baseTableFields2}
                      // category={"fleetRepaireds"}
                      category={"fleetRepairedsDashboard"}
                    />
                  ) : (
                    <TableSort
                      data={[]}
                      fields={baseTableFields2}
                      category={"fleetRepairedsDashboard"}
                    />
                  )
                ) : null}
              </>
            ) : (
              <div className="flex justify-center items-center px-5 py-5">
                <div
                  style={{ minHeight: "50vh" }}
                  className="flex items-center justify-center"
                >
                  <div className="flex justify-center px-5 py-5">
                    <MoonLoader color="#4CCEAC" size={50} />
                  </div>
                </div>
              </div>
            )}
          </>
        </Paper>

        <Paper className={classes.flexItem2} p="md" radius="md">
          <div className="font-dm text-sm font-bold text-[#515861] mb-2">
            Upcoming Servicings
          </div>
          {/* <BarChart /> */}
          <>
            {!loading ? (
              <>
                {cApprovedRequests ? (
                  cApprovedRequests.length > 0 ? (
                    <TableSort
                      data={completedRequestsRowData}
                      fields={baseTableFields2}
                      category={"fleetRepairedsDashboard"}
                    />
                  ) : (
                    <TableSort
                      data={[]}
                      fields={baseTableFields2}
                      category={"fleetRepairedsDashboard"}
                    />
                  )
                ) : null}
              </>
            ) : (
              <div className="flex justify-center items-center px-5 py-5">
                <div
                  style={{ minHeight: "50vh" }}
                  className="flex items-center justify-center"
                >
                  <div className="flex justify-center px-5 py-5">
                    <MoonLoader color="#4CCEAC" size={50} />
                  </div>
                </div>
              </div>
            )}
          </>
        </Paper>

        {/* <Paper className={classes.flexItem2} p="md" radius="md">
    <div className="font-dm text-sm font-bold text-[#515861] mb-2">Monthly Serviced Vehicles</div>
    <BarChart data={lineChartData} />
  </Paper> */}
      </div>

      {/* <div className={`${classes.repairContainer} mt-2 px-5 py-5 bg-[#FFFFFF] text-[#515861]`}>
        <div className={classes.barChartContainer}>
          <BarChart />
        </div>
        <div className={classes.barChartContainer}>
          <BarChart />
        </div>
      </div> */}

      {/* <div className={`${classes.repairContainer} mt-2 p-5 bg-[#FFFFFF]`}>
        <div className={classes.barChartContainer}>
          <BarChart />
        </div>
      </div> */}

      {/* <LineChart chartData={chartData} /> */}
      {/* <PieChart chartData={chartData} /> */}
    </Layout>
  );
}
