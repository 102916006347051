import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import { C } from '../common';
import toast from "react-hot-toast";
import { useState } from "react";

export function EditDocumentCategory({ documentCategory }) {
    const [loading, setLoading] = useState(false);

    // console.log(documentCategory);

    return (
        <Formik
            initialValues={{
                id: documentCategory.id,
                "name": documentCategory.Name,
            }}
            enableReinitialize

            onSubmit={async (values, { resetForm }) => {
                // console.log(values);
                setLoading(true);
                try {
                    const response = await axios
                        .put(`${C.URLS.BASE_URL}/api/DocumentCategories/${documentCategory.id}`, {
                            ...values,
                        });
                    const responseData = response.data;

                    if (responseData.code === 'BadRequest') toast.error(responseData.message, { position: "bottom-center" });
                    else if (response.status === 200 || response.status === 201) {
                        toast.success(responseData.message, { position: "bottom-center" });
                        setLoading(false);
                        resetForm();
                    } else {
                        toast.error("An error occurred while processing your request.", { position: "bottom-center" });
                    }

                    setLoading(false);
                } catch (error) { }
                resetForm();
            }}

        >
            {({ values, handleChange, handleBlur, errors, touched }) => {
                return (
                    <>
                        <Form className="h-full overflow-y-scroll pt-10 px-4 md:px-10 bg-white">
                            <main>
                                <label className="block mb-5">
                                    Role name
                                    <div className="">
                                        <input
                                            type="text"
                                            name="name"
                                            onChange={handleChange("name")}
                                            onBlur={handleBlur("name")}
                                            value={values.name}
                                            className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors &&
                                                errors?.lastName &&
                                                touched &&
                                                touched?.lastName
                                                ? "border-red-400"
                                                : "border-slate-400"
                                                }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-sm focus:ring-1 mb-1`}
                                        />
                                        <ErrorMessage name="name">
                                            {(msg) => (
                                                <div className="text-xs text-red-500 text-left w-full">
                                                    <p>{msg}</p>
                                                </div>
                                            )}
                                        </ErrorMessage>
                                    </div>
                                </label>

                                <div className="flex justify-end gap-3 md:gap-5 mt-4">
                                    <input
                                        type="submit"
                                        value={loading ? "Updating category..." : "Update category"}
                                        className="bg-[#23A74C] text-sm border-[#23A74C] border-2 text-white py-1 px-4 my-2 rounded-md cursor-pointer"
                                    />
                                </div>
                            </main>
                        </Form>
                    </>
                );
            }}
        </Formik>
    );
}
