import { Layout, Paginate } from "../../components";
import { AiFillPrinter, AiFillFileExcel } from "react-icons/ai";
import {
  IconManualGearbox,
} from '@tabler/icons-react';

import {
  FaWarehouse,
} from 'react-icons/fa';

import { MoonLoader } from 'react-spinners';

// import { AddGarage } from "../../components/AddGarage";
import { AddGarage } from "../../components";


import FleetDrawer from "../../components/FleetDrawer";
import { useEffect, useState } from "react";
import axios from "axios";
import { C } from '../../common';
import { Helmet } from "react-helmet";
// import { Formik, Form, ErrorMessage } from "formik";
import { useOnClickOutside } from "../../hooks";
import { useRef } from "react";
import { generateCSV, generateCSV2 } from "../../helpers/generateCSV";
import { generatePDF } from "../../helpers/generatePDF";

import { useDisclosure } from '@mantine/hooks';
import { Pagination } from '@mantine/core';

import { TableSort } from "../../components/TableSort";

import {
  IconCirclePlus,
  IconUserBolt,
} from '@tabler/icons-react';


const Garages = () => {
  const [loading, setLoading] = useState(false);

  const PAGE_SIZE = 20;

  const [openedAddDrawer, { open: openAddDrawer, close: closeAddDrawer }] = useDisclosure(false);

  const [roles, setRoles] = useState([]);
  const ref = useRef();

  // const [driversData, setDriversData] = useState([]);
  const [garagesData, setGaragesData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortAsc, setSortAsc] = useState(false);


  const [activePage, setPage] = useState(1);
  const [selectedGarage, setSelectedGarage] = useState({});


  useEffect(() => {
    getGarages();

    const fetchRoles = async () => {
      try {
        axios
          .get(`${C.URLS.BASE_URL}/api/Roles`)
          .then((res) => setRoles(res.data.data));
        // .catch((error) => console.log(error));
      } catch (error) {
        console.error("Network Error:", error.message);
      }
    };
    fetchRoles();
  }, [activePage]);

  const getGarages = async () => {
    setLoading(true);
    try {
      await axios
        .get(`${C.URLS.BASE_URL}/api/Garages?PageNumber=${activePage}&PageSize=${PAGE_SIZE}`)
        .then((res) => setGaragesData(res.data.data));
    } catch (error) {
      console.error("Network Error:", error.message);
    }
    setLoading(false);
  };

  const formatAndAdjustTimezone = (dateString) => {
    const utcDate = new Date(dateString);
    utcDate.setHours(utcDate.getHours() + 3);

    const formattedDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "UTC",
    }).format(utcDate);

    return formattedDate;
  };

  const {
    currentPage,
    pageSize,
    totalCount,
    totalPages,
    collection: garages,
    previousPage,
    nextPage,
  } = garagesData;

  const garageStatusMapping = {
    'QUALIFIED': 'Qualified',
    'NOT_QUALIFIED': 'Unqualified',
  };

  const tableFields = ['Name', 'Email', 'Contact', 'Location', 'Status', 'Creation Date', 'Action'];

  const rowData = garagesData.collection?.map((garage) => ({
    id: garage.id,
    'Name': `${garage.name}`,
    'Email': `${garage.email}`,
    'Contact': `${garage.contact}`,
    'Location': `${garage.location}`,
    // 'Status': `${garage.status}`,
    'Status': `${garageStatusMapping[garage.status] || garage.status}`,
    "Creation Date": formatAndAdjustTimezone(garage.createdOn),
  })) || [];


  const calculatePaginationRanges = (currentPage, pageSize, totalCount) => {
    if (totalCount === 0) {
      return { startRange: 0, endRange: 0 };
    }
    const startRange = (currentPage - 1) * pageSize + 1;
    const endRange = Math.min(startRange + pageSize - 1, totalCount);
    return { startRange, endRange };
  };

  let filteredGarages = garages ? garages.slice() : [];

  if (filteredGarages.length > 0 && searchText) {
    filteredGarages = filteredGarages.filter(garage => {
      const lowerSearchText = searchText.toLowerCase();
      const lowerName = garage.name ? garage.name.toLowerCase() : '';
      const lowerContact = garage.contact ? garage.contact.toLowerCase() : '';
      const lowerGarageEmail = garage.email ? garage.email.toLowerCase() : '';

      return lowerName.includes(lowerSearchText) ||
        lowerContact.includes(lowerSearchText) ||
        lowerGarageEmail.includes(lowerSearchText);
    });
  }


  const sortByFirstName = (ascOrDes) => {
    const sortedData = [...filteredGarages].sort((a, b) =>
      ascOrDes
        ? a.firstName.localeCompare(b.firstName)
        : b.firstName.localeCompare(a.firstName)
    );
    filteredGarages = sortedData;
  };

  const { startRange, endRange } = calculatePaginationRanges(activePage, pageSize, totalCount);

  return (
    <Layout>
      <Helmet>
        <title>Garages - Mak Fleet</title>
      </Helmet>
      <div className="flex justify-between">
        <h2 className="flex gap-2 items-center font-medium text-md">
          <FaWarehouse size={14} />
          {garages ? totalCount : 0} Garages available
        </h2>
        <div className="flex font-medium text-sm text-[#434C50]">

          <button
            className="px-5 py-2 bg-[#23A74C] rounded-md font-small text-white flex items-center text-base"
            onClick={openAddDrawer}
          >
            <span className="mr-2"><IconCirclePlus size={18} /></span>
            <span className="text-sm">Add new garage</span>
          </button>
        </div>
      </div>

      {!loading ? (
        <>
          {garages &&
            <TableSort
              data={rowData}
              fields={tableFields}
              category={'fleetGarages'}
            />}

          {/* <div className="px-3 py-2 mt-3 flex justify-between items-center ">
            <div className="text-sm text-gray-500">
              Showing {startRange ? startRange : 0} to {endRange ? endRange : 0} of {totalCount} entries
            </div>
            <div className="mt-2">
              <Pagination
                value={activePage}
                onChange={setPage}
                total={totalPages}
                position="right"
                withEdges
                styles={(theme) => ({
                  control: {
                    '&[data-active]': {
                      backgroundImage: theme.fn.gradient({ from: '#C1BCCD', to: '#C1BCCD' }),
                      border: 0,
                    },
                  },
                })}
              />
            </div>
          </div> */}

          <div className="px-3 py-2 mt-3 flex justify-end items-center font-medium">
            <div className="text-sm mr-3" style={{ fontSize: '12px' }}>
              Showing {startRange ? startRange : 0} to {endRange ? endRange : 0} of {totalCount} entries
            </div>
            <div className="">
              <Pagination
                value={activePage}
                onChange={setPage}
                total={totalPages}
                size="sm"
                position="right"
                withEdges
                styles={(theme) => ({
                  fontFamily: 'Outfit',
                  fontSize: '12px',
                  control: {
                    '&[data-active]': {
                      backgroundImage: theme.fn.gradient({ from: '#C1BCCD', to: '#C1BCCD' }),
                      border: 0,
                    },
                  },
                })}
              />
            </div>
          </div>

        </>
      ) : (
        <div style={{ minHeight: '50vh' }} className="flex items-center justify-center">
          <div style={{ minHeight: '50vh' }} className="flex items-center justify-center">
            <div className="flex justify-center px-5 py-5">
              <MoonLoader color="#4CCEAC" size={50} />
            </div>
          </div>
        </div>
      )}

      <FleetDrawer opened={openedAddDrawer} onClose={closeAddDrawer} title="Add new garage">
        <AddGarage onCancel={closeAddDrawer} />
      </FleetDrawer>

    </Layout>
  );
}

export default Garages;