import { Layout } from "../../components";
import { AddVehicle } from "../../components";
import FleetDrawer from "../../components/FleetDrawer";
import { useEffect, useState } from "react";
import axios from "axios";
import { C } from "../../common";
import { Helmet } from "react-helmet";
import { useDisclosure } from "@mantine/hooks";
import { Pagination } from "@mantine/core";
import { TableSort } from "../../components/TableSort";
import { useAuth } from "../../helpers/auth/AuthContext";
import { IconCirclePlus } from "@tabler/icons-react";
import { FaBus } from "react-icons/fa";
import { MoonLoader } from "react-spinners";

const Vehicles = () => {
  const [loading, setLoading] = useState(false);

  const [openedAddDrawer, { open: openAddDrawer, close: closeAddDrawer }] =
    useDisclosure(false);

  const { user } = useAuth();
  const [currentUserRole, setcurrentUserRole] = useState('');
  const [vehiclesData, setVehiclesData] = useState([]);
  const [activePage, setPage] = useState(1);

  const PAGE_SIZE = 20;

  useEffect(() => {
    const getUserRole = async (userID) => {
      try {
        await axios
          .get(`${C.URLS.BASE_URL}/api/Users/${userID}`)
          .then((res) => setcurrentUserRole(res.data?.role.name));
      } catch (error) {
        console.error("Network Error:", error.message);
      }
    };

    const getVehicles = async () => {
      setLoading(true);
      try {
        if (currentUserRole === "super_admin") {
          const response = await axios.get(`${C.URLS.BASE_URL}/api/Vehicles?PageNumber=${activePage}&PageSize=${PAGE_SIZE}`);
          setVehiclesData(response.data.data);
        } else {
          const unitsResponse = await axios.get(
            `${C.URLS.BASE_URL}/api/Units?UserId=${user.id}`
          );
  
          const userUnits = unitsResponse.data.data.collection;
          if (userUnits.length === 0) {
            // console.error("No userUnits found for the user");
            return;
          }
  
          const unitId = userUnits[0].id;
          const vehiclesResponse = await axios.get(
            `${C.URLS.BASE_URL}/api/Vehicles?UnitId=${unitId}&PageNumber=${activePage}`
          );
          setVehiclesData(vehiclesResponse.data.data);
        }
      } catch (error) {
        console.error("Network Error:", error.message);
      } finally {
        setLoading(false);
      }
    };
    
    getUserRole(user?.id);
    getVehicles();
  }, [activePage, currentUserRole, user.id]);

  const formatAndAdjustTimezone = (dateString) => {
    const utcDate = new Date(dateString);
    utcDate.setHours(utcDate.getHours() + 3);

    const formattedDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "UTC",
    }).format(utcDate);

    return formattedDate;
  };

  const {
    pageSize,
    totalCount,
    totalPages,
    collection: vehicles,
  } = vehiclesData;

  const tableFields = [
    "Reg No.",
    "Make/Model",
    "Type",
    "Y.O.M",
    "Chasis No.",
    "Unit or College",
    "User Unit",
    "Creation Date",
    "Status",
    "Action",
  ];

  const rowData =
    vehiclesData.collection?.map((vehicle) => ({
      id: vehicle.id,
      "Reg No.": `${vehicle.regNo}`,
      "Make/Model": `${vehicle.vehicleMake.name}`,
      Type: `${vehicle.vehicleType.name}`,
      "Y.O.M": `${vehicle.yearOfManufacture}`,
      "Chasis No.": `${vehicle.chasisNumber}`,
      Status: `${vehicle.status}`,
      "Unit or College": `${vehicle.unit.name}`,
      "User Unit": `${vehicle.subUnit || ''}`,
      "Creation Date": formatAndAdjustTimezone(vehicle.createdOn),
    })) || [];

  const calculatePaginationRanges = (currentPage, pageSize, totalCount) => {
    if (totalCount === 0) {
      return { startRange: 0, endRange: 0 };
    }
    const startRange = (currentPage - 1) * pageSize + 1;
    const endRange = Math.min(startRange + pageSize - 1, totalCount);
    return { startRange, endRange };
  };

  const { startRange, endRange } = calculatePaginationRanges(
    activePage,
    pageSize,
    totalCount
  );

  return (
    <Layout>
      <Helmet>
        <title>Vehicles - Mak Fleet</title>
      </Helmet>
      <div className="flex justify-between">
        <h2 className="flex gap-2 items-center text-md font-semibold text-[#515861]">
          <FaBus size={14} />
          {vehicles ? totalCount : 0} vehicles available
        </h2>
        <div className="flex font-medium text-sm text-[#434C50]">
          {currentUserRole === "super_admin" && (
            <button
              className="px-5 py-2 bg-[#23A74C] rounded-md font-medium text-white flex items-center text-base"
              onClick={openAddDrawer}
            >
              <span className="mr-2">
                <IconCirclePlus size={18} />
              </span>
              <span className="text-base-extra font-medium">New vehicle</span>
            </button>
          )}
        </div>
      </div>

      {!loading ? (
        <>
          {vehicles && (
            <TableSort
              data={rowData}
              fields={tableFields}
              category={"fleetVehicles"}
            />
          )}

          <div className="px-3 py-2 mt-3 flex justify-end items-center font-medium">
            <div className="text-sm mr-3" style={{ fontSize: "12px" }}>
              Showing {startRange ? startRange : 0} to {endRange ? endRange : 0}{" "}
              of {totalCount} entries
            </div>
            <div className="">
              <Pagination
                value={activePage}
                onChange={setPage}
                total={totalPages}
                size="sm"
                position="right"
                withEdges
                styles={(theme) => ({
                  fontFamily: "Outfit",
                  fontSize: "12px",
                  control: {
                    "&[data-active]": {
                      backgroundImage: theme.fn.gradient({
                        from: "#C1BCCD",
                        to: "#C1BCCD",
                      }),
                      border: 0,
                    },
                  },
                })}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="flex justify-center px-5 py-5">
          <div
            style={{ minHeight: "50vh" }}
            className="flex items-center justify-center"
          >
            <div className="flex justify-center px-5 py-5">
              <MoonLoader color="#4CCEAC" size={50} />
            </div>
          </div>
        </div>
      )}

      <FleetDrawer
        opened={openedAddDrawer}
        onClose={closeAddDrawer}
        title="Add New Vehicle"
      >
        <AddVehicle onCancel={closeAddDrawer} />
      </FleetDrawer>
    </Layout>
  );
};

export default Vehicles;
