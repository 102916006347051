import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import { C } from '../common';
import toast from "react-hot-toast";
import { useEffect, useState } from "react";

import { useDisclosure } from '@mantine/hooks';
import { Modal, useMantineTheme } from '@mantine/core';

import { useNavigate, useParams } from 'react-router-dom';
import { Layout } from "./Layout";

import Select from 'react-select';

import {
  FaCaretLeft,
  FaTrash,
  FaSave,
  FaBus,
  FaTimes,

  FaUserCheck,
  FaCarSide,
} from 'react-icons/fa';

export function EditDriver() {
  const [loading, setLoading] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [isVerifyDriverModalOpen, { open: openVerifyDriverModal, close: closeVerifyDriverModal }] = useDisclosure(false);

  const theme = useMantineTheme();

  const [vehicles, setVehicles] = useState([]);
  const [currentDriver, setCurrentDriver] = useState({});

  const navigate = useNavigate();
  const { id } = useParams();

  console.log(currentDriver);

  useEffect(() => {
    getDriver();
    fetchVehicles();
  }, []);

  const getDriver = async () => {
    setLoading(true);
    try {
      await axios
        .get(`${C.URLS.BASE_URL}/api/Drivers/${id}`)
        .then((res) => setCurrentDriver(res.data.data));
    } catch (error) {
      console.error("Network Error:", error.message);
    }
    setLoading(false);
  };

  const fetchVehicles = async () => {
    try {
      const allVehicles = [];
      let currentPage = 1;

      while (true) {
        const response = await axios.get(`${C.URLS.BASE_URL}/api/Vehicles?PageNumber=${currentPage}`);
        const vehiclesData = response.data.data.collection;

        if (vehiclesData.length === 0) {
          break;
        }

        allVehicles.push(...vehiclesData);
        currentPage++;
      }

      setVehicles(allVehicles);
    } catch (error) {
      console.error(error.message);
    }
  };


  const activateDriver = async () => {
    setLoading(true);

    try {
      const response = await axios.patch(`${C.URLS.BASE_URL}/api/Drivers/${id}/activate`);
      const responseData = response.data;
      if (responseData.code === 'BadRequest') toast.error(responseData.message, { position: "bottom-center" });
      else if (response.status === 200 || response.status === 201) {
        toast.success(responseData.message, { position: "bottom-center" });
        setLoading(false);
      } else {
        toast.error("An error occurred while processing your request.", { position: "bottom-center" });
      }
    } catch (error) {
      toast.error(`${error.message}`, {
        position: "bottom-center",
      });
    }

    setLoading(false);
  };

  const handleBackClick = () => {
    navigate(`/drivers`);
  };

  const allocateVehicle = async (vehicleId) => {
    setLoading(true);

    try {
      const response = await axios.post(`${C.URLS.BASE_URL}/api/Drivers/${id}/vehicle/${vehicleId}/assign`);
      const responseData = response.data;

      if (responseData.code === 'BadRequest') toast.error(responseData.message, { position: "bottom-center" });
      else if (response.status === 200 || response.status === 201) {
        toast.success(responseData.message, { position: "bottom-center" });
        setLoading(false);
      } else {
        toast.error("An error occurred while processing your request.", { position: "bottom-center" });
      }

      setLoading(false);

    } catch (error) { }
    finally {
      close();
      // handleBackClick();
    }
  };

  console.log(currentDriver);
  // console.log('isVerified:', currentDriver.isVerified);

  return (
    <Layout>
      <Formik
        initialValues={{
          id: currentDriver.id,

          driverNumber: currentDriver.driverNumber,
          unitId: currentDriver.unitId,
          contact: currentDriver.contact,
          name: currentDriver.name,
          email: currentDriver.email,
          status: currentDriver.status,
          isDeleted: false,
          isVerified: currentDriver.isVerified,

          // driverPermits: currentDriver.driverPermits ? currentDriver.driverPermits[0] : {},

        }}
        enableReinitialize
        onSubmit={async (values, { resetForm }) => {
          setLoading(true);
          try {
            const response = await axios
              .put(`${C.URLS.BASE_URL}/api/Drivers/${id}`, {
                ...values,
              });
            const responseData = response.data;

            if (responseData.code === 'BadRequest') toast.error(responseData.message, { position: "bottom-center" });
            else if (response.status === 200 || response.status === 201) {
              toast.success(responseData.message, { position: "bottom-center" });
              setLoading(false);
              // resetForm();
            } else {
              toast.error("An error occurred while processing your request.", { position: "bottom-center" });
            }

            setLoading(false);

          } catch (error) { }
        }}

      >
        {({ values, setFieldValue, handleChange, handleBlur, errors, touched }) => {
          return (
            <>
              {/* <Form className="h-full overflow-y-scroll pt-10 px-4 md:px-10 bg-white"> */}
              <Form className="h-full overflow-y-scroll px-4 md:px-10">
                <main>

                  <div className="flex justify-between items-center gap-3 md:gap-5 mb-2">
                    <button
                      className="px-4 py-2 my-2 font-medium bg-white border rounded-md text-[#23A74C] flex items-center text-base"
                      onClick={handleBackClick}
                    >
                      <span className="mr-2"><FaCaretLeft size={14} /></span>
                      <span className="text-base-extra">View all drivers</span>
                    </button>

                    <div className="flex gap-3 md:gap-5">
                      <button
                        type="button"
                        className="px-4 py-2 my-2 border border-[#C2E9DF]/40 font-medium bg-[#EAF2F1] rounded-md text-[#002200] flex items-center text-base"
                        onClick={openVerifyDriverModal}
                        disabled={currentDriver.isVerified}
                      >
                        <span className="mr-2"><FaUserCheck size={14} /></span>
                        <span className="text-base-extra">
                          {currentDriver.isVerified ? 'Verified' : 'Verify driver'}
                        </span>
                      </button>

                      <button
                        type="button"
                        className="px-4 py-2 my-2 border border-[#C2E9DF]/70 font-medium bg-[#EAF2F1] rounded-md text-[#002200] flex items-center text-base"
                        onClick={open}
                      >
                        <span className="mr-2"><FaBus size={14} /></span>
                        <span className="text-base-extra">Allocate vehicle</span>
                      </button>

                      <button
                        type="submit"
                        className="px-4 py-2 my-2 font-medium bg-[#23A74C] text-sm text-white border rounded-md flex items-center text-base"
                      >
                        <span className="mr-2"><FaSave size={14} /></span>
                        <span className="text-base-extra">{loading ? "Updating..." : "Update driver"}</span>
                      </button>
                    </div>
                  </div>


                  {/* Section 1 */}
                  {/* <div className="bg-white shadow-md border border-[#E4E4E7]/50 rounded-md px-10 pt-10 pb-5"> */}
                  <div className="bg-white shadow-md border border-[#E4E4E7]/50 rounded-md px-10 pt-5 flex">

                    {/* Left Column */}
                    <div className="w-1/2 pr-5">

                      {/* Vehicle Image */}
                      <label className="block mb-5">
                        <span className="block text-base text-primary-color font-semibold">
                          Driver permit (max size: 3MB)
                        </span>

                        <input
                          className="mt-1 px-3 py-2 bg-transparent border block text-base text-primary-color block w-full focus:border-custom-greener focus:ring-custom-greener rounded-md"
                          id="file"
                          name="file"
                          type="file"
                          onChange={(event) => {
                            setFieldValue("file", event.currentTarget.files[0]);
                          }}
                        />
                      </label>

                    </div>

                    {/* Right Column */}
                    <div className="w-1/2 pl-5">

                      {/* Driver Status */}
                      <label className="block mb-5">
                        <span className="block text-base font-semibold text-primary-color">
                          Driver status
                        </span>
                        <div className="">
                          <select
                            name="status"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.status}
                            className={`mt-1 px-3 py-2 bg-transparent border border-custom-green ${errors && errors.status && touched && touched.status
                              ? "border-red-400"
                              : "border-custom-green"
                              }  placeholder-slate-400 focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-base mb-1`}
                          >
                            <option value="NOT_ACTIVE">Inactive</option>
                            <option value="ACTIVE">Active</option>
                            <option value="LEAVE">On leave</option>
                            <option value="RETIRED">Retired</option>
                            <option value="DISCONTINUED">Discontinued</option>
                          </select>
                        </div>
                      </label>

                      <label className="block mb-5 w-full">
                        <span className="block text-base font-semibold text-primary-color">
                          Driver name
                        </span>
                        <div className="">
                          <input
                            type="text"
                            name="name"
                            placeholder="Enter driver name"
                            onChange={handleChange("name")}
                            onBlur={handleBlur("name")}
                            value={values.name}
                            className={`mt-1 px-3 py-2 bg-gray-50 border border-custom-green focus:outline-none focus:border-custom-greener focus:ring-gray-400 block w-full rounded-md sm:text-base focus:ring-0 mb-1`}
                          />
                        </div>
                      </label>

                      <label className="block mb-5 w-full">
                        <span className="block text-base font-semibold text-primary-color">
                          Contact
                        </span>
                        <div className="">
                          <input
                            type="text"
                            name="contact"
                            placeholder="Enter driver contact"
                            onChange={handleChange("contact")}
                            onBlur={handleBlur("contact")}
                            value={values.contact}
                            className={`mt-1 px-3 py-2 bg-gray-50 border border-custom-green focus:outline-none focus:border-custom-greener focus:ring-gray-400 block w-full rounded-md sm:text-base focus:ring-0 mb-1`}
                          />
                        </div>
                      </label>

                      <label className="block mb-5 w-full">
                        <span className="block text-base font-semibold text-primary-color">
                          Email address
                        </span>
                        <div className="">
                          <input
                            type="text"
                            name="email"
                            readOnly
                            placeholder="Enter email"
                            onChange={handleChange("email")}
                            onBlur={handleBlur("email")}
                            value={values.email}
                            className={`mt-1 px-3 py-2 bg-gray-50 border border-gray-200 focus:outline-none focus:border-gray-200 focus:ring-gray-400 block w-full rounded-md sm:text-base focus:ring-0 mb-1 text-gray-500`}
                          />
                        </div>
                      </label>

                    </div>
                  </div>


                  {/* Section 2 */}
                  {/* <div className="bg-white shadow-md border border-[#E4E4E7]/50 rounded-md px-10 pt-10 pb-5"> */}
                  <div className="bg-white shadow-md border border-[#E4E4E7]/50 rounded-md px-10 pt-5 pb-2 flex mt-2">
                  </div>

                </main>

                <Modal
                  opened={opened}
                  onClose={close}
                  title="Vehicle allocation"
                  centered
                  overlayProps={{
                    color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                    opacity: 0.55,
                    blur: 3,
                  }}
                >
                  <div className="border-t border-[#EEE] pt-3 mb-3">
                    <p className="text-base-extra fpmt-medium text-primary-color leading-snug">
                      You're about to allocate a vehicle to a driver.
                      Please select the appropriate vehicle below.
                    </p>
                  </div>

                  {/* Vehicles */}
                  <label className="block py-2">
                    <span className="block text-base font-semibold text-primary-color">
                      Select Vehicle
                    </span>
                    <div className="">
                      <Select
                        isSearchable
                        menuPortalTarget={document.body}
                        name="vehicleId"
                        value={vehicles
                          .filter((vehicle) => vehicle.id === values.vehicleId)
                          .map((vehicle) => ({ value: vehicle.id, label: vehicle.regNo }))[0]
                        }
                        onChange={option => setFieldValue('vehicleId', option ? option.value : '')}
                        onBlur={handleBlur('vehicleId')}
                        options={vehicles.map(vehicle => ({ value: vehicle.id, label: vehicle.regNo }))}
                        className={`mt-1 bg-transparent border border-custom-green ${errors && errors.vehicleId && touched && touched.vehicleId
                          ? 'border-red-400'
                          : 'border-custom-green'
                          } focus:outline-none focus:border-custom-greener focus:ring-custom-greener block w-full rounded-md sm:text-sm mb-1`}
                        classNamePrefix="select"
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            borderColor: state.isFocused
                              ? 'rgba(76, 206, 172, 0.4)'
                              : 'rgba(76, 206, 172, 0.7)',
                            boxShadow: state.isFocused ? '0 0 0 1px rgba(76, 206, 172, 0)' : '',
                            '&:hover': {
                              borderColor: 'rgba(76, 206, 172, 0.4)'
                            }
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? '#23A74C'
                              : state.isFocused
                                ? '#EAF2F1'
                                : provided.backgroundColor,
                            color: state.isSelected
                              ? '#FFFFFF'
                              : provided.color,
                            fontSize: '14px',
                          }),
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),


                        }}
                      />
                      <ErrorMessage name="vehicleId">
                        {(msg) => (
                          <div className="text-xs text-red-500 text-left w-full">
                            <p>{msg}</p>
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                  </label>

                  <div className="flex gap-3 md:gap-5 justify-end"> {/* Added justify-end here */}
                    <button
                      type="button"
                      className="px-4 py-1 my-2 font-medium bg-[#FDE8EB] border rounded-md text-[#B21028] flex items-center text-base"
                      onClick={close}
                    >
                      <span className="mr-2"><FaTimes size={12} /></span>
                      <span className="text-base-extra">Cancel</span>
                    </button>

                    <button
                      type="button"
                      onClick={() => allocateVehicle(values.vehicleId)} // Use an arrow function here
                      className="px-4 py-1 my-2 font-medium bg-[#23A74C] text-sm text-white border rounded-md flex items-center text-base"
                    >
                      <span className="mr-2"><FaBus size={12} /></span>
                      <span className="text-base-extra">Allocate vehicle</span>
                    </button>

                  </div>

                </Modal>

                <Modal
                  opened={isVerifyDriverModalOpen}
                  onClose={closeVerifyDriverModal}
                  title="Driver verification"
                  centered
                  overlayProps={{
                    color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                    opacity: 0.55,
                    blur: 3,
                  }}
                >
                  <div className="border-t border-[#EEE] pt-3 mb-3">
                    <p className="text-base-extra fomt-medium text-primary-color leading-snug">
                      Are you sure you want to verify this driver?
                    </p>
                  </div>

                  <div className="flex gap-3 md:gap-5 justify-start">
                    <button
                      type="button"
                      className="px-4 py-1 my-2 font-medium bg-[#FDE8EB] border rounded-md text-[#B21028] flex items-center text-base"
                      onClick={closeVerifyDriverModal}
                    >
                      <span className="mr-2"><FaTimes size={12} /></span>
                      <span className="text-base-extra">Cancel</span>
                    </button>

                    <button
                      type="button"
                      onClick={async () => {
                        setLoading(true);
                        await activateDriver();
                        setLoading(false);
                      }}
                      className="px-4 py-1 my-2 font-medium bg-[#23A74C] text-sm text-white border rounded-md flex items-center text-base"
                    >
                      <span className="mr-2"><FaBus size={12} /></span>
                      <span className="text-base-extra">Verify driver</span>
                    </button>

                  </div>

                </Modal>
              </Form>
            </>
          );
        }}
      </Formik>
      {/* <DeleteConfirmModal /> */}

    </Layout>
  );
}
