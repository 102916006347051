import { useState } from "react";
import {
  Group,
  Box,
  Collapse,
  ThemeIcon,
  UnstyledButton,
} from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { Link, useLocation } from "react-router-dom";

export function LinksGroup({
  icon: Icon,
  label,
  initiallyOpened,
  links,
  classes,
}) {
  const location = useLocation();
  const hasLinks = Array.isArray(links);
  const [opened, setOpened] = useState(initiallyOpened || false);

  const items = (hasLinks ? links : []).map((link, index) => (
    <Link
      to={link.link}
      key={link.label}
        className={`${classes.link} mx-5 px-10 text-base-extra py-2 my-1 border border-[#002800] hover:bg-[#012904] ${
        link.link === location.pathname ? "text-[#fff]" : "text-[#ccc]"
      }`}
    >
      {link.label}
    </Link>
  ));

  return (
    <>
      <li
        className={classes.link}
        style={{
          display: "block",
          width: "100%", 
        }}
      >
        <UnstyledButton
          onClick={() => setOpened((o) => !o)}
          className={`${classes.link} w-full px-5 py-2 hover:bg-[#012904]`}
        >
          <Group justify="space-between" gap={0}>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <ThemeIcon
                style={{ backgroundColor: "#012904" }}
                className={classes.linkIcon}
              >
                <Icon />
              </ThemeIcon>
              <span className="text-md">{label}</span>{" "}
            </Box>
            {hasLinks && (
              <IconChevronRight
                className={`${classes.chevron} text-[#008000]`}
                size={17}
                style={{
                  transform: opened ? "rotate(-90deg)" : "none",
                }}
              />
            )}
          </Group>
        </UnstyledButton>
        {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
      </li>
    </>
  );
}

export function NavbarLinksGroup() {
  return (
    <LinksGroup />
  );
}
