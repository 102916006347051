import * as Yup from "yup";

const phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;

export const signInWithEmailValidationSchema = Yup.object().shape({
  username_email: Yup.string()
    .required("Email or username required.")
    .min(3, "Should be at least 3 characters."),
  password: Yup.string()
    .min(8, "Should be at least 8 characters.")
    .required("Password required."),
});

export const resetPasswordValidationSchema = Yup.object().shape({
  username_email: Yup.string()
    .required("Email address is required.")
    .email("Invalid Email."),
});

export const newPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email address required.")
    .email("Invalid Email."),
  code: Yup.string().required("Rest code required."),
  password: Yup.string()
    .min(8, "Should be at least 8 characters.")
    .matches(
      /^(?=.*[A-Z])(?=.*\d).*$/,
      "Must contain at least one uppercase letter and one digit"
    )
    .required("Password required."),
});

export const addUserValidationSchema = Yup.object().shape({
  phoneNumber: Yup.string().required("Phone Number Required."),
  emailAddress: Yup.string()
    .required("Email Address is required.")
    .email("Invalid Email.")
    .test('mak-email', 'Invalid Email. Must be firstname.lastname@mak.ac.ug', (value) => {
      if (!value) {
        return true;
      }

      const emailPattern = /^[a-z]+(\.[a-z]+)@mak\.ac\.ug$/;
      return emailPattern.test(value);
    }),
  roleId: Yup.string().required("Role Required."),
  unitId: Yup.string().required("Unit Required."),
});

export const addUnitValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Should be at least 3 characters.")
    .required("Name Required."),
  location: Yup.string()
    .min(3, "Should be at least 3 characters.")
    .required("Location Required."),
});

export const addGarageValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Should be at least 3 characters.")
    .required("Name Required."),
  location: Yup.string()
    .min(3, "Should be at least 3 characters.")
    .required("Location Required."),
  status: Yup.string()
    .min(3, "Should be at least 3 characters.")
    .required("Status Required."),
});

export const assignUnitAdminValidationSchema = Yup.object().shape({
  admin: Yup.string().required("Name Required."),
});

export const addDriverValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Should be at least 3 characters.")
    .required("Name Required."),
  contact: Yup.string().required("Contact Required."),
  email: Yup.string().required("Email is required.").email("Invalid Email."),
  driverNumber: Yup.string().required("Number Required."),
});

export const addVehicleValidationSchema = Yup.object().shape({
  regNo: Yup.string()
  .test(
    "regNo",
    "Invalid registration number.",
    (value) => {
      return (
        /^U([A-Z]{1} |[A-Z]{3} ).*$/.test(value) ||
        /^U([A-Z]{2} |[A-Z]{3} ).*$/.test(value) ||
        /^\d{3} [A-Z]{3}$/.test(value)
      );
    }
  )
  .required("Reg no. required."),

  vehicleMakeId: Yup.string().required("Make required."),
  vehicleTypeId: Yup.string().required("Type required."),
  yearOfManufacture: Yup.string().required("Year of manufacture required."),
  // engineNo: Yup.string().required("Engine no. required."),
  unitId: Yup.string().required("Unit required."),
  regBook: Yup.string().required("Reg. book holder required."),
  status: Yup.string().required("Status required."),
  // chasisNumber: Yup.string().required("Chasis No. Required."),
  // imageUrl: Yup.string().required("Form Required."),
});

export const addServicingRequestValidationSchema = Yup.object().shape({
  note: Yup.string().required("Note is required."),
});

export const addRoleValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Role name is required")
    .min(3, "Role name should have at least 3 characters"),
});

export const addVehicleTypeValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Vehicle type is required")
});

export const addVehicleMakeValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Vehicle make is required")
});

export const addPermitClassValidationSchema = Yup.object().shape({
  name: Yup.string().required("Permit class is required"),
  // .min(3, 'Role name should have at least 3 characters')
});

export const addDocumentCategoryValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Document category name is required")
    .min(3, "Role name should have at least 3 characters"),
});

export const addInsuranceProviderValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Provider name is required")
    .min(3, "Provider name should have at least 3 characters"),
  contact: Yup.number()
    .typeError("Provider contact must be a number.")
    .required("Provider contact is required."),
});

export const editMaintainenceRequestValidationSchema = Yup.object().shape({
  garageId: Yup.string().required("Selecting a garage is required."),
  estimatedCost: Yup.number()
    .typeError("Estimated cost must be a number.")
    .min(4, "Estimated cost should be at least 1000 UGX.")
    .required("Estimated cost is required."),
});

export const updateMaintainenceRequestValidationSchema = Yup.object().shape({
  startDate: Yup.string().required("Start date of repair is required."),
  endDate: Yup.string().required("End date of repair is required."),
  cost: Yup.number()
    .typeError("Actual cost must be a number.")
    .min(4, "Actual cost should be at least 1000 UGX.")
    .required("Actual cost is required."),
});

export const getFinancialReports = Yup.object().shape({
  To: Yup.string().required("* Start date is required."),
  From: Yup.string().required("* End date is required."),
  Category: Yup.string().required("* Select a category."),
  Status: Yup.string().required("* Select an appropriate status."),
});
