import { AddServicingRequestForm, Layout } from "../../components";
import { useAuth } from "../../helpers/auth/AuthContext";
import FleetDrawer from "../../components/FleetDrawer";
import { useEffect, useState } from "react";
import axios from "axios";
import { C } from "../../common";
import { Helmet } from "react-helmet";
import { useRef } from "react";

import { useDisclosure } from "@mantine/hooks";
import { Pagination, Tabs, rem } from "@mantine/core";
import {
  IconPhoto,
  IconMessageCircle,
  IconSettings,
} from "@tabler/icons-react";
import { TableSort } from "../../components/TableSort";
import { MoonLoader } from "react-spinners";

import { IconCirclePlus } from "@tabler/icons-react";
import {
  FaBell,
  FaWrench,
  FaCheckDouble,
  FaCheck,
  FaTimes,
  FaBus,
} from "react-icons/fa";

const Servicing = () => {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("repairs");

  const PAGE_SIZE = 20;

  // const iconStyle = { width: rem(12), height: rem(12) };

  const [openedAddDrawer, { open: openAddDrawer, close: closeAddDrawer }] =
    useDisclosure(false);

  const [roles, setRoles] = useState([]);
  const { user } = useAuth();
  const [currentUserRole, setcurrentUserRole] = useState("");
  const ref = useRef();

  const [unitsData, setUnitsData] = useState([]);

  const [requestsData, setRequestsData] = useState([]);
  const [repairingData, setRepairingData] = useState([]);
  const [approvedData, setApprovedData] = useState([]);
  const [rejectedData, setRejectedData] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [sortAsc, setSortAsc] = useState(false);
  const [activePage, setPage] = useState(1);

  useEffect(() => {
    getUserRole(user?.id);
    getUnits();
    getRequestsData();
    getRepairingData();
    getApprovedData();
    getRejectedData();

    const fetchRoles = async () => {
      try {
        axios
          .get(`${C.URLS.BASE_URL}/api/Roles`)
          .then((res) => setRoles(res.data.data));
      } catch (error) {
        console.error("Network Error:", error.message);
      }
    };
    fetchRoles();
  }, [activePage, currentUserRole]);

  const getUserRole = async (userID) => {
    try {
      await axios
        .get(`${C.URLS.BASE_URL}/api/Users/${userID}`)
        .then((res) => setcurrentUserRole(res.data?.role.name));
    } catch (error) {
      console.error("Network Error:", error.message);
    }
  };

  const fetchMaintenanceData = async (status, setDataFunction) => {
    setLoading(true);

    try {
      const apiUrl = `${C.URLS.BASE_URL}/api/Maintenances?MaintenanceType=SERVICE&Status=${status}`;

      if (currentUserRole === "super_admin") {
        const response = await axios.get(apiUrl);
        setDataFunction(response.data.data);
      } else {
        const unitsResponse = await axios.get(
          `${C.URLS.BASE_URL}/api/Units?UserId=${user.id}`
        );
        const userUnits = unitsResponse.data.data.collection[0].userUnits;

        if (!userUnits.length) {
          throw new Error("No userUnits found for the user");
        }

        const unitId = userUnits[0].unitId;
        const maintenanceResponse = await axios.get(
          `${apiUrl}&UnitId=${unitId}`
        );
        setDataFunction(maintenanceResponse.data.data);
      }
    } catch (error) {
      const errorMessage = error.message.includes("userUnits")
        ? error.message
        : "Network Error: " + error.message;
      // console.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const formatAndAdjustTimezone = (dateString) => {
    const utcDate = new Date(dateString);
    utcDate.setHours(utcDate.getHours() + 3);

    const formattedDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "UTC",
    }).format(utcDate);

    return formattedDate;
  };

  const getRequestsData = () =>
    fetchMaintenanceData("NEW_REQUEST", setRequestsData);
  const getRepairingData = () =>
    fetchMaintenanceData("SERVICING", setRepairingData);
  const getApprovedData = () =>
    fetchMaintenanceData("COMPLETED", setApprovedData);
  const getRejectedData = () =>
    fetchMaintenanceData("DECLINED", setRejectedData);

  const getUnits = async () => {
    try {
      await axios
        .get(`${C.URLS.BASE_URL}/api/Units`)
        .then((res) => setUnitsData(res.data.data));
    } catch (error) {
      console.error("Network Error:", error.message);
    }
  };

  const getExcerpt = (html, length = 15) => {
    let doc = new DOMParser().parseFromString(html, "text/html");
    let text = doc.body.textContent || "";
    return text.length > length ? text.substr(0, length) + "..." : text;
  };

  const {
    currentPage: cpRepairRequests,
    pageSize: psRepairRequests,
    totalCount: tcRepairRequests,
    totalPages: tpRepairRequests,
    collection: crepairRequests,
    previousPage: ppRepairRequests,
    nextPage: npRepairRequests,
  } = requestsData;

  const {
    currentPage: cpRepairingRequests,
    pageSize: psRepairingRequests,
    totalCount: tcRepairingRequests,
    totalPages: tpRepairingRequests,
    collection: crepairingRequests,
    previousPage: ppRepairingRequests,
    nextPage: npRepairingRequests,
  } = repairingData;

  const {
    currentPage: cpApprovedRequests,
    pageSize: psApprovedRequests,
    totalCount: tcApprovedRequests,
    totalPages: tpApprovedRequests,
    collection: cApprovedRequests,
    previousPage: ppApprovedRequests,
    nextPage: npApprovedRequests,
  } = approvedData;

  const {
    currentPage: cpRejectedRequests,
    pageSize: psRejectedRequests,
    totalCount: tcRejectedRequests,
    totalPages: tpRejectedRequests,
    collection: cRejectedRequests,
    previousPage: ppRejectedRequests,
    nextPage: npRejectedRequests,
  } = rejectedData;

  const baseRepairFormFields = [
    "Reg no.",
    "Request ID",
    "Creation Date",
    "Request",
    "Mileage",
    "Unit",
    "Approval",
  ];
  const repairFormFields =
    currentUserRole === "super_admin"
      ? [...baseRepairFormFields, "Action"]
      : baseRepairFormFields;

  const rejectFormFields =
    currentUserRole === "super_admin"
      ? [...baseRepairFormFields]
      : baseRepairFormFields;

  const baseTableFields2 = [
    "Reg no.",
    "Type",
    "Cost",
    "Start date",
    "End date",
    "Duration",
    "Status",
  ];
  const tableFields2 =
    currentUserRole === "super_admin"
      ? [...baseTableFields2, "Action"]
      : baseTableFields2;

  const requestFormRowData =
    crepairRequests?.map((request) => ({
      id: request.id,
      "Reg no.": `${request.vehicle.regNo}`,
      "Request ID": request.id,
      "Creation Date": formatAndAdjustTimezone(request.createdOn),
      Request: getExcerpt(request.note),
      Mileage: request.vehicle.mileage,
      Unit: `${request.unit?.name}`,
      Approval: request.isApproved ? "Approved" : "Pending",
    })) || [];

  const rejectFormRowData =
    cRejectedRequests?.map((request) => ({
      id: request.id,
      "Reg no.": `${request.vehicle.regNo}`,
      "Request ID": request.id,
      "Creation Date": formatAndAdjustTimezone(request.createdOn),
      Request: getExcerpt(request.note),
      Mileage: request.vehicle.mileage,
      Unit: `${request.unit?.name}`,
      // Approval: request.isApproved ? "Approved" : "Pending",
      Approval: `Declined`,
    })) || [];

  const isValidDate = (dateString) => {
    if (dateString === null) return false;

    const date = new Date(dateString);
    return !isNaN(date.getTime()) && date instanceof Date;
  };

  const approvedRequestsRowData =
    crepairingRequests?.map((repair) => {
      const startDate = isValidDate(repair.startDate)
        ? new Date(repair.startDate)
        : null;
      const endDate = isValidDate(repair.endDate)
        ? new Date(repair.endDate)
        : null;

      let duration = "_";
      if (startDate && endDate) {
        const timeDifference = endDate.getTime() - startDate.getTime();
        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
        duration = daysDifference + " days";
      }

      return {
        id: repair.id,
        "Reg no.": `${repair.vehicle.regNo || "Unknown"}`,
        "Make/Model": `${repair.vehicle.vehicleMake ? repair.vehicle.vehicleMake.name : ""
          }`,
        Type: `${repair.vehicle.vehicleType ? repair.vehicle.vehicleType.name : ""
          }`,
        Cost: repair.cost.toLocaleString(),
        "Start date": startDate ? startDate.toLocaleDateString() : "_",
        "End date": endDate ? endDate.toLocaleDateString() : "_",
        Duration: duration,
        Status: repair.status,
      };
    }) || [];

  const completedRequestsRowData =
    cApprovedRequests?.map((repair) => {
      const startDate = isValidDate(repair.startDate)
        ? new Date(repair.startDate)
        : null;
      const endDate = isValidDate(repair.endDate)
        ? new Date(repair.endDate)
        : null;

      let duration = "_";
      if (startDate && endDate) {
        const timeDifference = endDate.getTime() - startDate.getTime();
        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
        duration = daysDifference + " days";
      }

      return {
        id: repair.id,
        "Reg no.": `${repair.vehicle.regNo || "Unknown"}`,
        "Make/Model": `${repair.vehicle.vehicleMake ? repair.vehicle.vehicleMake.name : ""
          }`,
        Type: `${repair.vehicle.vehicleType ? repair.vehicle.vehicleType.name : ""
          }`,
        Cost: repair.cost.toLocaleString(),
        "Start date": startDate ? startDate.toLocaleDateString() : "_",
        "End date": endDate ? endDate.toLocaleDateString() : "_",
        Duration: duration,
        Status: repair.status,
      };
    }) || [];

  const calculatePaginationRanges = (currentPage, pageSize, totalCount) => {
    if (totalCount === 0) {
      return { startRange: 0, endRange: 0 };
    }

    const startRange = (currentPage - 1) * pageSize + 1;
    const endRange = Math.min(startRange + pageSize - 1, totalCount);

    return { startRange, endRange };
  };

  let filteredRepairs = crepairRequests ? crepairRequests.slice() : [];

  // const sortByFirstName = (ascOrDes) => {
  //   const sortedData = [...filteredRepairs].sort((a, b) =>
  //     ascOrDes
  //       ? a.firstName.localeCompare(b.firstName)
  //       : b.firstName.localeCompare(a.firstName)
  //   );
  //   filteredRepairs = sortedData;
  // };

  const { startRange: startRequestRange, endRange: endRequestRange } =
    calculatePaginationRanges(activePage, psRepairRequests, tcRepairRequests);
  const { startRange: startRejectRange, endRange: endRejectRange } =
    calculatePaginationRanges(activePage, psRejectedRequests, tcRejectedRequests);
  const { startRange: startRepairingRange, endRange: endRepairingRange } =
    calculatePaginationRanges(
      activePage,
      psRepairingRequests,
      tcRepairingRequests
    );
  const { startRange: startApprovedRange, endRange: endApprovedRange } =
    calculatePaginationRanges(
      activePage,
      psApprovedRequests,
      tcApprovedRequests
    );

  return (
    <Layout>
      <Helmet>
        <title>Servicing - Mak Fleet</title>
      </Helmet>

      <div className="flex justify-between">
        <h2 className="font-dm flex gap-2 items-center font-medium text-md">
          {/* <FaWrench size={14} /> */}
          <FaBus size={14} />
          Servicing requests
        </h2>

        <div className="flex font-medium text-sm text-[#434C50]">
          {(currentUserRole == "unit_admin" ||
            currentUserRole == "unit_head" ||
            currentUserRole == "driver") && (
              <button
                className="px-5 py-2 bg-[#23A74C] rounded-md font-small text-white flex items-center text-base"
                onClick={openAddDrawer}
              >
                <span className="mr-2">
                  <IconCirclePlus size={18} />
                </span>
                <span className="text-base-extra">Servicing request</span>
              </button>
            )}
        </div>
      </div>

      <div className="font-dm flex justify-between text-[#434C50] mt-5 pt-2 bg-[#FFF]">
        <Tabs variant="default" defaultValue="new-request">
          <Tabs.List>
            <Tabs.Tab
              value="new-request"
              className="font-dm text-[#434C50] font-medium text-base-extra"
            >
              New requests
              <span className="h-5 ml-2 min-w-6 bg-[#63B6CE] text-[#FFF] leading-none rounded-md cursor-default flex items-center whitespace-nowrap inline-flex justify-center capitalize px-1.5 text-xs font-bold transition-all duration-200 ease-in-out">
                {tpRepairRequests ? tpRepairRequests : "0"}
              </span>
            </Tabs.Tab>

            <Tabs.Tab
              value="approved"
              className="font-dm text-[#434C50] font-medium text-base-extra"
            >
              Approved
              <span className="h-5 ml-2 min-w-6 bg-[#FFAB0029] text-[#B76E00] leading-none rounded-md cursor-default flex items-center whitespace-nowrap inline-flex justify-center capitalize px-1.5 text-xs font-bold transition-all duration-200 ease-in-out">
                {tcRepairingRequests ? tcRepairingRequests : 0}
              </span>
            </Tabs.Tab>
            <Tabs.Tab
              value="completed"
              className="font-dm text-[#434C50] font-medium text-base-extra"
            >
              Completed
              <span className="h-5 ml-2 min-w-6 bg-[#22C55E29] text-[#118D57] leading-none rounded-md cursor-default flex items-center whitespace-nowrap inline-flex justify-center capitalize px-1.5 text-xs font-bold transition-all duration-200 ease-in-out">
                {tcApprovedRequests ? tcApprovedRequests : "0"}
              </span>
            </Tabs.Tab>
            <Tabs.Tab
              value="declined"
              className="font-dm text-[#434C50] font-medium text-base-extra"
            >
              Declined
              <span className="h-5 ml-2 min-w-6 bg-[#FF5630]/25 text-[#B71D18] leading-none rounded-md cursor-default flex items-center whitespace-nowrap inline-flex justify-center capitalize px-1.5 text-xs font-bold transition-all duration-200 ease-in-out">
                {tcRejectedRequests ? tcRejectedRequests : "0"}
              </span>
            </Tabs.Tab>
          </Tabs.List>

          <div className="p-5">
            <Tabs.Panel value="new-request">
              <>
                {!loading ? (
                  <>
                    {crepairRequests ? (
                      crepairRequests.length > 0 ? (
                        <TableSort
                          data={requestFormRowData}
                          fields={repairFormFields}
                          category={"fleetServiceForms"}
                        />
                      ) : (
                        <TableSort
                          data={[]}
                          fields={repairFormFields}
                          category={"fleetServiceForms"}
                        />
                      )
                    ) : null}

                    <div className="px-3 py-2 mt-3 flex justify-end items-center font-medium">
                      <div
                        className="text-sm mr-3"
                        style={{ fontSize: "12px" }}
                      >
                        Showing {startRequestRange ? startRequestRange : 0} to{" "}
                        {endRequestRange ? endRequestRange : 0} of{" "}
                        {tcRepairRequests} entries
                      </div>
                      <div className="">
                        <Pagination
                          value={activePage}
                          onChange={setPage}
                          total={tpRepairRequests}
                          size="sm"
                          position="right"
                          withEdges
                          styles={(theme) => ({
                            fontFamily: "Outfit",
                            fontSize: "12px",
                            control: {
                              "&[data-active]": {
                                backgroundImage: theme.fn.gradient({
                                  from: "#C1BCCD",
                                  to: "#C1BCCD",
                                }),
                                border: 0,
                              },
                            },
                          })}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="flex justify-center items-center px-5 py-5">
                    <div
                      style={{ minHeight: "50vh" }}
                      className="flex items-center justify-center"
                    >
                      <div className="flex justify-center px-5 py-5">
                        <MoonLoader color="#4CCEAC" size={50} />
                      </div>
                    </div>
                  </div>
                )}
              </>
            </Tabs.Panel>

            <Tabs.Panel value="completed">
              <>
                {!loading ? (
                  <>
                    {cApprovedRequests ? (
                      cApprovedRequests.length > 0 ? (
                        <TableSort
                          data={completedRequestsRowData}
                          fields={tableFields2}
                          category={"fleetServiceds"}
                        />
                      ) : (
                        <TableSort
                          data={[]}
                          fields={tableFields2}
                          category={"fleetServiceds"}
                        />
                      )
                    ) : null}

                    <div className="px-3 py-2 mt-3 flex justify-end items-center font-medium">
                      <div
                        className="text-sm mr-3"
                        style={{ fontSize: "12px" }}
                      >
                        Showing {startApprovedRange ? startApprovedRange : 0} to{" "}
                        {endApprovedRange ? endApprovedRange : 0} of{" "}
                        {tcApprovedRequests} entries
                      </div>
                      <div className="">
                        <Pagination
                          value={activePage}
                          onChange={setPage}
                          total={tpApprovedRequests}
                          size="sm"
                          position="right"
                          withEdges
                          styles={(theme) => ({
                            fontFamily: "Outfit",
                            fontSize: "12px",
                            control: {
                              "&[data-active]": {
                                backgroundImage: theme.fn.gradient({
                                  from: "#C1BCCD",
                                  to: "#C1BCCD",
                                }),
                                border: 0,
                              },
                            },
                          })}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="flex justify-center items-center px-5 py-5">
                    <div
                      style={{ minHeight: "50vh" }}
                      className="flex items-center justify-center"
                    >
                      <div className="flex justify-center px-5 py-5">
                        <MoonLoader color="#4CCEAC" size={50} />
                      </div>
                    </div>
                  </div>
                )}
              </>
            </Tabs.Panel>

            <Tabs.Panel value="approved">
              <>
                {!loading ? (
                  <>
                    {crepairingRequests ? (
                      crepairingRequests.length > 0 ? (
                        <TableSort
                          data={approvedRequestsRowData}
                          fields={tableFields2}
                          category={"fleetServicings"}
                        />
                      ) : (
                        <TableSort
                          data={[]}
                          fields={tableFields2}
                          category={"fleetServicings"}
                        />
                      )
                    ) : null}

                    <div className="px-3 py-2 mt-3 flex justify-end items-center font-medium">
                      <div
                        className="text-sm mr-3"
                        style={{ fontSize: "12px" }}
                      >
                        Showing {startRepairingRange ? startRepairingRange : 0}{" "}
                        to {endRepairingRange ? endRepairingRange : 0} of{" "}
                        {tcRepairingRequests} entries
                      </div>
                      <div className="">
                        <Pagination
                          value={activePage}
                          onChange={setPage}
                          total={tpRepairRequests}
                          size="sm"
                          position="right"
                          withEdges
                          styles={(theme) => ({
                            fontFamily: "Outfit",
                            fontSize: "12px",
                            control: {
                              "&[data-active]": {
                                backgroundImage: theme.fn.gradient({
                                  from: "#C1BCCD",
                                  to: "#C1BCCD",
                                }),
                                border: 0,
                              },
                            },
                          })}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="flex justify-center items-center px-5 py-5">
                    <div
                      style={{ minHeight: "50vh" }}
                      className="flex items-center justify-center"
                    >
                      <div className="flex justify-center px-5 py-5">
                        <MoonLoader color="#4CCEAC" size={50} />
                      </div>
                    </div>
                  </div>
                )}
              </>
            </Tabs.Panel>

            <Tabs.Panel value="declined">
              <>
                {!loading ? (
                  <>
                    {cRejectedRequests ? (
                      cRejectedRequests.length > 0 ? (
                        <TableSort
                          data={rejectFormRowData}
                          fields={rejectFormFields}
                          category={"fleetServiceForms"}
                        />
                      ) : (
                        <TableSort
                          data={[]}
                          fields={rejectFormFields}
                          category={"fleetServiceForms"}
                        />
                      )
                    ) : null}

                    <div className="px-3 py-2 mt-3 flex justify-end items-center font-semibold">
                      <div
                        className="text-sm mr-3"
                        style={{ fontSize: "12px" }}
                      >
                        Showing {startRejectRange ? startRejectRange : 0} to{" "}
                        {endRejectRange ? endRejectRange : 0} of{" "}
                        {tcRejectedRequests} entries
                      </div>
                      <div className="">
                        <Pagination
                          value={activePage}
                          onChange={setPage}
                          total={tpRepairRequests}
                          size="sm"
                          position="right"
                          withEdges
                          styles={(theme) => ({
                            fontFamily: "Outfit",
                            fontSize: "12px",
                            control: {
                              "&[data-active]": {
                                backgroundImage: theme.fn.gradient({
                                  from: "#C1BCCD",
                                  to: "#C1BCCD",
                                }),
                                border: 0,
                              },
                            },
                          })}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="flex justify-center items-center px-5 py-5">
                    <div
                      style={{ minHeight: "50vh" }}
                      className="flex items-center justify-center"
                    >
                      <div className="flex justify-center px-5 py-5">
                        <MoonLoader color="#4CCEAC" size={50} />
                      </div>
                    </div>
                  </div>
                )}
              </>
            </Tabs.Panel>
          </div>
        </Tabs>
      </div>

      <FleetDrawer
        opened={openedAddDrawer}
        onClose={closeAddDrawer}
        title="New Servicing Request"
      >
        <AddServicingRequestForm onCancel={closeAddDrawer} />
      </FleetDrawer>
    </Layout>
  );
};

export default Servicing;
