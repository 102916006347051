import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import { C } from '../../common';
import toast from "react-hot-toast";
import { addUserValidationSchema } from "../../helpers/validators";
import { useEffect, useState } from "react";

export function AddUser({ onCancel }) {
  const [units, setUnits] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        axios
          .get(`${C.URLS.BASE_URL}/api/Roles`)
          .then((res) => setRoles(res.data.data.collection))
          .catch((error) => console.log(error));
      } catch (error) { }
    };

    const fetchUnits = async () => {
      try {
        const allUnits = [];
        let currentPage = 1;

        while (true) {
          const response = await axios.get(`${C.URLS.BASE_URL}/api/Units?PageNumber=${currentPage}`);
          const unitsData = response.data.data.collection;

          if (unitsData.length === 0) {
            break;
          }

          allUnits.push(...unitsData);
          currentPage++;
        }

        setUnits(allUnits);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchRoles();
    fetchUnits();
  }, []);

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        phoneNumber: "",
        emailAddress: "",
        userName: "",
        middleName: "",
        imageUrl: "",
        unitId: "",
        roleId: "",
        activatePageUrl: `${C.ENDPOINTS.ACTIVATION_URL}`,
      }}
      validationSchema={addUserValidationSchema}
      enableReinitialize

      onSubmit={async (values, { resetForm }) => {
        console.log(values);
        setLoading(true);

        const submittedEmail = values.emailAddress;
        values.activatePageUrl = values.activatePageUrl + `?email=${encodeURIComponent(submittedEmail)}`;

        try {
          const formData = new FormData();
          for (const key in values) {
            if (key === "imageUrl") {
              formData.append(key, values[key][0]);
            } else {
              formData.append(key, values[key]);
            }
          }

          // console.log(formData);

          await axios
            .post(`${C.URLS.BASE_URL}/api/Users`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(() =>
              toast.success("Successfully added user.", {
                position: "bottom-center",
              })
            )
            .then(() => {
              setLoading(false);
              resetForm();
            })
            .catch((error) => {
              toast.error(`${error.message}`, {
                position: "bottom-center",
              });
              setLoading(false);
            });
        } catch (error) { }
      }}


    >
      {({ values, handleChange, handleBlur, errors, touched }) => {
        return (
          <>
            <Form className="h-full overflow-y-scroll pt-10 px-4 md:px-10 bg-white">
              <main>

                <label className="block mb-3">
                  {/* <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700"> */}
                  <span className="block text-base font-medium text-slate-700">
                    First Name
                  </span>
                  <div className="">
                    <input
                      type="text"
                      name=""
                      placeholder="First name"
                      onChange={handleChange("firstName")}
                      onBlur={handleBlur("firstName")}
                      value={values.firstName}
                      className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors &&
                        errors?.firstName &&
                        touched &&
                        touched?.firstName
                        ? "border-red-400"
                        : "border-slate-400"
                        }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-sm focus:ring-1 mb-1`}
                    />
                  </div>
                </label>

                <label className="block mb-3">
                  <span className="block text-base font-medium text-slate-700">
                    Middle Name
                  </span>
                  <div className="">
                    <input
                      type="text"
                      name="middleName"
                      placeholder="Middle name"
                      onChange={handleChange("middleName")}
                      onBlur={handleBlur("middleName")}
                      value={values.middleName}
                      className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-400 placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-sm focus:ring-1 mb-1"
                    />
                  </div>
                </label>

                <label className="block mb-5">
                  <span className="block text-base font-medium text-slate-700">
                    Last Name
                  </span>
                  <div className="">
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Last name"
                      onChange={handleChange("lastName")}
                      onBlur={handleBlur("lastName")}
                      value={values.lastName}
                      className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors &&
                        errors?.lastName &&
                        touched &&
                        touched?.lastName
                        ? "border-red-400"
                        : "border-slate-400"
                        }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-sm focus:ring-1 mb-1`}
                    />
                    </div>
                </label>

                {/* <label className="block mb-5">
                  <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                    Username
                  </span>
                  <div className="">
                    <input
                      type="text"
                      name=""
                      placeholder="User Name"
                      onChange={handleChange("userName")}
                      onBlur={handleBlur("userName")}
                      value={values.userName}
                      className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors &&
                        errors?.userName &&
                        touched &&
                        touched?.userName
                        ? "border-red-400"
                        : "border-slate-400"
                        }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-sm focus:ring-1 mb-1`}
                    />
                    </div>
                </label> */}

                {/* Profile Image */}
                <label className="block mb-7 w-full">
                  <span className="block text-base font-medium text-slate-700">
                    Profile Image
                  </span>
                  <div className="">
                    <input
                      type="file"
                      name="imageUrl"
                      placeholder="Select Profile Image"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.imageUrl}
                      className={`mt-1 px-3 py-2 bg-gray-50 border shadow-sm capitalize border-gray-400 focus:outline-none focus:border-gray-400 focus:ring-gray-400 block w-full rounded-md sm:text-sm focus:ring-0 mb-1 text-gray-500`}
                    />
                  </div>
                </label>


                <label className="block mb-3">
                  <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base text-slate-700">
                    Phone Number
                  </span>
                  <div className="">
                    <input
                      type="text"
                      name="phoneNumber"
                      placeholder="Phone number"
                      onChange={handleChange("phoneNumber")}
                      onBlur={handleBlur("phoneNumber")}
                      value={values.phoneNumber}
                      className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors &&
                        errors?.phoneNumber &&
                        touched &&
                        touched?.phoneNumber
                        ? "border-red-400"
                        : "border-slate-400"
                        }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-sm focus:ring-1 mb-1`}
                    />
                    <ErrorMessage name="phoneNumber">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </label>

                <label className="block mb-3">
                  <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base text-slate-700">
                    Mak email
                  </span>
                  <div className="">
                    <input
                      type="text"
                      name="email"
                      placeholder="Email e.g john.doe@mak.ac.ug"
                      onChange={handleChange("emailAddress")}
                      onBlur={handleBlur("emailAddress")}
                      value={values.emailAddress}
                      className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors &&
                        errors?.emailAddress &&
                        touched &&
                        touched?.emailAddress
                        ? "border-red-400"
                        : "border-slate-400"
                        }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-sm focus:ring-1 mb-1`}
                    />
                    <ErrorMessage name="emailAddress">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </label>

                {/* Units */}
                <label className="block mb-5">
                  <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base font-medium text-slate-700">
                    Unit or College
                  </span>
                  <div className="">
                    <select
                      name="units"
                      onChange={handleChange("unitId")}
                      onBlur={handleBlur("unitId")}
                      value={values.unitId}
                      className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors && errors?.units && touched && touched?.units
                        ? "border-red-400"
                        : "border-slate-400"
                        }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-base focus:ring-1 mb-1`}
                    >
                      <option value="">-- Select Unit --</option>
                      {units &&
                        units.length > 0 &&
                        units.map((unit) => (
                          <option key={unit.id} value={unit.id}>
                            {unit.name}
                          </option>
                        ))}
                    </select>
                    <ErrorMessage name="unitId">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </label>

                {/* Roles */}
                <label className="block mb-5">
                  <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base text-slate-700">
                    Role
                  </span>
                  <div className="">
                    <select
                      name="roleId"
                      onChange={handleChange("roleId")}
                      onBlur={handleBlur("roleId")}
                      value={values.roleId}
                      className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors && errors?.roleId && touched && touched?.roleId
                        ? "border-red-400"
                        : "border-slate-400"
                        }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-sm focus:ring-1 mb-1`}
                    >
                      <option value="">-- Select Role --</option>
                      {roles &&
                        roles.length > 0 &&
                        roles.map((role, index) => (
                          <option key={index} value={role.id}>
                            {role.name}
                          </option>
                        ))}
                    </select>
                    <ErrorMessage name="roleId">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </label>

                <div className="flex justify-end gap-3 md:gap-3 mt-4">
                  <button
                    type="button"
                    className="text-base-extra text-[#23A74C] border-red-500 border cursor-pointer text-red-500 py-1 px-4 my-2 rounded-md"
                    onClick={onCancel}
                  >
                    Cancel
                  </button>
                  <input
                    type="submit"
                    value={loading ? "Adding user..." : "Add User"}
                    className="bg-[#23A74C] text-base-extra border-[#23A74C] border-2 text-white py-1 px-4 my-2 rounded-md cursor-pointer"
                  />
                </div>
              </main>
            </Form>
          </>
        );
      }}
    </Formik>
  );
}
