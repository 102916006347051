import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import { C } from '../../common';
import toast from "react-hot-toast";
import { IconCirclePlus, IconSquareLetterX } from "@tabler/icons-react";
import { addVehicleValidationSchema } from "../../helpers/validators";

import { useEffect, useState } from "react";

export function AddVehicle({ onCancel }) {
  const [units, setUnits] = useState([]);
  const [typeMakes, setTypeMakes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const allUnits = [];
        let currentPage = 1;

        while (true) {
          const response = await axios.get(`${C.URLS.BASE_URL}/api/Units?PageNumber=${currentPage}`);
          const unitsData = response.data.data.collection;

          if (unitsData.length === 0) {
            break;
          }

          allUnits.push(...unitsData);
          currentPage++;
        }

        setUnits(allUnits);
      } catch (error) {
        console.error(error.message);
      }
    };

    const fetchVehicleTypeMakes = async () => {
      try {
        const allVehicleTypeMakes = [];
        let currentPage = 1;

        while (true) {
          const response = await axios.get(`${C.URLS.BASE_URL}/api/VehicleTypeMakes?PageNumber=${currentPage}`);
          const typeMakeData = response.data.data.collection;

          if (typeMakeData.length === 0) {
            break; // No more users, exit the loop
          }

          allVehicleTypeMakes.push(...typeMakeData);
          currentPage++;
        }

        setTypeMakes(allVehicleTypeMakes);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchUnits();
    fetchVehicleTypeMakes();
  }, []);

  const validateImage = (file) => {
    const maxSize = 1024 * 1024; // 1MB in bytes
    if (!file) {
      return 'Please select an image.';
    }
    if (file.size > maxSize) {
      return 'Image size exceeds 1MB.';
    }
    return null;
  };

  return (
    <Formik
      initialValues={{
        regNo: "",
        vehicleMakeId: "",
        vehicleTypeId: "",
        yearOfManufacture: 2017,
        engineNo: "",
        unitId: "",
        subUnit: "",
        regBook: "",
        chasisNumber: "",
        remarks: "",
        deleteReason: "",
        metaData: "",
        imageUrl: "",
        dateOfAcquisition: "",
        status: "",
        costOfAquisition: 0,
        mileage: 0
      }}
      validationSchema={addVehicleValidationSchema}
      enableReinitialize
      onSubmit={async (values, { resetForm }) => {
        console.log(values);

        const formData = new FormData();
        for (let key in values) {
          formData.append(key, values[key]);
        }

        setLoading(true);
        try {
          // const response = await axios.post(
          //   `${C.URLS.BASE_URL}/api/Vehicles`,
          //   values
          // );
          const response = await axios.post(`${C.URLS.BASE_URL}/api/Vehicles`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
          const responseData = response.data;

          if (responseData.code === 'BadRequest') toast.error(responseData.message, { position: "bottom-center" });
          else if (response.status === 200 || response.status === 201) {
            toast.success(responseData.message, { position: "bottom-center" });
            setLoading(false);
            resetForm();
          } else {
            toast.error("An error occurred while processing your request.", { position: "bottom-center" });
          }

          setLoading(false);
          resetForm();
        } catch (error) {
          const errorResponse = error.response;
          if (errorResponse) {
            const errorMessage = errorResponse.data.message;
            toast.error(errorMessage, {
              position: "bottom-center",
            });
          } else {
            toast.error("An error occurred.", {
              position: "bottom-center",
            });
          }
          setLoading(false);
        }
      }}

    >
      {({ values, handleChange, handleBlur, errors, touched }) => {
        return (
          <>
            <Form encType="multipart/form-data" className="h-full overflow-y-scroll pt-7 px-4 md:px-10 bg-white">
              <main>

                <label className="block mb-5">
                  <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base font-medium text-slate-700">
                    Registration Number
                  </span>
                  <div className="">
                    <input
                      type="text"
                      name="regNo"
                      placeholder="Enter Registration Number"
                      onChange={handleChange("regNo")}
                      onBlur={handleBlur("regNo")}
                      value={values.regNo}
                      className={`mt-1 px-3 py-2 text-base bg-transparent border shadow-sm ${errors && errors?.regNo && touched && touched?.regNo
                        ? "border-red-400"
                        : "border-slate-400"
                        }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-base focus:ring-1 mb-1`}
                    />
                    <ErrorMessage name="regNo">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </label>

                {/* Make/Model */}
                <label className="block mb-5">
                  <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base font-medium text-slate-700">
                    Make/Model
                  </span>
                  <div className="">
                    <select
                      name="vehicleMakeId"
                      onChange={handleChange("vehicleMakeId")}
                      onBlur={handleBlur("vehicleMakeId")}
                      value={values.vehicleMakeId}
                      className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors && errors?.vehicleMakeId && touched && touched?.vehicleMakeId
                        ? "border-red-400"
                        : "border-slate-400"
                        }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-base focus:ring-1 mb-1`}
                    >
                      <option value="">-- Select Make/Model --</option>
                      {typeMakes &&
                        typeMakes.length > 0 &&
                        typeMakes
                          .filter(makesModel => makesModel.typeMakeModelStatus === "MAKEMODEL")
                          .map((makesModel) => (
                            <option key={makesModel.id} value={makesModel.id}>
                              {makesModel.name}
                            </option>
                          ))}
                    </select>
                    <ErrorMessage name="vehicleMakeId">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </label>

                {/* Make/Model */}
                <label className="block mb-5">
                  <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base font-medium text-slate-700">
                    Type
                  </span>
                  <div className="">
                    <select
                      name="vehicleTypeId"
                      onChange={handleChange("vehicleTypeId")}
                      onBlur={handleBlur("vehicleTypeId")}
                      value={values.vehicleTypeId}
                      className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors && errors?.vehicleTypeId && touched && touched?.vehicleTypeId
                        ? "border-red-400"
                        : "border-slate-400"
                        }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-base focus:ring-1 mb-1`}
                    >
                      <option value="">-- Select Type --</option>
                      {typeMakes &&
                        typeMakes.length > 0 &&
                        typeMakes
                          .filter(makesModel => makesModel.typeMakeModelStatus === "TYPE")
                          .map((makesModel) => (
                            <option key={makesModel.id} value={makesModel.id}>
                              {makesModel.name}
                            </option>
                          ))}
                    </select>
                    <ErrorMessage name="vehicleTypeId">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </label>

                <label className="block mb-5">
                  <span className="after:ml-0.5 after:text-red-500 block text-base font-medium text-slate-700">
                    Year of Manufacture
                  </span>
                  <div className="">
                    <input
                      type="number"
                      name="yearOfManufacture"
                      placeholder="Enter Year of Manufacture e.g 2017"
                      onChange={handleChange("yearOfManufacture")}
                      onBlur={handleBlur("yearOfManufacture")}
                      value={values.yearOfManufacture}
                      className={`mt-1 px-3 py-2 bg-transparent border border-slate-400 shadow-sm placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-base focus:ring-1 mb-1`}
                    />
                    {/* <ErrorMessage name="yearOfManufacture">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage> */}
                  </div>
                </label>

                <label className="block mb-5">
                  <span className="block text-base font-medium text-slate-700">
                    Engine Number
                  </span>
                  <div className="">
                    <input
                      type="text"
                      name="engineNo"
                      placeholder="Enter Engine Number"
                      onChange={handleChange("engineNo")}
                      onBlur={handleBlur("engineNo")}
                      value={values.engineNo}
                      className={`mt-1 px-3 py-2 bg-transparent border border-slate-400 shadow-sm placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-base focus:ring-1 mb-1`}
                    />
                  </div>
                </label>


                {/* Units */}
                <label className="block mb-5">
                  <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base font-medium text-slate-700">
                    Unit
                  </span>
                  <div className="">
                    <select
                      name="units"
                      onChange={handleChange("unitId")}
                      onBlur={handleBlur("unitId")}
                      value={values.unitId}
                      className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors && errors?.units && touched && touched?.units
                        ? "border-red-400"
                        : "border-slate-400"
                        }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-base focus:ring-1 mb-1`}
                    >
                      <option value="">-- Select Unit --</option>
                      {units &&
                        units.length > 0 &&
                        units.map((unit) => (
                          <option key={unit.id} value={unit.id}>
                            {unit.name}
                          </option>
                        ))}
                    </select>
                    <ErrorMessage name="unitId">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </label>

                <label className="block mb-5">
                  <span className="after:ml-0.5 after:text-red-500 block text-base font-medium text-slate-700">
                    User Unit
                  </span>
                  <div className="">
                    <input
                      type="text"
                      name="subUnit"
                      placeholder="Enter User Unit"
                      onChange={handleChange("subUnit")}
                      onBlur={handleBlur("subUnit")}
                      value={values.subUnit}
                      className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors && errors?.subUnit && touched && touched?.subUnit
                        ? "border-red-400"
                        : "border-slate-400"
                        }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-base focus:ring-1 mb-1`}
                    />
                  </div>
                </label>

                {/* RegBook Status */}
                <label className="block mb-5">
                  <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base font-medium text-slate-700">
                    Registration Book Holder
                  </span>
                  <div className="">
                    <select
                      name="regBook"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.regBook}
                      className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors && errors.regBook && touched && touched.regBook
                        ? "border-red-400"
                        : "border-slate-400"
                        }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-base focus:ring-1 mb-1`}
                    >
                      <option value="">-- Select Current Holder --</option>
                      <option value="Transport Office">Transport Officer</option>
                      <option value="User">User</option>
                    </select>
                    <ErrorMessage name="regBook">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </label>

                <label className="block mb-5">
                  <span className="after:ml-0.5 after:text-red-500 block text-base font-medium text-slate-700">
                    Chasis Number
                  </span>
                  <div className="">
                    <input
                      type="text"
                      name="chasisNumber"
                      placeholder="Enter Chasis Number"
                      onChange={handleChange("chasisNumber")}
                      onBlur={handleBlur("chasisNumber")}
                      value={values.chasisNumber}
                      className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors && errors?.chasisNumber && touched && touched?.chasisNumber
                        ? "border-red-400"
                        : "border-slate-400"
                        }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-base focus:ring-1 mb-1`}
                    />
                    <ErrorMessage name="chasisNumber">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </label>

                {/* Vehicle Image */}
                <label className="block mb-5">
                  <span className="after:ml-0.5 after:text-red-500 block text-base font-medium text-slate-700">
                    Vehicle Image <span className="italic text-base font-medium">(Max size: 3MB)</span>
                  </span>
                  <div className="">
                    <input
                      type="file"
                      name="imageUrl"
                      validate={values.imageUrl}
                      onChange={handleChange("imageUrl")}
                      onBlur={handleBlur("imageUrl")}
                      value={values.imageUrl}
                      className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-400 placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-base focus:ring-1 mb-1"
                    />
                  </div>
                </label>


                {/* Date of Acquisition */}
                <label className="block mb-5">
                  <span className="after:ml-0.5 after:text-red-500 block text-base font-medium text-slate-700">
                    Date of Acquisition
                  </span>
                  <div className="">
                    <input
                      type="date"
                      name="dateOfAcquisition"
                      onChange={handleChange("dateOfAcquisition")}
                      onBlur={handleBlur("dateOfAcquisition")}
                      value={values.dateOfAcquisition}
                      className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-400 placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-base focus:ring-1 mb-1"
                    />
                  </div>
                </label>


                {/* Current Status */}
                <label className="block mb-5">
                  <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-base font-medium text-slate-700">
                    Current Status:
                  </span>
                  <div className="">
                    <select
                      name="status"
                      onChange={handleChange("status")}
                      onBlur={handleBlur("status")}
                      value={values.status}
                      className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors && errors.status && touched && touched.status
                        ? "border-red-400"
                        : "border-slate-400"
                        }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-base focus:ring-1 mb-1`}
                    >
                      <option value="">-- Select Current Status --</option>
                      <option value="PARKED">Parked</option>
                      <option value="RUNNING">Running</option>
                      <option value="DISPOSED">Disposed</option>
                      <option value="OBSOLETE">Obsolete</option>
                      <option value="GROUNDED">Grounded</option>
                      <option value="SOLD">Sold</option>
                    </select>
                  </div>
                  <ErrorMessage name="status">
                    {(msg) => (
                      <div className="text-xs text-red-500 text-left w-full">
                        <p>{msg}</p>
                      </div>
                    )}
                  </ErrorMessage>
                </label>

                <label className="block mb-5">
                  <span className="after:ml-0.5 after:text-red-500 block text-base font-medium text-slate-700">
                    Cost of Acquisition <span className="italic text-base font-medium"></span>(UGX)</span>
                  <div className="">
                    <input
                      type="number"
                      name="costOfAquisition"
                      placeholder="Enter Cost of Acqusition"
                      onChange={handleChange("costOfAquisition")}
                      onBlur={handleBlur("costOfAquisition")}
                      value={values.costOfAquisition}
                      className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors && errors?.costOfAquisition && touched && touched?.costOfAquisition
                        ? "border-red-400"
                        : "border-slate-400"
                        }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-base focus:ring-1 mb-1`}
                    />
                  </div>
                </label>


                <label className="block mb-5">
                  <span className="after:ml-0.5 after:text-red-500 block text-base font-medium text-slate-700">
                    Mileage <span className="italic text-base font-medium">(KM)</span>
                  </span>
                  <div className="">
                    <input
                      type="number"
                      name="mileage"
                      placeholder="Enter Vehicle Mileage"
                      onChange={handleChange("mileage")}
                      onBlur={handleBlur("mileage")}
                      value={values.mileage}
                      className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors && errors?.mileage && touched && touched?.mileage
                        ? "border-red-400"
                        : "border-slate-400"
                        }  placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-base focus:ring-1 mb-1`}
                    />
                    <ErrorMessage name="mileage">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </label>

                <label className="block mb-5">
                  <span className="after:ml-0.5 after:text-red-500 block text-base font-medium text-slate-700">
                    Remarks
                  </span>
                  <div className="">
                    <textarea
                      name="remarks"
                      placeholder="Any remarks?"
                      onChange={handleChange("remarks")}
                      onBlur={handleBlur("remarks")}
                      value={values.remarks}
                      className={`mt-1 px-3 py-2 bg-transparent border shadow-sm ${errors && errors?.remarks && touched && touched?.remarks
                        ? "border-red-400"
                        : "border-slate-400"
                        } placeholder-slate-400 focus:outline-none focus:border-teal-700 focus:ring-teal-700 block w-full rounded-md sm:text-base focus:ring-1 mb-1`}
                      rows="4"
                    />
                    <ErrorMessage name="remarks">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </label>


                <div className="flex justify-end gap-3 md:gap-2 mt-4 font-bold">
                  <button
                    type="button"
                    className="px-5 py-2 bg-[#FDE8EB] rounded-md font-bold text-[#B21028] flex items-center text-base"
                    onClick={onCancel}
                  >
                    <span className="mr-2">
                      <IconSquareLetterX size={18} />
                    </span>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    value={loading ? "Adding vehicle..." : "Add Vehicle"}
                    className="px-5 py-2 bg-[#23A74C] rounded-md font-bold text-white flex items-center text-base"
                    // onClick={onCancel}
                  >
                    <span className="mr-2">
                      <IconCirclePlus size={18} />
                    </span>
                    {loading ? "Adding vehicle..." : "Add Vehicle"}
                  </button>
                </div>
              </main>
            </Form >
          </>
        );
      }}
    </Formik >
  );
}
